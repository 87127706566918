import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid'
import TableForm from './TableAn'
import AnalitycProps from '../../../components/GridProps'
import Line from './LineMaterialPrice'
import { Button } from '@material-ui/core';
import download from '../../../functions/download'
import { Link } from 'react-router-dom';

export default function AnalyticMaterialPrice({ formName, formPrice, idProject }) {
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const formTable = useSelector(state => state.formTable[formPrice] || state.formTable.default)
  const getStringPrice = (price, avg) => {
    if (!price || !avg) return ''
    const arrow = (price < avg) ? <>&#x2191;</> : <>&#8595;</>
    const diff = 100 - Math.round(((price > avg) ? avg / price : price / avg) * 100)
    return <Typography style={{ color: (price > avg) ? '#3bcf00' : '#ff0000' }}>
      {(Math.round(avg * 100) / 100).toLocaleString()} ({diff}{arrow})
    </Typography>
  }
  const columns = [
    { id: 'date', name: 'Дата' },
    { id: 'nameProvider', name: 'Поставщик', component: (name,obj)=>{
    const toLink = React.forwardRef((props, ref) => (<Link ref={ref} to={`/projects/${idProject}/shipment/materials/${obj.idDoc}`} {...props} />))
    
      return <Typography component={toLink} align='inherit' >{name}</Typography>
    } },
    { id: 'estimate', name: 'Наименование' },
    { id: 'count', name: 'Количество', },
    { id: 'storageUnit', name: 'Ед. изм.', },
    { id: 'price', name: 'Цена', },
    { id: 'sum', name: 'Сумма', },
    { id: 'nds', name: 'НДС', },
    { id: 'nds_count', name: 'Сумма с НДС', },
  ]
  const handleClickCSV = () => {
    download(formTable.allIds, formTable.byId, columns.map(obj => obj.id), columns.map(obj => obj.name), 'analytic.csv')
  }
  return (
    <div>
      <Button onClick={() => { handleClickCSV(); }} color='primary'>Скачать таблицу</Button>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid container item xs={6}>
          <AnalitycProps
            name='Цена по смете:'
            value={(Math.round(values.price * 100) / 100).toLocaleString()}
          />
          <AnalitycProps
            name='Потрачено:'
            value={getStringPrice(values.price * values.countPrice, values.sumPrice)}
          />
          <AnalitycProps
            name='Средняя цена:'
            value={getStringPrice(values.price, values.avgPrice)}
          />
        </Grid>
        <Grid
          item
          container
          direction='row'
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item sm={12}>
            <TableForm
              tableHead={columns}
              tableData={formTable}
            />
          </Grid>
          <Grid item>
            <Line
              formName={formName}
              width={600}
              height={400}
            />
          </Grid>
        </Grid>
      </Grid>

    </div>
  )
}