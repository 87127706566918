import React, { useState } from 'react';
import {
  Button, Typography, Table,
  TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import { useSelector } from 'react-redux';


const tableHeadResult = [
  { name: 'Номер', id: 'number', padding: 'checkbox' },
  { name: 'Тип', id: 'typeEstimate', padding: 'default' },
  { name: 'Наименование', id: 'name', padding: 'default' },
  { name: 'Ед.изм.', id: 'storageUnit', padding: 'default' },
  { name: 'Количество', align: 'right', id: 'count', padding: 'default' },
]
export default function ResultTender({ formLots, formBid }) {
  const values = useSelector(state => state.form[formLots]?.values) || { projectsArr: [], projectsObj: {}, estimateObj: {} }
  const accounts = useSelector(state => state.form[formBid]?.values) || { byId: {}, allIds: [] }
  const [priceOrSumm, setPriceOrSumm] = useState(false) // price=false summ=true
  const handleDownload = () => {
    const data = ['\ufeff __ \n']
    const column = [
      ...tableHeadResult.map(e => e.name),
      ...accounts.allIds.map(key => (`${accounts.byId[key].accountName}(цена);${accounts.byId[key].accountName}(сумма)`))
    ].join(';') + '\n'
    values.projectsArr.forEach(key => {
      const project = values.projectsObj[key]
      data.push(`;${project.name}\n`)
      data.push([';Дата работ с', project.dateStart, 'по', project.dateEnd].join(';') + '\n')
      data.push(column)
      project.group.arr.forEach(groupKey => {
        const obj = project.group.obj[groupKey]
        data.push(`${obj.numberPos};${obj.name}\n`)
        const child = project.group.obj[groupKey].child
        child.forEach((id, i) => {
          const est = { ...values.estimateObj[id], number: obj.numberPos + '.' + (i + 1) };
          const str = [
            ...tableHeadResult.map(e => {
              if (e.id === 'name') {
                return est[e.id].replace(/;/g, ' ')
              }
              return est[e.id]
            }),
            ...accounts.allIds.map(key => {
              const acc = accounts.byId[key]
              const price = est.price[acc.idAccount.toString()] || 0
              return `${price};${Math.round(price * est.count * 100) / 100}`
            })
          ]
          data.push(str.join(';') + '\n')
        })
      })
    })
    const file = new Blob(data, { type: 'text/csv' });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
      window.navigator.msSaveOrOpenBlob(file, 'tender.csv');
    else { // Others
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = 'tender.csv';
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }
  return <div>
    <Button color="primary" onClick={handleDownload}>Скачать </Button>
    <ButtonDivider />
    <Button color={priceOrSumm ? "secondary" : "primary"} onClick={() => setPriceOrSumm(false)}>Цена</Button>
    <Button color={priceOrSumm ? "primary" : "secondary"} onClick={() => setPriceOrSumm(true)}>Сумма</Button>
    <ButtonDivider />
    {values.projectsArr.map(key => {
      const project = values.projectsObj[key]
      return <div key={key} style={{ marginBottom: 20 }}>
        <Typography variant='h6'>{project.name}</Typography>
        <div>Дата работ с:  {project.dateStart} по:  {project.dateEnd}</div>
        <AccountTable accounts={accounts} group={project.group} />
        <div style={{ margin: 30 }} />
        <TableResult accounts={accounts} project={project} estimateObj={values.estimateObj} priceOrSumm={priceOrSumm} />
      </div>
    })}
  </div>
}

function ButtonDivider() {
  const style = {
    borderLeft: '2px solid #9391a22e',
    height: '32px',
    verticalAlign: 'middle',
    display: 'inline-block',
  }
  return <div style={style} />
}

function AccountTable({ accounts, group }) {
  const { arr, obj } = group
  return <div>
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">№</TableCell>
          <TableCell>Аккаунт</TableCell>
          {arr.map(key => <TableCell key={key} align='right'>{obj[key].name}</TableCell>)}
          <TableCell align='right'>Всего</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {accounts.allIds.map((key, i) => {
          const { id, name } = accounts.byId[key]
          const summ = arr.reduce((accumulator, current) => {
            return accumulator + (obj[current].price[id] || 0)
          }, 0)
          return <TableRow key={key}>
            <TableCell padding="checkbox">{i + 1}</TableCell>
            <TableCell>{name}</TableCell>
            {arr.map(key => <TableCell key={key} align='right'>{(obj[key].price[id] || 0).toLocaleString()}</TableCell>)}
            <TableCell align='right'>{summ.toLocaleString()}</TableCell>
          </TableRow>
        })}
      </TableBody>
    </Table>
  </div>
}

function TableResult({ accounts, project, estimateObj, priceOrSumm }) {
  return <Table size='small'>
    <TableHead>
      <TableRow>
        {tableHeadResult.map(obj => {
          const align = obj.align || 'inherit'
          return (<TableCell key={obj.id} align={align} padding={obj.padding}>{obj.name}</TableCell>);
        })}
        {accounts.allIds.map(key => {
          const acc = accounts.byId[key]
          return (<TableCell key={key} align='right'>{acc.accountName}</TableCell>);
        })}
      </TableRow>
    </TableHead>
    <TableBody>
      {project.group.arr.map(groupKey => {
        return <RowResult
          key={groupKey}
          obj={project.group.obj[groupKey]}
          child={project.group.obj[groupKey].child}
          estimate={estimateObj}
          accounts={accounts}
          priceOrSumm={priceOrSumm}
        />
      })}
    </TableBody>
  </Table>
}

function RowResult({ obj, child, estimate, accounts, priceOrSumm }) {
  const [show, changeShow] = useState(true)
  return (
    <React.Fragment>
      <TableRow style={{ backgroundColor: '#eee' }} onClick={() => changeShow(!show)}>
        <TableCell padding="checkbox">{obj.numberPos}</TableCell>
        <TableCell colSpan={6 + accounts.allIds.length}>{obj.name}</TableCell>
      </TableRow>
      {
        show && child.map((id, i) => {
          const est = { ...estimate[id], number: obj.numberPos + '.' + (i + 1) };
          return <TableRow key={id} >
            {tableHeadResult.map(e => {
              return <TableCell key={e.id} align={e.align} padding={e.padding}>{est[e.id]}</TableCell>
            })}
            {accounts.allIds.map(key => {
              const acc = accounts.byId[key]
              const price = est.price[acc.id.toString()] || 0
              const priceFinal = priceOrSumm ? (Math.round(price * est.count * 100) / 100) : price
              return <TableCell key={key} align='right' style={{}}>
                {priceFinal.toLocaleString()}
              </TableCell>
            })}
          </TableRow>
        })}
    </React.Fragment>
  );
}