import React from 'react'
import withFetching from './fetch-wrapper';
import {
  CsvViewer,
  PDFViewer,
  PhotoViewerWrapper,
  XlsxViewer
} from './drivers'
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux'
import { fetchDispath } from '../../functions/fetch'
import { getFile } from '../../api/list'
import { API } from '../../conf'

export default function FileViewer({ fileType, fileName }) {
  const filePath = API + getFile.path({ id: fileName })
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(fetchDispath({
      param: {
        id: fileName,
      },
      progress: false,
      request: getFile,
      buff: true
    }))
      .then(res => {
        if (!res) return;
        res.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          setTimeout(function () {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          }, 0);
        })
      })
  }
  const Driver = getDriver({ fileType, fileName, filePath })
  return <div className="pg-viewer-wrapper">
    <div className="pg-viewer" id="pg-viewer">
      <Button onClick={handleClick} color='primary'>Скачать</Button><br />
      <Driver {...{ fileType, fileName, filePath }} />
    </div>
  </div>
}


function getDriver(props) {
  switch (props.fileType) {
    case 'csv': {
      return withFetching(CsvViewer, props);
    }
    case 'xlsx': {
      const newProps = { ...props, responseType: 'arraybuffer' }
      return withFetching(XlsxViewer, newProps);
    }
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'bmp':
    case 'webp':
    case 'png': {
      return PhotoViewerWrapper;
    }
    case 'pdf': {
      return PDFViewer;
    }
    // case 'docx': {
    //     return DocxViewer;
    // }
    // case 'mp3': {
    //     return AudioViewer;
    // }
    // case 'webm':
    // case 'mp4': {
    //     return VideoViewer;
    // }
    // case 'wexbim': {
    //     return XBimViewer;
    // }
    default: {
      return ()=><div>unsupported</div>;
    }
  }
}