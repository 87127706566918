import React from 'react';
import PageList from '../../components/PageListAPI'
import { getOpenTendersConfirmStatus } from '../../api/tender.api'
import { TENDER_STATUS_BID } from './TenderMyRequestOne'

const columns = [
  { id: 'name', name: 'Название' },
  { id: 'facilityName', name: 'Объект' },
  {
    id: 'requestStatusRus', name: 'Статус заявки',
    type: 'component', component: ({ obj }) => <StatusColored obj={obj} />
  },
  { id: 'dateConfirmStart', name: 'Дата начала подтверждения участия', type: 'date', formatDate: 'LLL' },
  { id: 'dateConfirmEnd', name: 'Дата окончания подтверждения участия', type: 'date', formatDate: 'LLL' },
  { id: 'dateOfferEnd', name: 'Дата окончания предложения', type: 'date', formatDate: 'LLL' },
  { id: 'dateSummingEnd', name: 'Дата подведения итогов', type: 'date', formatDate: 'LLL' },
]

export default function TenderMyRequestList(props) {
  return (
    <div className='App-paper'>
      <PageList
        title='Открытые тендеры в статусе подтверждение участия'
        name='tender-open-list'
        columns={columns}
        createItem={false}
        deleteItem={false}
        loadAPI={{
          request: getOpenTendersConfirmStatus,
          transform: (res) => {
            return {
              ...res,
              get: res.get.map(e => ({
                ...e,
                requestStatusObj: e.requestStatus ? TENDER_STATUS_BID.find(s => s.value === e.requestStatus) : { value: null, label: 'не подана', color: '#333' }
              }))
            }
          }
        }}
        onClickFunction={(row, obj) => {
          if (obj.requestStatus) {
            props.history.push(`${props.match.url.replace('/open', '/my-request')}/${obj.id}`)
          }
          else {
            props.history.push(`${props.match.url}/${obj.id}`)
          }
        }}
        filterProps={[
          {
            arr: TENDER_STATUS_BID.map(e => ({ value: e.value, name: e.label })),
            field: 'statusBid',
            name: 'Статус заявки',
            type: 'select'
          },
        ]}
        headerInit={{
          sortBy: 'dateConfirmStart',
          sortDirection: 'desc',
        }}
        {...props}
      />
    </div>
  )
};
function StatusColored({ obj }) {
  if (!obj.requestStatusObj) return null
  return <>
    <div style={{ color: obj.requestStatusObj.color }}>{obj.requestStatusObj.label}</div>
  </>
}