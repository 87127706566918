import { createStore, applyMiddleware  } from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension';
import { rootReducer } from '../reducers'
import reduxCatch from 'redux-catch'
import ReduxThunk from 'redux-thunk'
import refreshToken from './requestMiddleware'
function errorHandler(error, getState, lastAction, dispatch) {
    console.error(error);
    console.debug('current state', getState());
    console.debug('last action was', lastAction);
  }
export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(reduxCatch(errorHandler),refreshToken(),ReduxThunk)));
