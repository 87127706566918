import React, { useEffect, useState } from 'react'
import * as api from '../../api/machine'
import { useDispatch } from 'react-redux'
import {
  Button, Grid, TextField, Typography,
  Divider, List, ListItem, ListItemText,
  Tabs, Tab, IconButton, ListItemIcon
} from '@material-ui/core'
import { fetchDispath } from '../../functions/fetch'
import GridProps from '../../components/GridProps'
import moment from 'moment'
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

export default function Machine({ match, history }) {
  const [list, setList] = useState(0)
  const [pswd, setPswd] = useState(localStorage.getItem(match.params.id) || '')
  const [info, setInfo] = useState(false)
  const [mileageStart, setMileageStart] = useState(0)
  const [mileageEnd, setMileageEnd] = useState(0)
  const [dateStart, setDateStart] = useState(null)
  const [dateEnd, setDateEnd] = useState(null)
  const [diffTime, setDiffTime] = useState('')
  const [param, setParam] = useState({
    date: '',
    time: '',
    duration: '',
    model: '',
    govNumber: '',
    driver: '',
  })
  const [task, setTask] = useState([])
  const [taskResult, setTaskResult] = useState([])
  const dispatch = useDispatch({ match })
  const handleSetPSWD = () => {
    dispatch(fetchDispath({
      param: { id: match.params.id, },
      querty: { pswd: pswd },
      progress: true,
      request: api.getMachineInfo,
    })).then(res => {
      if (!res.success) return;
      setParam({
        date: moment(res.get.date).format('DD.MM.YYYY'),
        time: res.get.time,
        duration: res.get.duration,
        model: res.get.model,
        govNumber: res.get.govNumber,
        driver: `${res.get.surname} ${res.get.name} ${res.get.patronymic}`,
      })
      setDateStart(res.get.dateStart)
      setDateEnd(res.get.dateEnd)
      setMileageStart(res.get.mileageStart || 0)
      setMileageEnd(res.get.mileageEnd || 0)
      setTask(res.task)
      setTaskResult(res.taskResult.map(obj => ({
        key: 'id-' + obj.id,
        id: obj.id,
        idTask: obj.idTask,
        count: obj.countResult,
        distance: obj.distanceResult,
        transported: obj.transportedResult,
        arrivalTime: obj.arrivalTimeResult,
      })))
      localStorage.setItem(match.params.id, pswd)
      setInfo(true)
      if (!res.get.dateStart) return;
      const diff = moment(new Date()).diff(res.get.dateStart);
      const duration = moment.duration(diff);
      const hrs = duration.hours(),
        mins = duration.minutes(),
        secs = duration.seconds();
      setDiffTime(`${hrs > 9 ? hrs : '0' + hrs}:${mins > 9 ? mins : '0' + mins}:${secs > 9 ? secs : '0' + secs}`)

    }).catch(err => console.log(err))
  }
  const handleStart = () => {
    dispatch(fetchDispath({
      param: { id: match.params.id, },
      body: { pswd: pswd, mileageStart: mileageStart },
      progress: true,
      request: api.updMachineStart,
    })).then(res => {
      setDateStart(new Date())
    }).catch(err => console.log(err))
  }
  const handleEnd = () => {
    dispatch(fetchDispath({
      param: { id: match.params.id, },
      body: { pswd: pswd, mileageEnd: mileageEnd },
      progress: true,
      request: api.updMachineEnd,
    })).then(res => {
      setDateEnd(new Date())
    }).catch(err => console.log(err))
  }
  useEffect(() => {
    if (!dateStart) return;
    setTimeout(() => {
      const diff = moment(new Date()).diff(dateStart);
      const duration = moment.duration(diff);
      const hrs = duration.hours(),
        mins = duration.minutes(),
        secs = duration.seconds();
      setDiffTime(`${hrs > 9 ? hrs : '0' + hrs}:${mins > 9 ? mins : '0' + mins}:${secs > 9 ? secs : '0' + secs}`)
    }, 1000 * 60)
    return () => {
    }
  }, [dateStart, diffTime])
  if (!info) {
    return <div className='App-paper'>
      <img src="/logo.svg" alt="logo" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '50%', maxWidth: '200px' }} />
      <TextField label="Пароль" variant="outlined" fullWidth onChange={(e) => setPswd(e.target.value)} value={pswd} />
      <Button onClick={handleSetPSWD} color='primary'>Запросить информацию</Button>
    </div>
  }
  return <div className='App-paper'>
    <img src="/logo.svg" alt="logo" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '50%', maxWidth: '200px' }} />
    <Typography variant='h6'>Добро пожаловать {param.driver}</Typography>

    <Tabs value={list} onChange={(e, v) => setList(v)} indicatorColor='primary' textColor="primary" variant="fullWidth">
      {['Инфо', 'Контрольные листы', 'Задачи'].map((e, i) => {
        return <Tab key={i} label={e} />
      })}
    </Tabs>
    <div>
      {!dateStart && <div>
        <TextField label="Значение одометра на старте" type='number' variant="outlined" fullWidth onChange={(e) => setMileageStart(e.target.value)} value={mileageStart} />
        <Button onClick={handleStart} color='primary'>Начать работу</Button>
      </div>}
      {dateStart && !dateEnd && <div>
        <Typography variant='h6' color='secondary'>Время с начала работы {diffTime}</Typography>
        <TextField label="Значение одометра в конце" type='number' variant="outlined" fullWidth onChange={(e) => setMileageEnd(e.target.value)} value={mileageEnd} />
        <Button onClick={handleEnd} color='primary'>Закончить работу</Button>
      </div>}
      {dateStart && dateEnd && <Typography variant='h6' color='secondary'>Работы завершены</Typography>}
    </div>

    {
      list === 0 && <Info
        model={param.model}
        govNumber={param.govNumber}
        date={param.date}
        time={param.time}
        dateStart={dateStart}
        dateEnd={dateEnd}
        mileageEnd={mileageEnd}
        mileageStart={mileageStart}
      />
    }
    {list === 1 && <QuestionList uuid={match.params.id} pswd={pswd} />}
    {list === 2 && <TaskList task={task} taskResult={taskResult} pswd={pswd} match={match} reloadData={handleSetPSWD} />}
  </div>
}

const column = [
  { id: 'order', type: 'text', name: 'в чье распоряжение' },
  { id: 'arrivalTime', type: 'text', name: 'время прибытия, ч. мин' },
  { id: 'loadingAddress', type: 'text', name: 'адрес погрузки' },
  { id: 'unloadingAddress', type: 'text', name: 'адрес разгрузки' },
  { id: 'cargoName', type: 'text', name: 'наименование груза' },
  { id: 'count', type: 'number', name: 'количество ездок' },
  { id: 'distance', type: 'number', name: 'расстояние, км' },
  { id: 'transported', type: 'number', name: 'перевезти, тонн' },
]
const columnResult = [
  { id: 'count', type: 'number', name: 'количество ездок' },
  { id: 'distance', type: 'number', name: 'расстояние, км' },
  { id: 'transported', type: 'number', name: 'перевезти, тонн' },
]
const rowDefault = {
  key: '',
  id: '',
  idTask: '',
  count: 1,
  distance: '',
  transported: '',
  arrivalTime: '',
}
function TaskList({ task, taskResult, pswd, match, reloadData }) {
  const [result, setResult] = useState([])
  const [indexTask, setIndexTask] = useState(0)
  const dispatch = useDispatch()
  useEffect(() => {
    setResult(taskResult)
  }, [taskResult])
  const handleClick = (id) => {
    setResult(old => [...old, { ...rowDefault, idTask: id, key: 'id' + indexTask }])
    setIndexTask(old => (old + 1))
  }
  const fndObj = (key) => result.find(e => key === e.key);
  const findIndex = (key) => result.findIndex(e => key === e.key);
  const handleChange = (value, field, key) => {
    const i = findIndex(key);
    const arr = [...result];
    arr[i][field] = value;
    setResult(arr)
  }
  const handleSaveTaskRes = (key) => {
    const obj = fndObj(key);
    dispatch(fetchDispath({
      param: { id: match.params.id, },
      body: {
        pswd: pswd,
        idTaskResult: obj.id,
        idTask: obj.idTask,
        arrivalTime: obj.arrivalTime,
        count: obj.count,
        distance: obj.distance,
        transported: obj.transported,
      },
      progress: true,
      request: api.updMachineTaskResult,
    })).then(res => {
      if (res.success) {
        handleChange(res.idTaskResult, 'id', key)
      }
    }).catch(err => console.log(err))
  }
  const removeTaskResult = (key) => {
    const i = findIndex(key)
    setResult([
      ...result.slice(0, i),
      ...result.slice(i + 1, result.length)
    ]);
  }
  const handleDeleteTaskRes = (key) => {
    const obj = fndObj(key)
    if (obj.id === '') {
      removeTaskResult(key)
    }
    else {
      dispatch(fetchDispath({
        param: { id: match.params.id, },
        body: {
          pswd: pswd,
          idTaskResult: obj.id,
        },
        progress: true,
        request: api.removeMachineTaskResult,
      })).then(res => {
        if (res.success) {
          removeTaskResult(key)
        }
      }).catch(err => console.log(err))
    }
  }
  const handleClickFinishedStart = (id, isFinished) => {
    dispatch(fetchDispath({
      param: { id: match.params.id, },
      body: {
        pswd: pswd,
        idTask: id,
        isFinished: isFinished,
      },
      progress: true,
      request: api.updMachineTaskFinish,
    })).then(res => {
      if (res.success) {
        reloadData()
      }
    }).catch(err => console.log(err))
  }
  return <div>
    <Typography>Задание водителю</Typography>
    {task.map(e => {
      return <div className='App-paper' style={{ margin: 10 }} key={e.id}>
        {column.map(key => {
          return <Typography key={key.id}><b>{key.name}:</b> {e[key.id]}</Typography>
        })}
        <Button color='primary' disabled={e.isFinished} onClick={() => handleClick(e.id)}>Добавить результат</Button>
        {!e.isFinished && <Button color='primary' onClick={() => handleClickFinishedStart(e.id, true)}>Завершить</Button>}
        {e.isFinished && <Button color='primary' onClick={() => handleClickFinishedStart(e.id, false)}>Продолжить</Button>}
        {result.filter(s => s.idTask === e.id).map((res, i) => {
          if (e.isFinished) {
            return <div key={res.key}>
              <Divider />
              <Typography><b>№{i + 1}</b></Typography>
              {columnResult.map(key => {
                return <Typography key={key.id}><b>{key.name}:</b> {e[key.id]}</Typography>
              })}
            </div>
          }
          return <div key={res.key}>
            <Divider />
            <Typography><b>№{i + 1}</b></Typography>
            <TextField
              fullWidth
              label="время прибытия ч. мин."
              value={res.arrivalTime}
              type="time"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleChange(e.target.value, 'arrivalTime', res.key)} />
            <TextField
              fullWidth
              label="расстояние,км"
              value={res.distance}
              onChange={(e) => handleChange(e.target.value, 'distance', res.key)} />
            <TextField
              fullWidth
              label="перевезено, тонн"
              value={res.transported}
              onChange={(e) => handleChange(e.target.value, 'transported', res.key)} />
            <Button color='primary' onClick={() => handleSaveTaskRes(res.key)}>Сохранить</Button>
            <Button color='secondary' onClick={() => handleDeleteTaskRes(res.key)}>Удалить</Button>
          </div>
        })}
      </div>
    })}
  </div>
}

function Info(param) {
  return <Grid
    container
    direction="column"
    justifyContent="flex-start"
    alignItems="flex-start"
  >
    <Grid item container xs={12}>
      <GridProps name='Модель транспорта:' value={param.model} />
      <GridProps name='Гос. номер:' value={param.govNumber} />
      <GridProps name='Дата:' value={param.date} />
      <GridProps name='Время:' value={param.time} />
      <GridProps name='Продолжительность:' value={param.duration} />
      <GridProps name='Начало работы:' value={param.dateStart ? moment(param.dateStart).format('LLL') : ''} />
      <GridProps name='Конец работы:' value={param.dateEnd ? moment(param.dateEnd).format('LLL') : ''} />
      <GridProps name='Значение одометра на старте:' value={param.mileageStart} />
      <GridProps name='Значение одометра в конце:' value={param.mileageEnd} />
    </Grid>
  </Grid>
}


function QuestionList({ uuid, pswd }) {
  const [arr, setArr] = useState([])
  const [idSelect, setSelect] = useState(-1)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDispath({
      param: { id: uuid, },
      querty: { pswd: pswd },
      progress: true,
      request: api.getMachineCheckList,
    })).then(res => {
      if (!res.success) return;
      setArr(res.get)
    }).catch(err => console.log(err))
    return () => {
    }
  }, [dispatch, uuid, pswd])
  const handleSelectId = (id, idChWB) => {
    if (idChWB) setSelect(idChWB)
    else {
      dispatch(fetchDispath({
        param: { id: uuid, },
        querty: { pswd: pswd },
        body: { idChecklist: id },
        progress: true,
        request: api.startMachineCheckList,
      })).then(res => {
        if (!res.success) return;
        setSelect(res.get)
      }).catch(err => console.log(err))
    }
  }
  const handleFinished = () => {
    setSelect(-1)
    dispatch(fetchDispath({
      param: { id: uuid, },
      querty: { pswd: pswd },
      progress: true,
      request: api.getMachineCheckList,
    })).then(res => {
      if (!res.success) return;
      setArr(res.get)
    }).catch(err => console.log(err))
  }
  return <div>
    <Typography>Контрольные листы</Typography>
    {idSelect === -1 && <List>
      {arr.map(e => {
        return <ListItem key={e.id} button onClick={() => { handleSelectId(e.id, e.checklistWaybillId) }}>
          <ListItemIcon>
          {e.dateFinish &&<CheckCircleOutlineIcon style={{ color: 'green'}} />}
          {!e.dateFinish &&<RadioButtonUncheckedIcon style={{ color: 'blue'}} />}
            
          </ListItemIcon>
          <ListItemText>{e.name}</ListItemText>
        </ListItem>
      })}
    </List>}
    {idSelect !== -1 && <div>
      <Button onClick={() => setSelect(-1)} color="primary">К списку</Button>
      <QuestionOne uuid={uuid} pswd={pswd} id={idSelect} finished={() => handleFinished()} />
    </div>}
  </div>
};


function QuestionOne({ uuid, pswd, id, finished }) {
  const [arr, setArr] = useState([])
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(-1)
  const [currentQuestion, setCurrentQuestion] = useState({
    id: 1,
    questionNumber: 1,
    question: "",
    description: "",
    answerType: "checkbox",
    answerParam: {},
    idAnswer: null,
    answer: null
  })
  const [finish, setFinish] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    if (id === -1) return
    dispatch(fetchDispath({
      param: { id: uuid, idChWB: id },
      querty: { pswd: pswd },
      progress: true,
      request: api.getCheckListWBId,
    })).then(res => {
      if (!res.success) return;
      setArr(res.get)
      if (res.get.length > 0) {
        setCurrentQuestionNumber(0)
        setCurrentQuestion(res.get[0])
      }
    }).catch(err => console.log(err))
    return () => {
    }
  }, [dispatch, uuid, pswd, id])
  const handleNext = (answerSet) => {
    if (answerSet) {
      const newArr = [...arr]
      newArr[currentQuestionNumber].answer = answerSet
      setArr(newArr)
    }
    if (arr.length === currentQuestionNumber + 1) setFinish(true)
    else {
      setCurrentQuestion(arr[currentQuestionNumber + 1])
      setCurrentQuestionNumber(e => e + 1)

    }
  }
  const handlePrev = () => {
    setCurrentQuestion(arr[currentQuestionNumber - 1])
    setCurrentQuestionNumber(e => e - 1)
  }
  const handleFinish = () => {
    dispatch(fetchDispath({
      param: { id: uuid, idChWB: id },
      querty: { pswd: pswd },
      progress: true,
      request: api.updCheckListWBIdStop,
    })).then(res => {
      if (!res.success) return;
      finished()
    }).catch(err => console.log(err))
  }
  if (id === -1) return null
  if (arr.length === 0) return null
  if (finish) {
    return <div className='App-paper' style={{ margin: 10 }}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Grid><Typography><b>Вопросы закончились</b></Typography></Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>
            <Button onClick={()=>{setFinish(false)}} variant="outlined" color="primary">Назад</Button>
          </Grid>
          <Grid>
            <Button onClick={handleFinish} variant="contained" color="primary">Завершить</Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  }
  return <div className='App-paper' style={{ margin: 10 }}>
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <Grid><Typography><b>{currentQuestion.question}</b></Typography></Grid>
      <Grid style={{ minHeight: 200, textAlign: 'left', width: '100%' }}><Typography>{currentQuestion.description}</Typography></Grid>
      <Grid><AnswerType
        uuid={uuid}
        pswd={pswd}
        idChWB={id}
        answerType={currentQuestion.answerType}
        answerParam={currentQuestion.answerParam}
        questionId={currentQuestion.id}
        answer={currentQuestion.answer}
        handleNext={(answerSet) => handleNext(answerSet)}
      /></Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid>
          <IconButton onClick={handlePrev} disabled={currentQuestionNumber === 0}>
            {currentQuestionNumber === 0 ? <FirstPageIcon /> : <ArrowBackIosIcon />}
          </IconButton>
        </Grid>
        <Grid>
          <IconButton onClick={() => handleNext()}>
            {currentQuestionNumber === arr.length - 1 ? <LastPageIcon /> : <ArrowForwardIosIcon />}
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  </div>
}


function AnswerType({ uuid, pswd, idChWB, answerType, answerParam, questionId, handleNext, answer }) {
  const dispatch = useDispatch()
  const handleAnswer = (answerSet) => {
    dispatch(fetchDispath({
      param: { id: uuid, idChWB: idChWB },
      querty: { pswd: pswd },
      body: {
        questionId,
        answer: answerSet
      },
      progress: true,
      request: api.updCheckListWBIdAnswer,
    })).then(res => {
      if (!res.success) return;
      handleNext(answerSet)
    }).catch(err => console.log(err))
  }
  useEffect(() => {
    setAnswerNew(answer)
  }, [questionId])
  const [answerNew, setAnswerNew] = useState('')
  switch (answerType) {
    case 'checkbox': {
      return <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid><Button onClick={() => handleAnswer('Да')} variant="outlined" color={answer === 'Да' ? "secondary" : "primary"}>Да</Button></Grid>
        <Grid><Button onClick={() => handleAnswer('Нет')} variant="outlined" color={answer === 'Нет' ? "secondary" : "primary"}>Нет</Button></Grid>
      </Grid>
    }
    case 'button': {
      return <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        {answerParam && answerParam.buttonArr && answerParam.buttonArr.map((e, i) => {
          return <Grid>
            <Button
              key={i}
              onClick={() => handleAnswer(e.name)}
              variant="outlined"
              color={answer === e.name ? "secondary" : "primary"}
            >{e.name}
            </Button>
          </Grid>
        })}
      </Grid>
    }
    case 'text': {
      return <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={10}><TextField
          fullWidth
          onChange={(e) => setAnswerNew(e.target.value)}
          value={answerNew}
          variant="outlined"
        /></Grid>
        <Grid item xs={2}><IconButton onClick={() => handleAnswer(answerNew)}>
          <ArrowForwardIosIcon />
        </IconButton></Grid>
      </Grid>
    }
    default:
      return null;
  }
}