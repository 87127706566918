import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Table, TableHead, TableBody, TableRow, DialogActions, FormControl, IconButton, InputLabel,
  TableCell, Button, Dialog, DialogTitle, DialogContent, Input,
  Typography,
} from '@material-ui/core'
import * as actions from '../../../reducers/form';
import { fetchDispath } from '../../../functions/fetch'
import { setTenderOurBid, getOneOurTenderBid, addOurTenderBid } from '../../../api/tender.api'
import { TENDER_STATUS_BID } from '../../tender/TenderMyRequestOne'
import Autocomplite from '../../../components/Autocomplite'
import EditIcon from '@material-ui/icons/Edit';

const tableBidColumn = [
  { name: 'Наименование', id: 'name' },
  { name: 'Статус', id: 'statusRus' },
  { name: 'Контакты', id: 'contacts' },
  { name: 'Почта', id: 'email' },
  { name: 'ИНН', id: 'inn' },
]
export default function TenderRequest({ idDoc, formBid }) {
  const [openRequest, setOpenRequest] = useState(false)
  const [keyRequest, setKeyRequest] = useState(null)
  const values = useSelector(state => state.form[formBid]?.values) || { byId: {}, allIds: [] }
  const handleCloseDialog = () => {
    setOpenRequest(false)
    setKeyRequest(null)
  }
  const handleSelectRequest = (key) => {
    setKeyRequest(key)
    setOpenRequest(true)
  }
  return <div>
    <RequestDialog
      idDoc={idDoc}
      open={openRequest}
      onClose={handleCloseDialog}
      keyRequest={keyRequest}
      formBid={formBid}
    />
    <Button color="primary" onClick={() => setOpenRequest(true)}>Добавить </Button>
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell padding='checkbox'></TableCell>
          <TableCell padding='checkbox'>№</TableCell>
          {tableBidColumn.map(obj => {
            const align = obj.align || 'inherit'
            return (<TableCell key={obj.id} align={align}>{obj.name}</TableCell>);
          })}
          <TableCell>Доступ</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {values.allIds.map((key, i) => {
          const bid = values.byId[key]
          return <TableRow key={key}>
            <TableCell padding='checkbox'>
              <IconButton style={{ fontSize: '0.875rem' }} color='primary' size='small' onClick={() => handleSelectRequest(key)}>
                <EditIcon fontSize="inherit" />
              </IconButton>
            </TableCell>
            <TableCell padding='checkbox'>{i + 1}</TableCell>
            {tableBidColumn.map(obj => {
              return <TableCell key={bid.id + '_' + obj.id}>{bid[obj.id]}</TableCell>
            })}
            <TableCell >
              <Typography>{bid.accessKey}</Typography>
            </TableCell>
          </TableRow>
        })}
      </TableBody>
    </Table>
  </div>
}

const requestInit = {
  name: '',
  inn: '',
  email: '',
  contacts: '',
  statusValue: TENDER_STATUS_BID.find(s => s.value === 'confirm'),
}
const columnDialog = [
  { name: 'ИНН', id: 'inn' },
  { name: 'Наименование', id: 'name' },
  { name: 'Контакты', id: 'contacts' },
  { name: 'Почта', id: 'email' },
]
function RequestDialog({ idDoc, open, onClose, keyRequest, formBid }) {
  const byId = useSelector(state => state.form[formBid]?.values.byId || {})
  const [request, setRequest] = useState(requestInit)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!open) return
    if (!keyRequest) return
    const obj = byId[keyRequest]
    setRequest({
      name: obj.name || '',
      inn: obj.inn || '',
      email: obj.email || '',
      contacts: obj.contacts || '',
      statusValue: TENDER_STATUS_BID.find(s => s.value === obj.status),
    })
  }, [open, keyRequest, byId, formBid])
  const handleChangeValue = (value, key) => {
    setRequest(old => ({ ...old, [key]: value }))
  }
  const handleClose = () => {
    onClose()
    setRequest(requestInit)
  }
  const updRequest = () => {
    dispatch(fetchDispath({
      progress: true,
      request: getOneOurTenderBid,
      param: {
        id: idDoc
      }
    })).then(res => {
      let byId = {}
      const allIds = res.get.map(e => {
        const newId = 'id' + e.id;
        byId[newId] = { ...e, statusRus: TENDER_STATUS_BID.find(s => s.value === e.status).label, }
        return newId
      });
      dispatch(actions.formChangeAsObj({ byId, allIds }, { name: formBid }))

    }).catch(err => console.log(err))
  }
  const handleUpdContacts = () => {
    const obj = byId[keyRequest]
    dispatch(fetchDispath({
      progress: true,
      request: setTenderOurBid,
      param: {
        id: idDoc,
        idBid: obj.id
      },
      body: {
        name: request.name,
        inn: request.inn,
        email: request.email,
        contacts: request.contacts,
        status: request.statusValue.value
      }
    })).then(() => {
      updRequest()
      handleClose()
    })
  }
  const handleAddContacts = () => {
    dispatch(fetchDispath({
      progress: true,
      request: addOurTenderBid,
      param: {
        id: idDoc,
      },
      body: {
        name: request.name,
        inn: request.inn,
        email: request.email,
        contacts: request.contacts,
        status: request.statusValue.value
      }
    })).then(() => {
      updRequest()
      handleClose()
    })
  }
  return <Dialog
    open={open}
    keepMounted
    fullWidth
    scroll="paper"
    onClose={() => handleClose()}
    closeAfterTransition={false}
  >
    <DialogTitle>{keyRequest ? 'Изменить заявку' : 'Добавить заявку'}</DialogTitle>
    <DialogContent>
      <form noValidate autoComplete="off">
        <FormControl fullWidth>
          <Autocomplite
            palaceHolder='Статус'
            action={(e) => handleChangeValue(e, 'statusValue')}
            arr={TENDER_STATUS_BID}
            value={request.statusValue}
          />
        </FormControl>
        {columnDialog.map(col => {
          return <FormControl key={col.id} fullWidth>
            <InputLabel htmlFor={"editor" + col.id}>{col.name}</InputLabel>
            <Input
              id={"editor" + col.id}
              value={request[col.id]}
              onChange={(e) => handleChangeValue(e.target.value, col.id)}
              name={col.id}
            />
          </FormControl>
        })}
      </form>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => handleClose()} color="primary">
        Отмена
      </Button>
      <Button onClick={() => keyRequest ? handleUpdContacts() : handleAddContacts()} color="primary">
        {keyRequest ? 'Изменить' : 'Добавить'}
      </Button>
    </DialogActions>
  </Dialog>
}