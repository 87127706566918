import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as api from '../../api/facility'
import { fetchDispath } from '../../functions/fetch'
import { FormControl, Select, MenuItem, ListItem } from '@material-ui/core'
import { headerSetProject } from '../../reducers/header'
import { API_BO } from '../../conf'
import { useHistory } from "react-router-dom";

export default function ListProjectSelect({ facilityId }) {
  let history = useHistory();
  const [arr, setArr] = useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    if (facilityId === 0) { setArr([]); return; }
    dispatch(fetchDispath({
      progress: true,
      request: api.getFacilityOurProject,
      param: {
        id: facilityId
      }
    }))
      .then(res => {
        setArr(res.get.filter(e => !e.isHidden).map(e => ({ id: e.id, name: e.name })))
      })
      .catch(err => console.log(err))
    return () => {
    }
  }, [dispatch, facilityId])
  const [projectId, setProject] = useState('')
  const projectIdHeader = useSelector(state => state?.header?.projectId || '')
  useEffect(() => {
    if (projectIdHeader === 0 || projectIdHeader === '') setProject('')
    if (projectIdHeader) {
      setProject(projectIdHeader)
    }
  }, [projectIdHeader])
  const handleChange = (e) => {
    const id = e.target.value
    const name = arr.find(e => e.id === id)
    setProject(e.target.value)
    dispatch(headerSetProject({ id: e.target.value, name: name.name }))
    const newPath = history.location.pathname.replace(/\/projects\/[0-9]+/,`/projects/${id}`)
    if(newPath===`${API_BO}/projects/${id}`){
      history.push(newPath);
    }
    else{
      history.push(newPath.replace(/\/[0-9]+$/,''));
    }
  }
  if (arr.length === 0) return null
  return <ListItem disabled={typeof facilityId === 'undefined' || facilityId === 0}>
    <FormControl variant="outlined" disabled={arr.length === 0}>
      <Select
        value={projectId}
        onChange={handleChange}
        fullWidth
        style={{ width: 210 }}
      >
        {arr.map(e => {
          return <MenuItem key={e.id} value={e.id} name={e.name}>
            {e.name}
          </MenuItem>
        })}
      </Select>
    </FormControl>
  </ListItem>
}