import React, { useState } from 'react';
import moment from 'moment'
import queryString from 'query-string'
import * as api from '../../api/storage'
import PageList from '../../components/PageListAPI'
import ChangeFilter from '../../components/FilterDate'
import getFormatDate, { getFormatDateTable } from '../../functions/getFormatDate'
const formName = 'storageMaterialDynamics'

export default function StorageDynamics(props) {
  const search = queryString.parse(props.location.search)
  const [filter, setFiler] = useState(search.filter || 'month')
  const [formatDate, setFormatDate] = useState(getFormatDate(search.filter || 'month'))
  const [formatDateTable, setFormatDateTable] = useState(getFormatDateTable(search.filter || 'month'))
  const [filterDate, setFilterDate] = useState(search.date || '')
  const [date, setDate] = useState(search.date)
  const changeDate = (e) => {
    if (date !== e.target.value) {
      let filterDate = (e.target.value !== '') ? moment(e.target.value, formatDate).format('YYYY-MM-DD') : undefined
      let search = queryString.parse(props.history.location.search)
      search.date = filterDate
      search.limit = '50'
      search.offset = '0'
      setFilterDate(e.target.value)
      setDate(search.date)
      props.history.push(`?${queryString.stringify(search)}`)
    }
  }
  const handleChangeFilterGroup = (value) => {
    if (value !== filter) {
      let search = queryString.parse(props.history.location.search)
      search.filter = value
      search.date = undefined
      search.limit = '50'
      search.offset = '0'
      setFiler(search.filter)
      setFormatDate(getFormatDate(search.filter))
      setFormatDateTable(getFormatDateTable(search.filter))
      setFilterDate('')
      setDate(search.date)
      props.history.push(`?${queryString.stringify(search)}`)
    }
  }
  const tableHead = [
    { id: 'date', name: 'Дата списания', type: 'date', formatDate: formatDateTable, padding: 'checkbox' },
    { id: 'name', name: 'Наименование' },
    { id: 'typeMark', name: 'Тип, марка' },
    { id: 'vendorCode', name: 'Артикул' },
    { id: 'count', name: 'Количество', align: 'right' },
    { id: 'storageUnit', name: 'Ед. изм.' },
  ];
  return (
    <div className='App-paper'>
      <PageList
        name={formName}
        columns={tableHead}
        title='Расходы материалов'
        loadAPI={{
          querty: {
            filter: filter,
            date: date,
          },
          param: {
            id: props.match.params.id,
          },
          request: api.getStorageMaterialStatus
        }
        }
        headerInit={{
          sortBy: 'date',
          sortDirection: 'desc',
        }}
        click={false}
        headerObj={<ChangeFilter
          filter={filter}
          onChange={handleChangeFilterGroup}
          changeDate={changeDate}
          filterDate={filterDate}
        />}
        {...props}
      />
    </div>
  )
}