import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent } from '@material-ui/core';
import FileViewer from '../../../../components/fileViewer/FileViewer';

export default function DialogFile({ fileName }) {
  const [open, setOpen] = useState(false)
  const [file, setFile] = useState({ filePath: '', type: '', fileName: '' })
  useEffect(() => {
    if (fileName.split('.').length < 2) return;
    const name = fileName
    const type = fileName.split('.').reverse()[0]
    setFile({ type: (type !== 'xls') ? type : 'xlsx', fileName: name })
    setOpen(true);
  }, [fileName])
  return <Dialog
    open={open}
    maxWidth={'lg'}
    keepMounted
    fullWidth
    scroll="paper"
    onClose={() => setOpen(false)}
  >
    <DialogContent >
      {open && <FileViewer
        fileType={file.type}
        fileName={file.fileName}
      />}
    </DialogContent>
  </Dialog>
}

