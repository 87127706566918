import React from 'react';
import { Table, TableContainer, TableBody } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import TableHeader from './TableHeader'
import TableRowMaterial from './TableRowMaterial'
import { formArrayAddManyUnique, formArrayDeleteMany } from '../../../../reducers/form';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    height: '80vh'
  },
}));
export default function TableMain({ formName }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const allIds = useSelector(store => store.formTable[formName]?.allIds || [])
  const countSelect = useSelector(store => store.form[formName]?.values.countSelect || 0)
  const multiSelect = useSelector(store => store.form[formName]?.values.multiSelect || false)
  const selectMulti = (idStart, checked) => {
    const indexOf = allIds.indexOf(idStart)
    const arrAdd = allIds.slice(indexOf, indexOf + countSelect)
    if (checked) {
      dispatch(formArrayAddManyUnique(arrAdd, { field: 'selectId', name: formName }))
    }
    else {
      dispatch(formArrayDeleteMany(arrAdd, { field: 'selectId', name: formName }))
    }
  }
  return <TableContainer className={classes.container}>
    <Table stickyHeader >
      <TableHeader formName={formName} />
      <TableBody>
        {allIds
          .map(row => {
            return (<TableRowMaterial key={row} row={row} selectMulti={selectMulti} multiSelect={multiSelect} formName={formName} />)
          })}
      </TableBody>
    </Table>
  </TableContainer>
}