import React from 'react'
import TenderMyRequestOne from '../tender/TenderMyRequestOne'
import { API_BO } from '../../conf'
import { Redirect } from 'react-router-dom';

export default function TenderShareMain(props) {
  const token = localStorage.getItem('token');
  if (typeof token === undefined || token === '') {
    return <Redirect to={API_BO + '/tender_share/login'} />
  }
  return <div>
    <TenderMyRequestOne share={true} {...props} />
  </div>
}