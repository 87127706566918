import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button, TextField, Grid, Typography } from '@material-ui/core'
import { setOcrSetting, egtOcrSetting } from '../../api/ocr'
import { formSubmitAPI} from '../../reducers/form';
import { fetchDispath } from '../../functions/fetch'

export default function OcrSetting({ history }) {
  const dispatch = useDispatch()
  const [login, setLogin] = useState('')
  const [pswd, setPwd] = useState('')
  const [balance, setBalance] = useState(0)
  const [space, setSpace] = useState('')
  useEffect(()=>{
    dispatch(fetchDispath({
      progress: true,
      request: egtOcrSetting,
    })).then(res => {
      if(!res.get) return;
      console.log(res)
      setLogin(res.get.login)
      setBalance(res.get.balance)
      setSpace(res.get.space)
    })
  },[dispatch])
  const handleSave = () => {
    dispatch(formSubmitAPI({
      props: {
        login: login,
        password: pswd,
        space: space
      },
      url: setOcrSetting.path,
      method: setOcrSetting.type,
      history: history,
    }, 'formName'))
  }
  return <div className='App-paper'>
    <Typography variant='h6'>Настройки распознавания</Typography>
    <Typography >Баланс: {balance}</Typography>
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={6}>
        <TextField label='Логин' value={login} onChange={(e) => setLogin(e.target.value)} fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField label='Пространство документов' value={space} onChange={(e) => setSpace(e.target.value)} fullWidth/>
      </Grid>
      <Grid item xs={6}>
        <TextField label='Пароль' value={pswd} onChange={(e) => setPwd(e.target.value)} type='password' fullWidth/>
      </Grid>
    </Grid>
    <Button color='primary' component="span" onClick={() => history.goBack()}>Назад</Button>
    <Button color='primary' component="span" onClick={handleSave}>Сохранить</Button>
  </div>

}