import React, { useState } from 'react';
import { Button, Card, CardActions, CircularProgress, Grid, TextField, Typography, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { saveState } from '../../localStorage'
import { useDispatch } from 'react-redux';
import { parser } from '../../functions/fetch'
import { loginWorker } from '../../api/app_worker'
import { API } from '../../conf'
import { enqueueSnackbar } from '../../reducers/notifier'
import { API_BO } from '../../conf'

const useStyles = makeStyles(theme => ({
  main: {
    minHeight: '100vh',
    backgroundColor: "#163dec"
  },
  card: {
    minWidth: 300,
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
}));
const fetchGen = (path, body) => {
  return fetch(API + path, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  })
    .then(parser)
}
export default function WorkerLogin({ history }) {
  const classes = useStyles()
  return (
    <div className={classes.main}>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="stretch"
        className={classes.main}
      >
        <Hidden only={['xs', 'sm']}>
          <Grid
            item
            xs={7}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item >
              <img src='/logoWhite.png' width={"70%"} alt="logo" style={{ margin: 'auto', display: 'block' }} />
            </Grid>
          </Grid>
        </Hidden>
        <Grid
          item
          md={5}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ backgroundColor: '#fff' }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item >
              <Hidden only={['md', 'lg', 'xl']}>
                <img src='/logo.svg' width={"70%"} alt="logo" style={{ margin: 'auto', display: 'block' }} />
              </Hidden>
            </Grid>
            <Grid item >
              <CardLogin history={history} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}


function CardLogin({ history }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [login, changeLogin] = useState('')
  const [password, changePassword] = useState('')
  const [isLoading, changeLoading] = useState(false)
  const [errorPswd, changeErrorPswd] = useState(false)
  const snackbar = obj => dispatch(enqueueSnackbar(obj))

  const errServer = () => {
    changeLoading(false)
    snackbar({
      message: `Server error`,
      options: { variant: 'error' }
    })
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    changeLoading(true)
    fetchGen(loginWorker.path, { login: login.trim(), password: password })
      .then(res => {
        if (res.success) {
          localStorage.setItem('token', res.token);
          history.push(API_BO + '/worker-app/main')
        }
        else {
          changeLoading(false)
          changeErrorPswd(true)
          snackbar({
            message: `Неправильный логин или пароль`,
            options: { variant: 'error' }
          })
        }
      }).catch(errServer)
  }
  return <Grid item>
    <Card className={classes.card}>
      <Typography style={{ textAlign: 'center' }} variant="h6">Добро пожаловать</Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.form}>
          <div className={classes.input}>
            <TextField
              autoFocus
              label='Логин'
              value={login}
              type="text"
              onChange={(e) => changeLogin(e.target.value)}
              disabled={isLoading}
              fullWidth
              error={errorPswd}
            />
          </div>
          <div className={classes.input}>
            <TextField
              label='Пароль'
              value={password}
              type="password"
              onChange={(e) => changePassword(e.target.value)}
              disabled={isLoading}
              fullWidth
              error={errorPswd}
            />
          </div>
        </div>
        <CardActions className={classes.actions}>
          <Grid
            container
            direction="column"
            justifyContent="space-around"
          >
            <Grid>
              <Button
                variant="contained"
                type="submit"
                style={{ backgroundColor: '#163dec', color: '#fff' }}
                disabled={isLoading}
                className={classes.button}
                fullWidth
              >
                {isLoading && (<CircularProgress size={25} thickness={2} />)}Войти
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </form>
    </Card>
  </Grid>
}