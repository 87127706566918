import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, TextField, Tooltip, TableCell, TableRow, IconButton, Select, Typography, Input, FormControl,
  FormHelperText
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ChangeIcon from '@material-ui/icons/Build'
import AutoComplite from '../../../../../components/Autocomplite'
import AutocompliteWithLoadCreate from '../../../../../components/AutocompliteWithLoadCreate'
import { formObjDelete, formArrayDelete, formObjChangeObj } from '../../../../../reducers/form';
import { addFilterAutocomplite, dellFilterAutocomplite } from '../../../../../reducers/autocomplite'
import { getMSN, addMSNfunction } from '../../../../../api/material'
import { nds } from '../../../../../enum/nds.enum'


export default function TableRowShipmentMaterial({ id, formName, idProject }) {
  const obj = useSelector(state => state.form[formName]?.values?.byId[id] || {})
  const dispatch = useDispatch()
  const deleteRow = () => {
    if (obj.value) {
      dispatch(dellFilterAutocomplite(obj.value.value, { field: 'material', id: id }))
    }
    dispatch(formArrayDelete(id, { field: 'allIds', name: formName }));
    dispatch(formObjDelete({ id: id, field: 'byId', name: formName }));
  }
  const getErrorCount = () => {
    const count = (obj.idStorageUnit !== obj.idStorageUnit2) ? obj.count * obj.coef : obj.count
    const title = obj.balance ? (obj.balance / obj.coef).toString() : '0'
    const error = obj.balance ? (obj.balance < count) : false
    return {
      error: error,
      title: title + (error ? ' Больше чем по смете' : '')
    }
  }
  const errorTitleCount = getErrorCount()
  return <TableRow>
    <TableCell style={{ width: 10 }} padding={'none'}>
      <Tooltip title="Delete">
        <IconButton aria-label="Delete" size='small'
          onClick={deleteRow}  ><DeleteIcon /></IconButton>
      </Tooltip>
    </TableCell>
    <TableCell padding='none'>
      <MaterialCell
        formName={formName}
        id={id}
        idProject={idProject}
        obj={obj}
      />
    </TableCell>
    <SimpleCell
      id={id}
      formName={formName}
      field="count"
      title={errorTitleCount.title}
      type="number"
      value={obj.count}
      error={errorTitleCount.error}
    />
    <SimpleCell
      id={id}
      formName={formName}
      field="price"
      title={obj.priceOld ? obj.priceOld * obj.coef : '0'}
      type="number"
      value={obj.price}
    />
    <StorageUnitCoffCell formName={formName} id={id} storageUnit2Value={obj.storageUnit2Value} coef={obj.coef} idMaterial={obj?.idMaterial || ''} />
    <TableCell padding='none'><Typography>{Math.round(obj.count * obj.price * 100) / 100}</Typography></TableCell>
    <SelectSwitch id={id} formName={formName} value={obj.nds} />
    <TableCell padding='none'><Typography>{Math.round(obj.count * obj.price * 100 * ((obj.nds === 'NDS20') ? 1.2 : 1)) / 100}</Typography></TableCell>
  </TableRow>
}

const useStyles = makeStyles({
  formControl2: {
    width: '100%',
    // marginTop: 5,
    // marginBottom: 3,
  },
});

function MaterialCell({ formName, id, idProject, obj }) {
  const [edit, changeEdit] = useState(obj.idMaterial === '')
  const [editName, changeEditName] = useState(false)
  const classes = useStyles();
  const materialArr = useSelector(state => state.autocomplite.material || state.autocomplite.default)
  const filterArr = materialArr.arr.filter(obj => {
    return !(typeof obj.select !== 'undefined' && obj.select !== id)
  })
  const dispatch = useDispatch()
  const changeMaterial = (text) => {
    if (obj.value) {
      dispatch(dellFilterAutocomplite(obj.value.value, { field: 'material', id: id }))
    }
    dispatch(formObjChangeObj({
      balance: text.balance,
      priceOld: text.price,
      idMaterial: text.value,
      name: text.label,
      nameNotEstimate: text.label,
      storageUnit: text.storageUnit,
      idStorageUnit2: text.idStorageUnit,
      storageUnit2Value: { value: text.idStorageUnit, label: text.storageUnit },
      value: text,
      idOrder: undefined
    }, { field: 'byId', id: id, name: formName }))
    dispatch(addFilterAutocomplite(text.value, { field: 'material', id: id }))
  };
  const changeEstimate = (e, name) => {
    dispatch(formObjChangeObj({
      idEstimate: e.value,
      name: e.label + ` (${name})`,
      estimateName: e.label,
    }, { field: 'byId', id: id, name: formName }))
    dispatch(dellFilterAutocomplite(obj.value.value, { field: 'material', id: id }))
  }
  if (edit) {
    return <AutoComplite
      arr={filterArr}
      palaceHolder=''
      action={(e) => { changeMaterial(e); changeEdit(false) }}
      value={obj.value}
      formName={formName}
      selectProps={{
        onBlur: () => {
          if (obj.idMaterial !== '') { changeEdit(false) }
        },
      }}
    />
  }
  if (editName) {
    return <Grid container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={10}>
        <AutocompliteWithLoadCreate
          classes={{ formControl: classes.formControl2 }}
          field='idEstimate'
          name=''
          path={getMSN.path({ id: idProject, idMaterial: obj.idMaterial })}
          createAPI={addMSNfunction(idProject, obj.idMaterial)}
          createBody='name'
          change={(e) => { changeEstimate(e, obj.nameNotEstimate); changeEditName(false) }}
          meta={{
            field: 'idEstimate' + obj.idMaterial,
            value: 'id',
            labelFunc: (obj) => obj.name
          }}
          value={{ value: obj.idEstimate || '', label: obj.estimateName || '' }}
          formName={formName}
          selectProps={{
            onBlur: () => changeEditName(false),
          }}
        />
      </Grid>
      <Grid item>
        <IconButton onClick={() => changeEditName(true)} size='small' >
          <ChangeIcon />
        </IconButton>
      </Grid>
    </Grid>
  }
  return (
    <div >
      <Grid container
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <Grid item onClick={() => changeEdit(true)}><Typography>{obj.name}</Typography></Grid>
        <Grid item>
          <IconButton onClick={(e) => { e.preventDefault(); changeEditName(true) }} size='small' >
            <ChangeIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
}

const useStylesSimpleCell = makeStyles({
  root: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    }
  },
  input: {
    margin: 2,
    paddingBottom: 2,
    paddingTop: 2,
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    }
  },
});

function SimpleCell({ id, field, title, type, value, formName, disabled = false, error = false }) {
  const dispatch = useDispatch()
  const classes = useStylesSimpleCell()
  const handleChange = (e) => dispatch(formObjChangeObj({ [field]: e.target.value }, { field: 'byId', id: id, name: formName }))
  return <TableCell padding='none'>
    <Tooltip title={title} placement="bottom" >
      <FormControl error={error} fullWidth>
        <Input
          classes={{ root: classes.root, input: classes.input }}
          value={value}
          onChange={handleChange}
          onFocus={e => { if (type === 'number') e.target.select() }}
          margin="dense"
          type={type}
          name={field}
          disabled={disabled}
        />
      </FormControl>
    </Tooltip>
  </TableCell >
}

function SelectSwitch({ id, value, formName }) {
  const dispatch = useDispatch()
  const handleChange = (e) => {
    dispatch(formObjChangeObj({ nds: e.target.value }, { field: 'byId', id: id, name: formName }))
  }
  return <TableCell padding='none'>
    <Select
      native
      value={value}
      onChange={handleChange}
      style={{ minWidth: 90 }}
    >
      {nds.map(el => {
        return <option key={el.value} value={el.value}>{el.label}</option>
      })}
    </Select>
  </TableCell>
}

function StorageUnitCoffCell({ formName, id, storageUnit2Value, coef, idMaterial }) {
  const dispatch = useDispatch()
  const [edit, setEdit] = useState(false)
  const [editCoff, setEditCoff] = useState(false)
  const changeStorageUnit = text => {
    dispatch(formObjChangeObj({
      idStorageUnit2: text.value,
      storageUnit2Value: text,
    }, { field: 'byId', id: id, name: formName }))
    setEditCoff(true)
  };
  useEffect(() => {
    setEditCoff(false)
  }, [idMaterial])
  const arr = useSelector(state => state.autocomplite?.storageUnit.arr || [])
  return <>
    <TableCell padding='none' style={{ minWidth: 70 }}>
      {!edit && <div onClick={() => setEdit(true)}>
        <Typography>{storageUnit2Value?.label || ''}</Typography>
      </div>}
      {edit && <>
        <AutoComplite
          arr={arr}
          action={changeStorageUnit}
          value={storageUnit2Value}
          formName={formName}
          palaceHolder=''
          selectProps={{
            onBlur: () => setEdit(false),
          }}
        />
      </>}
    </TableCell>
    <SimpleCell
      id={id}
      formName={formName}
      field="coef"
      value={coef}
      type="number"
      title=''
      disabled={!editCoff}
    />
  </>
}