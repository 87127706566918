import React, { useState, useEffect } from 'react'
import {  makeStyles } from '@material-ui/core/styles';
import PageList from '../../components/PageListAPI'
import Button from '@material-ui/core/Button'
import { Typography } from '@material-ui/core';
import {  useSelector, useDispatch } from 'react-redux'
import Autocomplite from '../../components/AutocompliteWithLoad'
import { getWorkerList, getWorkerOneWork, getWorkerReport } from '../../api/worker'
import TextField from '@material-ui/core/TextField'
import queryString from 'query-string'
import { formInitialize, formDestroy } from '../../reducers/form';
import Grid from '@material-ui/core/Grid'
import { fetchDispath } from '../../functions/fetch'
import print from '../../functions/print'
const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    marginTop: 5,
    marginBottom: 3,
    width: '100%'
  },
  autocomp: {
    minWidth: 120
  }
}));
const formName = 'WorkerOneWork'
export default function WorkerOneWork ({ match, history, location }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const { idWorker } = values
  const [reload, setReaload] = useState(false)
  const [formed, setFormed] = useState(false)
  const [summ, setSumm] = useState(0)
  const [state, setState] = useState({
    dateStart: '',
    dateEnd: '',
  })
  useEffect(() => {
    dispatch(formInitialize({
      idWorker: 0
    },{ name: formName }))
    return () => {
      dispatch(formDestroy({ name: formName }))
    }
  }, [dispatch])
  const hangleChange = (field, value) => {
    setState({ ...state, [field]: value })
  }
  const changeDate = (e) => {
    let filterDate = (e.target.value !== '') ? e.target.value : undefined
    let search = queryString.parse(history.location.search)
    search[e.target.name] = filterDate
    hangleChange(e.target.name, e.target.value)
  }
  const handlePrint = () => {
    print(
      (obj)=>dispatch(fetchDispath(obj)),
      {
        querty: {
          dateStart: state.dateStart,
          dateEnd: state.dateEnd
        },
        param: {
          id: idWorker,
        },
        request: getWorkerReport,
      }
    )
  }
  return <div className='App-paper'>
    <Button
      color='primary'
      onClick={() => { setReaload(true); setFormed(true) }}
      disabled={typeof idWorker === 'undefined'}
    >{(formed) ? 'Обновить' : 'Сформировать'}</Button>
    <Button
      color='primary'
      onClick={() => handlePrint()}
      disabled={typeof idWorker === 'undefined'}
    >Печать </Button>
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={6}>
        <Autocomplite
          classes={classes}
          field='idWorker'
          name='Работник'
          path={getWorkerList.path}
          meta={{
            field: 'worker',
            value: 'id',
            labelFunc: (obj) => obj.surname + ' ' + obj.name + ' ' + obj.patronymic
          }}
          formName={formName}
        />
      </Grid>
      <Grid item >
        <TextField
          name='dateStart'
          type="date"
          label='Дата начала'
          value={state.dateStart}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={changeDate}
        />
      </Grid>
      <Grid item >
        <TextField
          name='dateEnd'
          type="date"
          label='Дата окончания'
          value={state.dateEnd}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={changeDate}
        />
      </Grid>
    </Grid>
    {formed &&
      <div>
        <Typography>{` Сумма: ${summ}`}</Typography>
        <PageList
          name='workerOneWork'
          columns={[
            { id: 'name', name: 'Работа' },
            { id: 'count', name: 'Количество' },
            { id: 'storageUnit', name: 'Ед.изм.' },
            { id: 'price', name: 'Цена' },
            { id: 'summ', name: 'Сумма' },
          ]}
          createItem={false}
          deleteItem={false}
          csv={false}
          loadAPI={{
            querty: {
              dateStart: state.dateStart,
              dateEnd: state.dateEnd
            },
            param: {
              id: idWorker
            },
            request: getWorkerOneWork,
            transform: (res => {
              const summ1 = res.get.reduce((acc, curr) => {
                return acc + Number(curr.summ)
              }, 0)
              setSumm(summ1 + summ)
              return res
            }),
          }}
          click={false}
          notLoad={typeof idWorker === 'undefined'}
          reload={reload}
          reloadFunc={() => setReaload(false)}
          history={history}
          match={match}
          goBack={false}
          location={location}
        />
      </div>
    }
    <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
  </div>
}