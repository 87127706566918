import '../App.css';
import React, { useEffect, useState } from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Header from './header/Header'
import Login from './login/Login'
import LoginReset from './login/LoginReset'
import { SnackbarProvider } from 'notistack';
import { API_BO } from '../conf'
import rout, {routAdmin} from '../functions/rout'
import routNotLogin from '../functions/rout_not_login'
import { useSelector } from 'react-redux';
import Notifier from '../components/Notifier'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },

}));

function Root() {
  const classes = useStyles()
  const user = useSelector(state => state.user)
  const role = user.auth.role
  if (role) {
    const routers = rout()
    const routersA = routAdmin()
    return (
      <div className={classes.root}>
        <Notifier />
        {/* <Route component={Header} /> */}
        <Header/>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <ErrorBoundary>
            <Switch>
              <Route exact path={API_BO + '/login'} component={Login} />
              <Route exact path={API_BO + '/login-reset/:token'} component={LoginReset} />
              {routers.map((obj, index) => <Route exact {...obj} key={index} />)}
              {routersA.map((obj, index) => <Route exact {...obj} key={index} />)}
              <Route render={() => { return <h1>Not found</h1> }} />
            </Switch>
          </ErrorBoundary>
        </main>
      </div>
    )
  }
  else {
    const routersNot = routNotLogin()
    return (
      <div>
        <Notifier />
        <main>
          <Switch>
            {routersNot.map((obj, index) => <Route exact {...obj} key={index} />)}
            <Route render={() => (!role ? <Redirect to={API_BO + "/login"} /> : null)} />
          </Switch>
        </main>
      </div>
    )
  }
}

function IntegrationNotification() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Root />
    </SnackbarProvider>
  );
}
export default IntegrationNotification;


function ErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (hasError) {
      setHasError(false);
    }
  }, [location.key]);
  return (
    <ErrorBoundaryInner
      hasError={hasError}
      setHasError={setHasError}
    >
      {children}
    </ErrorBoundaryInner>
  );
}

class ErrorBoundaryInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error) {
    return { hasError: true };
  }

  componentDidUpdate(prevProps, _previousState) {
    if (!this.props.hasError && prevProps.hasError) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(_error, _errorInfo) {
    this.props.setHasError(true);
  }

  render() {
    return this.state.hasError
      ? <h1>There was an error</h1>
      : this.props.children;
  }
}
