import React, { useState } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import HistoryIcon from '@material-ui/icons/History';
import LoadBar from './LoadBar'
import { Hidden, AppBar, Toolbar, CssBaseline, IconButton, Grid, Button, Tooltip } from '@material-ui/core';
import Avatar from './Avatar'
import ListButton from './ListButton'
import LeftDrawer from './LeftDrawer';
import { useDispatch } from 'react-redux';
import { openHistory } from '../../reducers/history';
import HistoryDrawer from './HistoryDrawer'
import { Link, useRouteMatch } from 'react-router-dom';
import { API_BO } from '../../conf'
import ProjectDescription from './ProjectDescription'
import ProjectPath from './ProjectPath'

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBar2: {
    zIndex: theme.zIndex.drawer + 2,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#AA0000'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  profileButton: {
    position: 'relative',
    marginRight: '10%',
    marginLeft: 'inherit',
    color: "inherit"
    ,
  },
  hide: {
    display: 'none',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1
  },
  butt: {
    display: 'flex',
  },
  buttonAccount: {
    textTransform: 'capitalize',
    '&:hover': {
      color: '#000'
    }
  },
}));
const truncateString = (s, w) => s.length > w ? s.slice(0, w).trim() + "..." : s;
export default function Header() {
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  return (
    <div className={classes.root}>
      <ProjectDescription />
      <CssBaseline />
      <AppBar
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar disableGutters={!open} style={{ backgroundColor: '#163dec' }}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={() => setOpen(true)}
            size='small'
            className={classNames(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Grid
            container
            direction='column'
            justifyContent="flex-start"
            alignItems="stretch"
          >
            <Hidden xsDown>
              <Grid
                item
                container
                direction="row"
                alignItems="stretch"
              >
                <Grid item xs={8} direction="row" container><ListButton /></Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  xs={4}
                >
                  <Grid item><HistoryButton classes={classes} /></Grid>
                  <Grid item><Avatar /></Grid>
                  <Grid item >
                    <Tooltip title="Изменить аккаунт">
                      <Button
                        color='inherit'
                        size='small'
                        component={React.forwardRef((props, ref) => (<Link ref={ref} to={API_BO + '/accounts'} {...props} />))}
                        className={classes.buttonAccount}
                      >
                        {truncateString(localStorage.getItem('accountName') || 'Аккаунт', 15)}
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
            <Grid item>
              <ProjectPath />
            </Grid>
          </Grid>
        </Toolbar>
        <div><LoadBar /></div>
      </AppBar>
      <LeftDrawer
        open={open}
        handleDrawerClose={() => setOpen(!open)}
      />
    </div>
  );
}

function HistoryButton({ classes }) {
  const dispatch = useDispatch()
  let match = useRouteMatch("/projects/:idProject");
  return <><IconButton
    className={classes.buttonAccount}
    color='inherit' size='small'
    onClick={() => { dispatch(openHistory(true)) }}
    disabled={!match}
  >
    <HistoryIcon />
  </IconButton>
    {!!match && match.params?.idProject && <HistoryDrawer idProject={match.params?.idProject} />}
  </>

}