import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, Typography,  MenuItem, TextField, InputAdornment, Select } from '@material-ui/core'
import arrAirDuct from './arrAirDuct'

const drawerWidth = 300
const useStyles = makeStyles({
  drawer: {
    width: drawerWidth
  }
});

function DriverHelper({ open, handleClose }) {
  const classes = useStyles();
  const [selected, setSelected] = useState(0)
  const selectArr = ['воздуховоды и фасонные изделия', 'sds']
  const componentArr = [<AirDuct />, null]
  return (<Drawer anchor='right' open={open} onClose={handleClose} className={classes.drawer}>
    <div className='App-paper' >
      <Select
        value={selected}
        onChange={(e) => setSelected(e.target.value)}
      >
        {selectArr.map((value, key) => <MenuItem key={key} value={key}>{value}</MenuItem>)}
      </Select>
      {componentArr[selected]}
    </div>
  </Drawer>)
}
export default DriverHelper;

function AirDuct() {
  const [selected, setSelected] = useState(0)
  return (
    <div style={{ width: drawerWidth, overflow: 'hidden' }}>
      <Select
        value={selected}
        onChange={(e) => setSelected(e.target.value)}
      >
        {arrAirDuct.map((value, key) => <MenuItem key={key} value={key}>{value.name}</MenuItem>)}
      </Select>
      <AirDuctOne key={selected} {...arrAirDuct[selected]} />
    </div>
  )
}

function AirDuctOne({ params, func, img, name }) {
  let s = {}
  params.forEach(({ id }) => {
    s[id] = ''
  });
  const [variables, setVariables] = useState(s)
  return <div>
    {params.map(({ id, name, unit }) => {
      return <TextField
        key={id}
        fullWidth
        label={name}
        margin="dense"
        type="number"
        value={variables[id]}
        onChange={(e) => {
          const value = e.target.value
          setVariables(prevState => ({ ...prevState, [id]: value }))
        }
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {unit}
            </InputAdornment>
          ),
        }}
      />
    })}
    <Typography>{func(variables)} м<sup>2</sup></Typography>
    <img src={`/air/${img}`} alt={name} />
  </div>
}