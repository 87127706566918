import React, { useState, useCallback } from 'react'
import {
  Button, Popover, IconButton, Tooltip,
  Dialog, DialogContent, DialogTitle, DialogActions,
  List, ListItem, ListItemText, ListItemIcon, Checkbox,
  ClickAwayListener, MenuList, MenuItem,
  DialogContentText
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import PrintIcon from '@material-ui/icons/Print';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import LinkIcon from '@material-ui/icons/Link';
import ZoomOutOutlinedIcon from '@material-ui/icons/ZoomOutOutlined';
import ZoomInOutlinedIcon from '@material-ui/icons/ZoomInOutlined';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import GantDialogParent from './GantDialogParent'
import GantAddArrow from './GantAddArrow'
import GantHideColumn from './GantHideColumn'
import CreateShareLink from '../../components/CreateShareLink'
import { formInitialize } from '../../reducers/form';
import { fetchDispath } from '../../functions/fetch'
import print from '../../functions/print'
import { loadAllToGant, getProjectGantPrint, deleteGantMany, setGantDatePlan } from '../../api/form-gant.api'
import { formChange, formArrayDeleteMany } from '../../reducers/form';
import { loadCritPathGant } from '../../reducers/gant'
import { formViewProps } from './gant.const'
import { enqueueSnackbar } from '../../reducers/notifier'
import moment from 'moment'

export default function GantHeader({
  zoom, setZoom, setPercent, history,
  fixTable, setFixTable,
  formName, handleAdd,
  setLoad, idProject,
  formEstimateGroup
}) {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const multiDisabled = useSelector(state => state.form[formName]?.values?.selected?.length === 0)
  var ContainerElements = ["svg", "g"];
  var RelevantStyles = {
    "rect": ["fill", "stroke", "stroke-width", "visibility"],
    "path": ["fill", "stroke", "stroke-width", "visibility"],
    "circle": ["fill", "stroke", "stroke-width", "visibility"],
    "line": ["stroke", "stroke-width", "visibility"],
    "text": ["fill", "font-size", "text-anchor"],
    "polygon": ["stroke", "fill", "visibility"]
  };
  function read_Element(ParentNode, OrigData) {
    var Children = ParentNode.childNodes;
    var OrigChildDat = OrigData.childNodes;

    for (var cd = 0; cd < Children.length; cd++) {
      var Child = Children[cd];

      var TagName = Child.tagName;
      if (ContainerElements.indexOf(TagName) !== -1) {
        read_Element(Child, OrigChildDat[cd])
      } else if (TagName in RelevantStyles) {
        var StyleDef = window.getComputedStyle(OrigChildDat[cd]);

        var StyleString = "";
        for (var st = 0; st < RelevantStyles[TagName].length; st++) {
          StyleString += RelevantStyles[TagName][st] + ":" + StyleDef.getPropertyValue(RelevantStyles[TagName][st]) + "; ";
        }
        Child.setAttribute("style", StyleString);
      }
    }
  }
  const printP = () => {
    const svg = document.getElementById('svg-full')
    var oDOM = svg.cloneNode(true)
    read_Element(oDOM, svg)
    const svgString = new XMLSerializer().serializeToString(oDOM);
    const file = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
      window.navigator.msSaveOrOpenBlob(file, 'gant.svg');
    else {
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = 'gant.svg';
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }
  const fetch = useCallback((obj) => { return dispatch(fetchDispath(obj)) }, [dispatch])
  const handlePrint = () => {
    print(
      fetch,
      {
        param: {
          id: idProject
        },
        request: getProjectGantPrint,
      }
    )
  }
  const handleSetPercent = (add) => {
    let gantProps = { percent: 130 }
    try {
      gantProps = JSON.parse(localStorage.getItem('gantProps'))
    } catch (error) { }
    gantProps.percent += add * 5
    localStorage.setItem('gantProps', JSON.stringify(gantProps))
    setPercent(old => old + add * 5)
  }

  const handleFixTable = () => {
    let gantProps = { fixTable: true }
    try {
      gantProps = JSON.parse(localStorage.getItem('gantProps'))
    } catch (error) { }
    gantProps.fixTable = !gantProps.fixTable
    localStorage.setItem('gantProps', JSON.stringify(gantProps))
    setFixTable(e => !e)
  }
  /*
   <ButtonSimple title="" ariaLabel=""  Icon={} handleClick={}/>
   */
  return <React.Fragment>
    <div >
      <div>
        <ButtonSimple title="Назад" ariaLabel="back" Icon={ArrowBackIcon} handleClick={() => history.goBack()} />
        <ButtonDivider />
        <ButtonSimple title="Уменьшить размер" ariaLabel="down size" Icon={ZoomOutOutlinedIcon} handleClick={() => handleSetPercent(1)} />
        <ButtonSimple title="Увеличить размер" ariaLabel="up size" Icon={ZoomInOutlinedIcon} handleClick={() => handleSetPercent(-1)} />
        <ButtonZoom zoom={zoom} changeZoom={setZoom} />
        <ButtonSimple title="Фиксировать таблицу" ariaLabel="fix table" Icon={AttachFileIcon} handleClick={() => handleFixTable()} />
        <ButtonHideVisiblePlan />
        <ButtonDivider />
        <ButtonSimple title="Открыть доступ по ссылке" ariaLabel="open access" Icon={LinkIcon} handleClick={e => setAnchorEl(e.currentTarget)} />
        <ButtonSimple title="Печать" ariaLabel="print" Icon={PrintIcon} handleClick={handlePrint} />
        <ButtonSimpleText title="Сохранить как SVG" ariaLabel="save as svg" text='SVG' handleClick={printP} />
        <ButtonDivider />
        <ButtonSimple title="Добавить" ariaLabel="add" Icon={AddIcon} handleClick={handleAdd} />
        <GantAddArrow formName={formName} idProject={idProject} />
        <GantDialogParent formName={formName} idProject={idProject} reload={() => setLoad(true)} disabled={multiDisabled} />
        <ButtonDivider />
        <ButtonDeleteMany formName={formName} idProject={idProject} disabled={multiDisabled} reload={() => setLoad(true)} />
        <ButtonDivider />
        <ButtonAutoFill idProject={idProject} reload={() => setLoad(true)} formEstimateGroup={formEstimateGroup} />
        <ButtonCreateOrder formName={formName} history={history} idProject={idProject} disabled={multiDisabled} />
        <GantHideColumn />
        <ButtonCriticalPath formName={formName} />
      </div>
    </div>
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={() => setAnchorEl(null)}
    >
      <CreateShareLink idProject={idProject} type={'gant'} />
    </Popover>
  </React.Fragment >
}

const arrZoom = [
  { value: 'day', label: 'День' },
  { value: 'week', label: 'Неделя' },
  { value: 'month', label: 'Месяц' },
]
function ButtonZoom({ zoom, changeZoom }) {
  const handleSetZoom = (newZoom) => {
    let gantProps = { zoom: 'day' }
    try {
      gantProps = JSON.parse(localStorage.getItem('gantProps'))
    } catch (error) { }
    gantProps.zoom = newZoom
    localStorage.setItem('gantProps', JSON.stringify(gantProps))
    changeZoom(newZoom)
  }
  return <>
    {arrZoom.map(e => <Button
      key={e.value}
      color={zoom === e.value ? 'primary' : 'secondary'}
      onClick={() => handleSetZoom(e.value)}
    >{e.label}
    </Button>)}
  </>

}

function ButtonSimpleText({ title, ariaLabel, handleClick, text }) {
  return <Tooltip title={title} aria-label={ariaLabel}>
    <Button color='primary' onClick={handleClick}>{text}</Button>
  </Tooltip>
}

function ButtonSimple({ title, ariaLabel, handleClick, Icon }) {
  return <Tooltip title={title} aria-label={ariaLabel}>
    <IconButton color='primary' onClick={handleClick}><Icon /></IconButton>
  </Tooltip>
}

function ButtonHideVisiblePlan() {
  const dispatch = useDispatch()
  const planVisible = useSelector(state => state.form[formViewProps]?.values.planVisible)
  return <Tooltip title="Скрыть план" aria-label="add"><Button
    color={planVisible ? 'primary' : 'secondary'}
    onClick={() => {
      dispatch(formChange(!planVisible, { field: 'planVisible', name: formViewProps }))
    }}
  >План</Button></Tooltip>
}

function ButtonCreateOrder({ formName, history, idProject, disabled }) {
  const selectedId = useSelector(state => state.form[formName]?.values?.selected || [])
  const byId = useSelector(state => state.form[formName]?.values.byId || {})
  const dispatch = useDispatch()
  const handleCreateOrder = () => {
    const selected = []
    const selectedCount = []
    selectedId.forEach(id => {
      const key = 'id' + id
      if (byId[key]?.idMaterial) {
        selected.push(byId[key].idMaterial)
        selectedCount.push({ id: byId[key].idMaterial, count: byId[key].count })
      }
    })
    if (selected.length > 0) {
      dispatch(formInitialize({ arr: selected, arrCount: selectedCount }, { name: 'materialSelected' }))
      history.push(`/projects/${idProject}/order/provider/add`)
    }
  }
  return <Button color='primary' onClick={handleCreateOrder} disabled={disabled}>Создать заявку</Button>

}

function ButtonDeleteMany({ formName, disabled, reload, idProject }) {
  const selected = useSelector(state => state.form[formName]?.values?.selected || [])
  const dispatch = useDispatch()
  const handleDeleteMany = () => {
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      body: {
        selected: selected
      },
      request: deleteGantMany,
    })).then(res => {
      reload()
      dispatch(formArrayDeleteMany(selected, { name: formName, field: 'selected' }))
    }).catch(err => console.log(err))
  }
  return <Tooltip title="Удалить" aria-label="add">
    <span>
      <IconButton color='primary' onClick={handleDeleteMany} disabled={disabled}><DeleteIcon /></IconButton>
    </span>
  </Tooltip>
}

function ButtonDivider() {
  const style = {
    borderLeft: '2px solid #9391a22e',
    height: '32px',
    verticalAlign: 'middle',
    display: 'inline-block',
  }
  return <div style={style} />
}


function ButtonCriticalPath({ formName }) {
  const byId = useSelector(state => state.form[formName]?.values.byId || {})
  const arrow = useSelector(state => state.gant.arrow)
  const dispatch = useDispatch()
  const handleCriticalPath = () => {
    let byArrow = {}
    const allArrow = []
    const arrowVertex = [[]]
    arrow.forEach(e => {
      const { fromId, to, id, type } = e
      if (arrowVertex[fromId]) { arrowVertex[fromId].push(to) } else arrowVertex[fromId] = [to]
      const objTo = byId['id' + to]
      const objFrom = byId['id' + fromId]
      const parentObjArrow = { id, fromId, type, name: byId['id' + fromId].name, duration: durationArrow(objFrom, objTo, type) }
      const childObjArrow = { id, to, type, name: byId['id' + to].name, duration: durationArrow(objFrom, objTo, type) }
      if (byArrow[to]) { byArrow[to].parent.push(parentObjArrow) }
      else {
        byArrow[to] = {
          id: to,
          start: moment(objTo.start),
          end: moment(objTo.end),
          duration: diffDate(objTo.end, objTo.start) + 1,
          parent: [parentObjArrow],
          child: [],
          es: 0,
          ef: 0,
          ls: 0,
          lf: 0,
          tf: -1,
          name: objTo.name,
        }
        allArrow.push(to)
      }
      if (byArrow[fromId]) { byArrow[fromId].child.push(childObjArrow) }
      else {
        byArrow[fromId] = {
          id: fromId,
          start: moment(objFrom.start),
          end: moment(objFrom.end),
          duration: diffDate(objFrom.end, objFrom.start) + 1,
          parent: [],
          child: [childObjArrow],
          es: 0,
          ef: 0,
          ls: 0,
          lf: 0,
          tf: -1,
          name: objFrom.name,
        }
        allArrow.push(fromId)
      }
    })
    const momentsStart = allArrow.map(key => byArrow[key].start)
    const minMoment = moment.min(momentsStart)
    const arrEnds = []
    let EFMax = 0
    allArrow.forEach(key => {
      byArrow[key].es = diffDate(byArrow[key].start, minMoment)
      byArrow[key].ef = diffDate(byArrow[key].end, minMoment) + 1
      if (byArrow[key].child.length === 0) {
        arrEnds.push(key)
        if (byArrow[key].ef > EFMax) EFMax = byArrow[key].ef
      }
      if (byArrow[key].parent.length === 0) {
        arrowVertex[0].push(key)
      }
    })
    const parentChild = new Set()
    arrEnds.forEach(key => {
      byArrow[key].lf = EFMax
      byArrow[key].ls = EFMax - byArrow[key].duration
      byArrow[key].parent.forEach(e => parentChild.add(e.fromId))
      byArrow[key].tf = byArrow[key].lf - byArrow[key].ef
    })
    if (arrowVertex[0].length === 0) {
      dispatch(enqueueSnackbar({
        message: `Диаграмма имеет циклы`,
        options: { variant: 'error' }
      }))
      return
    }
    const cycle = cycleExist(arrowVertex)
    if (cycle) {
      dispatch(enqueueSnackbar({
        message: `Диаграмма имеет циклы`,
        options: { variant: 'error' }
      }))
      return
    }
    function recLS_LF(arr) {
      const recArr = new Set()
      arr.forEach(key => {
        const obj = byArrow[key]
        let err = false
        const arrLF = obj.child.map(arrow => {
          const to = byArrow[arrow.to]
          if (to.lf === 0) {
            err = true
            return 0
          }
          return calcLF(obj.duration, to, arrow.type, arrow.duration)
        })
        if (err) { recArr.add(key) }
        else if (byArrow[key].lf === 0) {
          byArrow[key].lf = Math.min(...arrLF)
          byArrow[key].ls = byArrow[key].lf - byArrow[key].duration
          byArrow[key].parent.forEach(e => {
            const objFrom = byArrow[e.fromId]
            if (objFrom.lf === 0) {
              recArr.add(e.fromId)
            }
          })
          byArrow[key].tf = byArrow[key].lf - byArrow[key].ef
        }
      })
      if (recArr.size > 0) { recLS_LF(recArr) }
    }
    try {
      recLS_LF(parentChild)
    } catch (error) {
      dispatch(enqueueSnackbar({
        message: `Ошибка в диаграмме, проверьте на циклы`,
        options: { variant: 'error' }
      }))
      return
    }
    const arrTFZero = []
    const point = new Set([0])
    let startPointExist = false
    const TFZeroId = []
    allArrow.forEach(key => {
      const obj = byArrow[key]
      if (obj.tf === 0) {
        TFZeroId.push(key)
        if (obj.es === 0) { arrTFZero.push({ s: 0, e: key, d: 0, id: 0 }); point.add(key); startPointExist = true }
        obj.child.forEach(ch => {
          if (byArrow[ch.to].tf === 0) { arrTFZero.push({ s: key, e: ch.to, d: -1, id: ch.id }); point.add(ch.to) }
        })
      }
    })
    if (!startPointExist && TFZeroId.length > 0) {
      let minTF = byArrow[TFZeroId[0]].es
      TFZeroId.forEach(key => {
        const obj = byArrow[key]
        if (minTF > obj.es) minTF = obj.es
      })
      TFZeroId.forEach(key => {
        const obj = byArrow[key]
        if (minTF === obj.es) {
          arrTFZero.push({ s: 0, e: key, d: 0, id: 0 }); point.add(key)
        }
      })
    }
    if (!startPointExist) {
      dispatch(enqueueSnackbar({
        message: `Начало работ не совпадает с критическим путем`,
        options: { variant: 'warning' }
      }))
    }
    const F = algB(arrTFZero, point)
    dispatch(loadCritPathGant({ path: F.path.map(i => 'id' + i), arrow: F.arrow.map(i => i) }))
  }
  return <Button color='primary' onClick={handleCriticalPath} >Критический путь </Button>
}
function diffDate(end, start) {
  return end.diff(start, 'days')
}

function durationArrow(from, to, type) {
  switch (type) {
    case 'fs':
      return diffDate(to.start, from.end) - 1
    case 'ss':
      return diffDate(to.start, from.start) - 1
    case 'ff':
      return diffDate(to.end, from.end) - 1
    case 'sf':
      return diffDate(to.end, from.start) - 1
    default:
      return 0
  }
}

function calcLF(fromDuration, to, type, durationArrow) {
  switch (type) {
    case 'fs':
      return to.ls - durationArrow
    case 'ss':
      return to.ls - durationArrow + fromDuration
    case 'ff':
      return to.lf - durationArrow
    case 'sf':
      return to.lf - durationArrow + fromDuration
    default:
      return 0
  }
}
function cycleExist(graph) {
  const color = []
  let circle = false
  graph.forEach((e, i) => {
    color[i] = 'w'
    e.forEach(j => color[j] = 'w')
  })
  function dfs(v) {
    color[v] = 'g';
    graph[v]?.forEach(u => {
      if (color[u] === 'w') dfs(u);
      if (color[u] === 'g') {
        circle = true
        return;
      }
    })
    color[v] = 'b'
  }
  dfs(0)
  return circle
}

function algB(arr, point) {
  const INF = 1e9;
  const F = [];
  point.forEach(key => F[key] = INF)
  const pref = []
  F[0] = 0;
  for (let k = 1; k < point.size; k++)
    for (let j = 0; j < arr.length; j++) {
      const el = arr[j];
      const start = el.s;
      const finish = el.e;
      const weight = el.d;
      const id = el.id
      if (F[start] + weight < F[finish]) {
        F[finish] = F[start] + weight;
        pref[finish] = { start, id };
      }
    }
  let min = 0
  let indexMin = 0
  point.forEach(key => {
    if (F[key] < min) {
      min = F[key]
      indexMin = key
    }
  })
  const path = [indexMin]
  const arrowPath = []
  function pathCalc(index) {
    const value = pref[index]
    if (value.start !== 0) {
      path.push(value.start)
      arrowPath.push(value.id)
      pathCalc(value.start)
    }
  }
  pathCalc(indexMin)
  return { path, arrow: arrowPath }
}


// авто заполнение
function ButtonAutoFill({ idProject, reload, formEstimateGroup }) {
  const groupArr = useSelector(state => state.autocomplite[formEstimateGroup]?.arr || [])
  const [selected, setSelected] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [autoOpen, setAutoOpen] = useState(false)
  const anchorRef = React.useRef(null);
  const dispatch = useDispatch()
  const onLoadFromEstimate = () => {
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      body: {
        groups: selected
      },
      request: loadAllToGant,
    })).then(() => {
      reload()
      setOpenDialog(false)
      setSelected([])
    })
  }
  const handleSetDatePlan = () => {
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      body: {},
      request: setGantDatePlan,
    })).then(() => {
      reload()
    })
  }
  const handleAutoClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setAutoOpen(false);
  };
  const handleSelectGroup = (key, select) => {
    if (select) {
      setSelected(old => old.filter(e => key !== e))
    }
    else setSelected(old => ([...old, key]))
  }
  return <>
    <Button color='primary' onClick={() => { setAutoOpen((prevOpen) => !prevOpen); }} ref={anchorRef}>Автозаполнение</Button>
    <Popover
      open={autoOpen}
      anchorEl={anchorRef.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={handleAutoClose}
    >
      <div className='App-paper'>
        <ClickAwayListener onClickAway={handleAutoClose}>
          <MenuList autoFocusItem={autoOpen} id="menu-list-grow" >
            <MenuItem onClick={(e) => { handleAutoClose(e); setOpenDialog(true) }}>Заполнить из сметы</MenuItem>
            <MenuItem onClick={(e) => { handleAutoClose(e); handleSetDatePlan() }}>Сформировать базовый график на основе текущего</MenuItem>
          </MenuList>
        </ClickAwayListener>
      </div>
    </Popover>
    <Dialog
      open={openDialog}
      maxWidth='sm'
      keepMounted
      fullWidth
      scroll="paper"
      onClose={() => (setOpenDialog(false))}
      PaperProps={{ style: { height: 400 } }}
    >
      <DialogTitle>Выбрать группу</DialogTitle>
      <DialogContent>
        <DialogContentText>Выберите группу для загрузки в диаграмму </DialogContentText>
        <Button color="primary" onClick={() => setSelected(groupArr.map(e => e.value))}>Выделить все </Button>
        <List aria-label="main mailbox folders">
          {groupArr.map(obj => {
            const select = selected.includes(obj.value)
            return <ListItem dense button key={obj.value} onClick={() => handleSelectGroup(obj.value, select)}>
              <ListItemIcon><Checkbox edge="start" disableRipple checked={select} tabIndex={-1} /></ListItemIcon>
              <ListItemText primary={obj.label} />
            </ListItem>
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)} color="primary">
          Отмена
        </Button>
        <Button onClick={onLoadFromEstimate} color="primary" disabled={selected.length === 0}>
          Добавить
        </Button>
      </DialogActions>
    </Dialog>
  </>
}