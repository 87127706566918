import React from 'react'
import { useSelector } from 'react-redux'
import { TableRow, Table, TableHead, TableBody, TableCell, TableContainer } from '@material-ui/core'

export default function TablePlanFactWorker({ formName, field, columns, group }) {
  const tablePlan = useSelector(state => state.formTable[formName] || state.formTable.default)
  const tableField = useSelector(state => state.formTable[field] || state.formTable.default)
  const { allIds = [], byId = {} } = tablePlan
  let byIdField = {}
  if (!group) {
    tableField.allIds.forEach(key => {
      const obj = tableField.byId[key]
      if (!byIdField[obj.id]) {
        byIdField[obj.id] = { ...obj, idPlan: -1 }
        return;
      }
      if (byIdField[obj.id].fact !== 'работал') {
        byIdField[obj.id].fact = obj.fact
      }
    });
  }
  const planArr = group ? allIds.slice(1) : allIds.length > 0 ? [allIds[0]] : []
  return <TableContainer style={{ height: '70vh' }}>
    <Table size='small' stickyHeader  >
      <TableHead>
        <TableRow>
          {columns.map(obj => {
            return <TableCell key={obj.id} >{obj.name}</TableCell>
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {planArr.map(key => {
          const obj = byId[key]
          const arrField = tableField.allIds.filter(id => {
            if (!group) {
              return true
            }
            return Number(tableField.byId[id].idPlan) === obj.id
          })
          return <React.Fragment key={key}>
            <TableRow>
              <TableCell colSpan={columns.length}><b>{obj.shortDescription}{!obj.idFact ? ' (факт не заполнен)' : ''}</b></TableCell>
            </TableRow>
            {!group && Object.values(byIdField).map(objField => {
              return <TableRow key={objField.id}>
                {columns.map(obj => {
                  return <TableCell key={obj.id}>{obj.type ? obj.component({ obj: objField }) : objField[obj.id]}</TableCell>
                })}
              </TableRow>
            })}
            {group && arrField.map(keyField => {
              const objField = tableField.byId[keyField]
              return <TableRow key={keyField}>
                {columns.map(obj => {
                  return <TableCell key={obj.id}>{obj.type ? obj.component({ obj: objField }) : objField[obj.id]}</TableCell>
                })}
              </TableRow>
            })}
          </React.Fragment>
        })}
      </TableBody>
    </Table>
  </TableContainer>
}
