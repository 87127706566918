import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../reducers/header'
import { loadProjectById } from '../../api/projects.js'
import { fetchDispath } from '../../functions/fetch'
import { useRouteMatch } from "react-router-dom";

export default function ProjectDescription() {
  const dispatch = useDispatch()
  const projectId = useSelector(state => state.header?.projectId || 0)
  let match = useRouteMatch("/projects/:newID");
  const newID = match ? Number(match.params?.newID) : 0
  useEffect(() => {
    if (projectId !== 0) return;
    if (newID === 0) return;
    if (projectId !== newID) {
      dispatch(fetchDispath({
        param: {
          id: newID
        },
        progress: true,
        request: loadProjectById,
      }))
        .then(res => {
          dispatch(actions.headerSetProject({ id: res.get.id, name: res.get.name }))
          dispatch(actions.headerSetObject({ id: res.get.facilityId, name: res.get.facility }))
        })
    }
  }, [projectId, newID, dispatch])
  return <div></div>
}