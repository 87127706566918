import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import 'moment/locale/ru';
import { formViewProps, formName, } from '../gant.const'

export default function GridGantt({ heightSVG, zoom, sizeDay, sizeWeek, sizeMonth, yPadding, heightDateLine }) {
  const start = useSelector(formSelector(formName, 'start')) || moment()
  const end = useSelector(formSelector(formName, 'end')) || moment()
  const [countDay, setCountDay] = useState(10)
  const [daysToWeekend, setDaysToWeekend] = useState(0)
  const startDiag = useSelector(formSelector(formViewProps, 'startDiag')) || 1810
  useEffect(() => {
    const countDay = end.diff(start, 'days') + 10
    setCountDay(countDay)
    const startWeekend = moment(start)
    startWeekend.day(7)
    setDaysToWeekend((startWeekend.diff(start, 'days')) % 7 - 1)
  }, [start, end])
  let gridComponent = null
  switch (zoom) {
    case 'day': {
      gridComponent = <GridTableZoomDay
        heightSVG={heightSVG}
        countDay={countDay}
        daysToWeekend={daysToWeekend}
        size={sizeDay}
      />
      break;
    }
    case 'week': {
      gridComponent = <GridTableZoomWeek
        heightSVG={heightSVG}
        countDay={countDay}
        size={sizeWeek}
      />
      break;
    }
    case 'month': {
      gridComponent = <GridTableZoomMonth
        heightSVG={heightSVG}
        countDay={countDay}
        start={start}
        end={end}
        size={sizeMonth}
        yPadding={yPadding}
      />
      break;
    }
    default: {
      gridComponent = null;
      break;
    }
  }
  return <g id="grid-gant" transform={`translate(${startDiag},${heightDateLine})`}>
    {gridComponent}
  </g>
}
function GridTableZoomMonth({ heightSVG, countDay, start, end, size, yPadding }) {
  let offset = 0
  const sizeD = size * 12 / 365
  const [yearArr, setYearArr] = useState([])
  useEffect(() => {
    const countDay = end.diff(start, 'days') + 10
    let date = moment(start)
    const arr1 = new Array(12)
    arr1[date.month()] = 1
    const res2 = {
      [date.year()]: arr1
    }
    for (let i = 0; i < countDay; i++) {
      date.add(1, 'd')
      const year = date.year()
      const month = date.month()
      if (res2[year]) {
        if (res2[year][month]) {
          res2[year][month] += 1
        }
        else {
          res2[year][month] = 1
        }
      } else {
        const arr1 = new Array(12)
        arr1[month] = 1
        res2[year] = arr1
      }
    }
    setYearArr(Object.keys(res2).map(year => {
      return res2[year].filter(Number)
    }))
  }, [start, end])
  return <g id="gridMonth">
    {yearArr.map(year => {
      const sizeYear = year.reduce((a, b) => (a + b))
      offset += sizeD * sizeYear
      let offsetMonth = 0
      return <g key={year} transform={`translate(${offset - sizeD * sizeYear})`}>
        {year.map((m, i) => {
          const sizeMonth = m * sizeD
          offsetMonth += sizeMonth
          return <React.Fragment key={i}>
            <line
              key={i}
              x1={offsetMonth - sizeMonth}
              y1={0}
              x2={offsetMonth - sizeMonth}
              y2={heightSVG}
              stroke="#e0e0e0"
            />
          </React.Fragment>
        })}
      </g>
    })}
    <rect
      x={0}
      y={0}
      fill="url(#patternGridYear)"
      fillOpacity={1}
      width={sizeD * countDay}
      height={heightSVG}
      transform={`translate(0,${- yPadding / 2})`}
      stroke='none'
    />
  </g>
}
function GridTableZoomWeek({ heightSVG, countDay, size }) {
  return <rect
    x={0}
    y={0}
    fill="url(#patternGridWeek)"
    fillOpacity={0.1}
    width={size * countDay / 7}
    height={heightSVG}
    transform={`translate(${-size / 14},${-size / 14})`}
    stroke='none'
  />
}
function GridTableZoomDay({ heightSVG, countDay, daysToWeekend, size }) {
  return <g>
    <rect
      x={0}
      y={0}
      fill="url(#patternGridDay)"
      fillOpacity={0.5}
      width={size * countDay}
      height={heightSVG}
      transform={`translate(${-size / 2},${-size / 2})`}
      stroke='none'
    />
    <rect
      x={0}
      y={0}
      fill="url(#patternGridWeekend)"
      fillOpacity={0.1}
      width={size * countDay}
      height={heightSVG}
      transform={`translate(${size * daysToWeekend},0)`}
      stroke='none'
    />
  </g>
}
function formSelector(formName, field) {
  return state => state.form?.[formName]?.values[field]
}