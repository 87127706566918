import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, Checkbox, FormControlLabel, Button, Grid, Tooltip, CircularProgress, List,
  ListItem, ListItemText, IconButton, ListItemSecondaryAction
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Clear'
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../reducers/materialLoad';
import * as actions2 from '../../../reducers/form';
import { fetchDispath } from '../../../functions/fetch'
import SimpleAdd from '../../../components/SimpleAdd'
import { addFileParse, getFileParse, deleteFileParse } from '../../../api/list'
import moment from 'moment'
import 'moment/locale/ru';
const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  input: {
    display: 'none',
  },
  edt: {
    padding: '1px', /* Поля вокруг текста */
  },
  main: {
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: 240,
    marginTop: 5,
    marginBottom: 3,
    width: '50%'
  },
}));

export default function SelectFile({ formName }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [loadFileP, setLoadFileP] = useState(false)
  const [selectedFile, setSelectedFile] = useState('')
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const arrProps = [
    { field: 'start', type: 'text', label: 'Начало', props: { autoComplete: 'off', type: 'number' } },
    { field: 'end', type: 'text', label: 'Окончание', props: { autoComplete: 'off', type: 'number' } },
    {
      field: 'consist', type: 'autoArr', label: 'Если есть',
      validator: [value => !!value], errorText: 'Поле обязательно',
      textProps: { arr: [{ value: 'change', label: 'Изменить' }, { value: 'add', label: 'Добавить' }], defaultValue: 'add', },
    },
    {
      field: 'listName', type: 'autoArr', label: 'Лист',
      validator: [value => !!value], errorText: 'Поле обязательно',
      textProps: { arr: values.listArr },
    }
  ]
  const loadFilePrice = useCallback(() => {
    return dispatch(fetchDispath({
      progress: true,
      request: getFileParse,
    }))
      .then(res => {
        let filePByIds = {}
        const filePAllIds = res.get.map(e => {
          let id = 'id' + e.id
          filePByIds[id] = e;
          return id
        })
        dispatch(actions2.formChangeAsObj({ filePByIds, filePAllIds }, { name: formName }))
      }).catch(err => console.log(err))
  }, [dispatch, formName])

  useEffect(() => {
    if (loadFileP) return;
    loadFilePrice()
    setLoadFileP(true)
  }, [loadFilePrice, loadFileP])

  const _handleImageChange = (e) => {
    e.preventDefault();
    if (typeof e.target.files[0] === 'undefined' || e.target.files[0].length === 0) return
    setLoading(true)
    const fileName = e.target.files[0].name
    dispatch(actions2.formChangeAsObj({ file: fileName, fileShort: fileNameShorter(fileName) }, { name: formName }))
    dispatch(actions.loadFileAPI({ file: e.target.files[0], path: `/files/add` }))
      .then(res => {
        if (res.success) {
          const fileId = res.id
          dispatch(fetchDispath({
            querty: {
              id: fileId,
            },
            progress: true,
            request: { path: '/materials/parse/info', type: 'GET' },
          }))
            .then(res => {
              const listArr = res.get.map(e => ({ value: e, label: e }))
              dispatch(actions2.formChange(listArr, { field: 'listArr', name: formName }))
              setSelectedFile(fileId + '')
              dispatch(fetchDispath({
                body: {
                  idFile: fileId,
                  name: fileName,
                  lists: res.get
                },
                progress: true,
                request: addFileParse,
              }))
                .then(res => {
                  loadFilePrice()
                }).catch(err => console.log(err))
            }).catch(err => console.log(err))
          dispatch(actions2.formChange(fileId, { field: 'fileId', name: formName }))
        }
        setLoading(false)
      })
      .catch()
  }

  return (<div>
    <Grid container
      direction='row'
      justifyContent="flex-start"
      alignItems="flex-start">
      <Grid item xs={6}>
        <Typography variant='h6'>Выбор файла</Typography>
        <div className={classes.formControl}>
          <input
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={(e) => _handleImageChange(e)}
          />
          <label htmlFor="contained-button-file">
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            // spacing={16}
            >
              <Grid item >
                <Button variant='contained' component="span" size="small">Файл</Button>
              </Grid>
              <Grid item >
                <Tooltip title={values.file || ''}><Typography>{values.fileShort}</Typography></Tooltip>
              </Grid>
              <Grid item >
                {loading && <CircularProgress size={20} />}
              </Grid>
            </Grid>
          </label>
        </div>
        <div style={{ padding: '16px' }}>
          <SimpleAdd
            arr={arrProps}
            formName={formName}
            gridProps={{ xs: 12 }}
          />
        </div>
        <FormControlLabel
          className={classes.formControl}
          control={
            <Checkbox
              checked={values.estimate || false}
              onChange={(e) => { dispatch(actions2.formChange(e.target.checked, { field: 'estimate', name: formName })) }}
              color="primary"
            />
          }
          label="Материалы заказчика"
        />
      </Grid>
      <Grid item xs={6}>
        <OldFileList selectedFile={selectedFile} formName={formName} loadFilePrice={loadFilePrice}/>
      </Grid>
    </Grid>
  </div >
  );
}

function fileNameShorter(fileName) {
  if (fileName.length > 18) {
    return fileName.slice(0, 7) + '...' + fileName.slice(-8)
  }
  else return fileName
}

function OldFileList({ selectedFile, formName, loadFilePrice }) {
  const dispatch = useDispatch()
  const filePAllIds = useSelector(state => state.form[formName]?.values.filePAllIds ||[])
  const filePByIds = useSelector(state => state.form[formName]?.values.filePByIds ||{})
  const handleSelectFile = (obj) => {
    dispatch(actions2.formChangeAsObj({ file: obj.name, fileShort: fileNameShorter(obj.name), fileId: obj.idFile }, { name: formName }))
  }
  const handleDeleteFile = (id) => {
    dispatch(fetchDispath({
      progress: true,
      request: { path: deleteFileParse.path({ id: id }), type: deleteFileParse.type },
    }))
      .then(res => {
        loadFilePrice()
      }).catch(err => console.log(err))
  }
  return <>
    <Typography variant='h6'>Старые файлы</Typography>
    <List>
      {filePAllIds.map(key => {
        const obj = filePByIds[key]
        return <ListItem
          key={key}
          button={true}
          onClick={() => handleSelectFile(obj)}
          selected={selectedFile === obj.id}
        >
          <ListItemText primary={obj.name} secondary={moment(obj.date).format('LLL')} />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteFile(obj.id)}><DeleteIcon /></IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      })}
    </List>
  </>
}