import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {
  IconButton, Grid, Typography,
  TableHead, TableRow, TableCell, Checkbox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import BuildIcon from '@material-ui/icons/Build';
import { formObjChangeObj, formChange } from '../../../../reducers/form';
import PopperColumnType from './PopperColumnType'
import PopperColumnVisible from './PopperColumnVisible'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  cell: {
    minWidth: 120
  },
}));

function TableHeader(props) {
  const { formName } = props
  const classes = useStyles();
  const column = useSelector(state => state.form[formName]?.values.column)
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorList, setAnchorList] = useState(null)
  const [selectKey, setSelectKey] = useState(null)
  const dispatch = useDispatch()
  const changeHeader = (name, id, value) => {
    dispatch(formObjChangeObj({ [name]: value }, { field: 'column', id: id, name: formName }))
  }
  const handleClick = (key, currentTarget) => {
    setSelectKey(key)
    setAnchorEl(currentTarget)
  };
  return <TableHead>
    <PopperColumnType anchorEl={anchorEl} onClose={() => setAnchorEl(null)} column={column} formName={formName} selectKey={selectKey} />
    <PopperColumnVisible anchorList={anchorList} onClose={() => setAnchorList(null)} formName={formName} />
    <TableRow>
      <CheckboxCell formName={formName} setAnchorList={e => setAnchorList(e)} />
      {Object
        .keys(column)
        .filter(key => column[key].visible)
        .map(key => {
          const obj = column[key];
          return <TableCell key={key} className={classes.cell} padding='none' >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-end"
              wrap='nowrap'
            >
              <Grid item ><Typography>{(obj.select === '') ? key : obj.selectName}</Typography></Grid>
              <Grid item >
                <IconButton size='small' onClick={(e) => { handleClick(key, e.currentTarget) }}>
                  <BuildIcon fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item >
                <IconButton size='small' onClick={() => { changeHeader('visible', key, false) }}>
                  <CancelIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          </TableCell>
        })}
    </TableRow>
  </TableHead>
}

export default TableHeader


function CheckboxCell({ formName, setAnchorList }) {
  const selectId = useSelector(state => state.form[formName]?.values.selectId || [])
  const allIds = useSelector(state => state.formTable[formName]?.allIds || [])
  const all = allIds.length === selectId.length
  const dispatch = useDispatch()
  const handleSelect = () => {
    if (all) {
      dispatch(formChange([], { field: 'selectId', name: formName }));
    }
    else {
      dispatch(formChange(allIds, { field: 'selectId', name: formName }));
    }
  }
  return <TableCell style={{ minWidth: 120 }}>
    <Checkbox
      checked={all}
      onClick={(e) => handleSelect()}
    />
    <IconButton size='small' onClick={(e) => { setAnchorList(e.currentTarget) }}>
      <BuildIcon fontSize='small' />
    </IconButton>
  </TableCell>
}