import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { TextField, Popper, Grid, } from '@material-ui/core'
import { formChange } from '../../../reducers/form';
import { fetchImg } from '../../../functions/fetch'

export default function OcrByIdHeader({ formName, imgPath }) {
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  // const [size, setSize] = useState(0.5)
  const size=0.5
  const [anchorEl, setAnchorEl] = useState(null);
  const [pos, setPos] = useState({ leftX: 486, topY: 541, rightX: 3405, pageNumber: 1, bottomY: 589 })
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChange = (value, field) => {
    dispatch(formChange(value, { name: formName, field: field }))
  }
  const { values } = form
  return <div>
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={3}>
        <TextField
          label='Номер документа'
          fullWidth
          value={values.numberNew || ''}
          onChange={(e) => handleChange(e.target.value, 'numberNew')}
          onFocus={(e) => { setPos(values.numberPosition); handleClick(e); }}
          onBlur={() => setAnchorEl(null)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          type='date'
          fullWidth
          label='Дата документа'
          value={values.dateNew || ''}
          onChange={(e) => handleChange(e.target.value, 'dateNew')}
          onFocus={(e) => { setPos(values.datePosition); handleClick(e); }}
          onBlur={() => setAnchorEl(null)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label='Плательщик'
          value={values.customerNew || ''}
          onChange={(e) => handleChange(e.target.value, 'customerNew')}
          onFocus={(e) => { setPos(values.recognizedCustomer['namePosition']); handleClick(e); }}
          onBlur={() => setAnchorEl(null)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label='Поставщик'
          value={values.supplierNew || ''}
          onChange={(e) => handleChange(e.target.value, 'supplierNew')}
          onFocus={(e) => { setPos(values.recognizedSupplier['namePosition']); handleClick(e); }}
          onBlur={() => setAnchorEl(null)}
        />
      </Grid>
    </Grid>
    <CustomPoper
      anchorEl={anchorEl}
      handleClose={() => setAnchorEl(null)}
      size={size}
      pos={pos}
      imgPath={imgPath}
    />
  </div>
}
function CustomPoper({ anchorEl, handleClose, size, pos, imgPath }) {
  const { leftX,/* rightX,*/ topY, bottomY, pageNumber } = pos || { leftX: 0, rightX: 0, topY: 0, bottomY: 0, pageNumber: 1 }
  const dispatch = useDispatch()
  const [url, setUrl] = useState('')
  useEffect(() => {
    dispatch(fetchImg(imgPath + '/' + pageNumber))
      .then(response => response.blob())
      .then(blob => {
        let url = URL.createObjectURL(blob);
        console.log(url)
        setUrl(`url(${url})`)
      })
  }, [dispatch, imgPath, pageNumber])
  const open = Boolean(anchorEl);
  return <Popper
    open={open}
    anchorEl={anchorEl}
    onClose={(e) => { console.log('poper', e.target); handleClose() }}
    placement="top"
    style={{ zIndex: 2000, width: 552 }}
  >
    <div style={{
      width: '100%',
      backgroundColor: '#fff',
      padding: '5px',
      boxShadow: '0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)',
      borderRadius: '2px',
    }}>
      <div id='poper_ocr_byid_header' style={{
        backgroundImage: url,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: `-${leftX * size}px -${topY * size}px`,
        backgroundSize: 3534 * size,
        height: (bottomY - topY) * size,
        width: '100%'
        // width: (rightX - leftX) * size
      }} />
    </div>
  </Popper>
}