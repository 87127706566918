import React, { useState, useEffect, useCallback } from 'react';
import {  Button,  } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux';
import TableShipment from './source/TableShipment'
import * as actions from '../../../../reducers/form';
import {
  loadAutocompliteAPI, initialAutocomplite, clearAutocomplite,
  addFilterAutocomplite, set as setAutocomplite, addFilterManyAutocomplite
} from '../../../../reducers/autocomplite'
import { getStorageUnit, } from '../../../../api/list'
import { getMaterials } from '../../../../api/material'
import { addShipmentMaterial } from "../../../../api/shipment";
import moment from 'moment'
import { enqueueSnackbar } from '../../../../reducers/notifier'
import TableFile from './source/TableFile'
import { selectHistory } from '../../../../reducers/history';
import { nds } from '../../../../enum/nds.enum';
import ShipmentAddHeader from './source/ShipmentAddHeader';

const row = {
  name: '',
  nameNotEstimate: '',
  count: '',
  price: '',
  idMaterial: '',
  priceOld: '',
  balance: '',
  storageUnit: '',
  idStorageUnit2: '',
  storageUnit2Value: null,
  coef: '1',
  value: null,
  idOrder: undefined,
  nds: nds[0].value,
}
const arrMaterial = 'material'
const arrStorageUnit = 'storageUnit'
const formName = 'ShipmentMaterialAdd'
const formOrders = 'selectOrders'
const formMaterialSelected = 'materialSelected'
const formMOP = 'MOP_Shipment'
export default function ShipmentAdd({ match, history }) {
  const dispatch = useDispatch()
  const orders = useSelector(state => state.form[formOrders] || state.form.default)
  const mop = useSelector(state => state.form[formMOP] || state.form.default)
  const selectedMaterial = useSelector(state => state.form[formMaterialSelected] || state.form.default)
  const historyMat = useSelector(state => state.history)
  const [selectedLoad, setSelectedLoad] = useState(typeof selectedMaterial.values.arr === 'undefined')
  const [ordersLoad, setOrdersLoad] = useState(typeof orders.values.arr === 'undefined')
  const [mopLoad, setMOPLoad] = useState(typeof mop.values.arr === 'undefined')
  const material = useSelector(state => state.autocomplite[arrMaterial] || state.autocomplite.default)
  const form = useSelector(state => state.form[formName] || state.form.default)
  const idRow = useSelector(state => state.form[formName]?.values?.idRow || 0)
  const ndsDefault = useSelector(state => state.form[formName]?.values?.ndsDefault || nds[0].value)
  const { values = {} } = form
  const idProject = match.params.idProject

  useEffect(() => {
    dispatch(actions.formInitialize({
      id: idProject,
      idProvider: '',
      byId: {},
      allIds: [],
      date: moment(Date.now()).format('YYYY-MM-DD'),
      draverId: 'id0',
      draverOpen: false,
      idRow: 0,
      fById: {},
      fAllIds: [],
      ndsDefault: nds[0].value
    }, { name: formName }))
    dispatch(initialAutocomplite({ field: arrMaterial }))
    dispatch(initialAutocomplite({ field: arrStorageUnit }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(actions.formDestroy({ name: formOrders }))
      dispatch(actions.formDestroy({ name: formMOP }))
      dispatch(actions.formDestroy({ name: formMaterialSelected }))
      dispatch(clearAutocomplite({ field: arrMaterial }))
      dispatch(clearAutocomplite({ field: arrStorageUnit }))
    }
  }, [dispatch, idProject])
  useEffect(() => {
    dispatch(loadAutocompliteAPI(getMaterials.path({ id: idProject }) + '?balance=our&type=balance', {
      field: 'material',
      value: 'id',
      hidden: ['price', 'balance', 'storageUnit', 'idStorageUnit'],
      labelFunc: (obj) => obj.name + ' ' + obj.typeMark + ' ' + obj.vendorCode
    }))
    dispatch(loadAutocompliteAPI(getStorageUnit.path, {
      field: 'storageUnit',
      value: 'id',
      labelFunc: (obj) => obj.name
    }))
  }, [dispatch, idProject])

  const addRow = useCallback(() => {
    const newIdRow = idRow + 1;
    const rowAdd = { ...row, nds: ndsDefault }
    dispatch(actions.formObjAdd(rowAdd, { field: 'byId', id: 'id' + newIdRow, name: formName }))
    dispatch(actions.formArrayAdd('id' + newIdRow, { field: 'allIds', name: formName, end: true }))
    dispatch(actions.formChange('id' + newIdRow, { field: 'draverId', name: formName }))
    dispatch(actions.formChange(true, { field: 'draverOpen', name: formName }))
    dispatch(actions.formChange(newIdRow, { field: 'idRow', name: formName }))
  }, [dispatch, idRow, ndsDefault])
  // load orders
  useEffect(() => {
    if (ordersLoad) return;
    if (!material.load) return;
    const { values } = orders
    if (!values.arr) return;
    let newId = 0
    let objAdd = {}
    let arrAdd = []
    let filterAdd = []
    values.arr.forEach((obj, i) => {
      const findMaterial = material.arr.find(e => obj.id.toString() === e.value)
      if (!findMaterial) return;
      newId += 1
      objAdd['id' + newId] = {
        ...row,
        balance: findMaterial.balance,
        priceOld: findMaterial.price,
        idMaterial: findMaterial.value,
        name: findMaterial.label,
        storageUnit: findMaterial.storageUnit,
        idStorageUnit2: findMaterial.idStorageUnit,
        storageUnit2Value: { value: findMaterial.idStorageUnit, label: findMaterial.storageUnit },
        value: findMaterial,
        idOrder: obj.idOrder,
        count: obj.count
      }
      arrAdd.push('id' + newId)
      filterAdd.push({ value: findMaterial.value, id: 'id' + newId })
    })
    dispatch(addFilterManyAutocomplite(filterAdd, { field: arrMaterial }))
    dispatch(actions.formChangeAsObj({
      allIds: arrAdd,
      byId: objAdd,
      idRow: newId
    }, { name: formName }))
    setOrdersLoad(true)
  }, [dispatch, material, orders, ordersLoad])
  // load mop
  useEffect(() => {
    if (mopLoad) return;
    if (!material.load) return;
    const { values } = mop
    if (!values.arr) return;
    let newId = 0
    let objAdd = {}
    let arrAdd = []
    let filterAdd = []
    values.arr.forEach((obj, i) => {
      const findMaterial = material.arr.find(e => obj.id.toString() === e.value)
      if (!findMaterial) return;
      newId += 1
      objAdd['id' + newId] = {
        ...row,
        balance: findMaterial.balance,
        priceOld: findMaterial.price,
        idMaterial: findMaterial.value,
        name: findMaterial.label,
        storageUnit: findMaterial.storageUnit,
        idStorageUnit2: findMaterial.idStorageUnit,
        storageUnit2Value: { value: findMaterial.idStorageUnit, label: findMaterial.storageUnit },
        value: findMaterial,
        idOrder: obj.idOrder,
        count: obj.count,
        nds: obj.nds || row.nds,
        price: obj.price || ''
      }
      if (obj.idEstimate) {
        objAdd['id' + newId].idEstimate = obj.idEstimate
        objAdd['id' + newId].name = obj.estimateName + `(${findMaterial.label})`
        objAdd['id' + newId].estimateName = obj.estimateName
      }
      arrAdd.push('id' + newId)
      filterAdd.push({ value: findMaterial.value, id: 'id' + newId })
    })
    dispatch(addFilterManyAutocomplite(filterAdd, { field: arrMaterial }))
    dispatch(actions.formChangeAsObj({
      allIds: arrAdd,
      byId: objAdd,
      idRow: newId
    }, { name: formName }))

    if (values.provider) {
      dispatch(setAutocomplite(values.provider, { field: 'provider' }))
      dispatch(actions.formChange(values.provider.value + '', { field: 'idProvider', name: formName }))
    }
    if (values.dateOrder) {
      dispatch(actions.formChange(values.dateOrder, { field: 'date', name: formName }))
    }
    setMOPLoad(true)
  }, [dispatch, material, mop, mopLoad])
  // load selected materials
  useEffect(() => {
    if (selectedLoad) return;
    if (!material.load) return;
    const { arr } = selectedMaterial.values
    if (arr.length === 0) return;
    let newId = 0
    let objAdd = {}
    let arrAdd = []
    let filterAdd = []
    arr.forEach((id, i) => {
      const findMaterial = material.arr.find(e => id.toString() === e.value)
      if (!findMaterial) return;
      newId += 1
      objAdd['id' + newId] = {
        ...row,
        balance: findMaterial.balance,
        priceOld: findMaterial.price,
        idMaterial: findMaterial.value,
        name: findMaterial.label,
        storageUnit: findMaterial.storageUnit,
        idStorageUnit2: findMaterial.idStorageUnit,
        storageUnit2Value: { value: findMaterial.idStorageUnit, label: findMaterial.storageUnit },
        value: findMaterial,
      }
      arrAdd.push('id' + newId)
      filterAdd.push({ value: findMaterial.value, id: 'id' + newId })
    })
    dispatch(addFilterManyAutocomplite(filterAdd, { field: arrMaterial }))
    dispatch(actions.formChangeAsObj({
      allIds: arrAdd,
      byId: objAdd,
      idRow: newId
    }, { name: formName }))
    setSelectedLoad(true)
  }, [dispatch, material, selectedMaterial, selectedLoad])
  useEffect(() => {
    console.log('historyMat')
    if (!historyMat.click) return;
    const [type, key] = historyMat.click.split('_')
    if (type === 'work') return;
    const obj = historyMat[idProject].byIdM[key]
    dispatch(selectHistory(null))
    const findMaterial = material.arr.find(e => obj.idMP.toString() === e.value)
    if (!findMaterial) return;
    addRow()
    dispatch(actions.formObjChangeObj({
      balance: findMaterial.balance,
      priceOld: findMaterial.price,
      idMaterial: findMaterial.value,
      name: findMaterial.label,
      storageUnit: findMaterial.storageUnit,
      idStorageUnit2: findMaterial.idStorageUnit,
      storageUnit2Value: { value: findMaterial.idStorageUnit, label: findMaterial.storageUnit },
      value: findMaterial,
      idOrder: undefined
    }, { field: 'byId', id: 'id' + (idRow + 1), name: formName }))
    dispatch(addFilterAutocomplite(findMaterial.value, { field: 'material', id: 'id' + (idRow + 1) }))
  }, [historyMat.click, idRow, dispatch, idProject])
  const errMsg = (msg) => dispatch(enqueueSnackbar({ message: msg, options: { variant: 'error' } }));
  const saveServer = (delivery = false) => {
    let arr = []
    const arrOrder = [];
    let errBalance = false
    let errMaterial = false
    let err = false
    values.allIds.forEach(row => {
      const obj = values.byId[row]
      const count = parseFloat(obj.count.toString().replace(',', '.').replace(' ', ''))
      const price = parseFloat(obj.price.replace(',', '.').replace(' ', ''))
      const coef = parseFloat(obj.coef.replace(',', '.').replace(' ', ''))
      if (obj.idMaterial > 0 && count > 0 && price > 0) {
        const value = {
          count: count,
          price: price,
          idMaterial: obj.idMaterial,
          idEstimate: obj.idEstimate,
          coef: 1,
          idStorageUnit: null,
          nds: obj.nds
        }
        if (obj.count > obj.balance / (obj.idStorageUnit !== obj.idStorageUnit2 ? coef : 1)) errBalance = true
        if (obj.idStorageUnit !== obj.idStorageUnit2) {
          value.idStorageUnit = obj.idStorageUnit2
          value.coef = coef
        }
        arr.push(value)
        if (obj.idOrder) arrOrder.push(obj.idOrder);
      }
      else if (obj.idMaterial > 0) errMaterial = true
    });
    if (values.idProvider === '') {
      errMsg('Поле поставщик не заполнено')
      err = true
    }
    if (errMaterial) {
      errMsg('Ошибки в заполнении материалов(цена,количество)')
      err = true
    }
    if (errBalance) {
      errMsg('Количество  материала не может быть больше чем в смете')
      err = true
    }
    if (err) return;
    dispatch(actions.formSubmitAPI({
      props: {
        idProject: idProject,
        idProvider: values.idProvider,
        data: arr,
        dateDelivery: values.date,
        files: Object.values(values.fById).map(e => e.id),
        orders: arrOrder
      },
      url: addShipmentMaterial.path,
      method: addShipmentMaterial.type,
      history: (delivery) ? undefined : history,
      endFunc: (res) => {
        if (res.success && delivery) {
          history.push(`/projects/${idProject}/delivery/doc/${res.devliveryId}`)
        }
      }
    }, formName))
  }
  return (
    <div className='App-paper'>
      <ShipmentAddHeader formName={formName} />
      <TableShipment
        idProject={idProject}
        formName={formName}
        addRow={addRow}
        loadOrder={ordersLoad}
      />
      <TableFile formName={formName} />
      <Button color='primary' component="span" onClick={() => history.goBack()}>Назад</Button>
      <Button color='primary' component="span" onClick={() => saveServer()}>Сохранить</Button>
      <Button color='primary' component="span" onClick={() => saveServer(true)}>Сохранить и оприходовать</Button>
    </div>
  )
}