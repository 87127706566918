import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import IconMaterials from '@material-ui/icons/ListAlt'
import { ListItem, ListItemIcon, ListItemText, Drawer, List } from '@material-ui/core';
import SelectedProject from '../listLinlDraver/SelectedProject'
import { API_BO } from '../../conf'
import { Link } from 'react-router-dom';
import ListProjectSelect from './ListProjectSelect'
import { butt } from './ListButton'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const drawerWidth = 240;
const useStyleDriver = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justify: 'flex-start',
    padding: '0 8px',
    // marginLeft: 'auto',
    marginRight: 'auto',
    marginLeft: '33px',
    ...theme.mixins.toolbar,
  },
}))
const ListItemTextBold = (props) => <ListItemText primaryTypographyProps={{ style: { fontWeight: 'bold' } }} {...props} />

export default function LeftDrawer({ handleDrawerClose, open }) {
  const theme = useTheme();
  const isPermanent = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyleDriver()
  const [selectedDriver, setSelected] = useState('')
  const facilityId = useSelector(state => state.header?.facilityId)
  const objectDisabled = typeof facilityId === 'undefined' || facilityId === 0
  return <Drawer
    variant={isPermanent ? 'permanent' : 'temporary'}
    className={classNames(classes.drawer, {
      [classes.drawerOpen]: open,
      [classes.drawerClose]: !open,
    })}
    classes={{
      paper: classNames({
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      }),
    }}
    open={open}
  >
    <div className={classes.toolbar}>
      <img src='/logo.svg' alt='logo' width='80%' height={'120%'} />
    </div>
    <hr className='App-divider' />
    <List component="nav">
      {!isPermanent && <React.Fragment>
        <ListItemButton text='Главное' onClick={handleDrawerClose} open={open} />
        <ListItem><ListItemTextBold primary={localStorage.getItem('accountName') || ''} /></ListItem>
        <ListItemLink text='Аккаунты' to='/accounts' />
        {butt.map(e => <ListItemLink key={e.primary} text={e.primary} to={e.link} />)}
        <ListItem><ListItemTextBold inset primary={"Объект"} /></ListItem>
      </React.Fragment>}
      {isPermanent && <ListItemButton text='Объект' onClick={handleDrawerClose} open={open} />}
      <ListItemLink text='Главное' to={`/facility/our/${facilityId}`} Icon={<AccountBalanceIcon />} disabled={objectDisabled} />
      <ListItemLink text='Меню' to={`/facility/our/${facilityId}/menu`} Icon={<IconMaterials />} disabled={objectDisabled} />
      <hr className='App-divider' />
      {open && <ListProjectSelect facilityId={facilityId} />}
    </List>
    <hr className='App-divider' />
    <SelectedProject open={open} selectedDriver={selectedDriver} setSelected={name => setSelected(name)} />
    <hr className='App-divider' />
    <ListItemButton text='Скрыть панель' onClick={handleDrawerClose} open={open} />
  </Drawer>
}

function ListItemLink({ to, disabled = false, Icon = null, text }) {
  const itemLink = React.forwardRef((props, ref) => (<Link ref={ref} to={API_BO + to} {...props} />))
  return <ListItem button component={itemLink} disabled={disabled}>
    {Icon && <ListItemIcon>{Icon}</ListItemIcon>}
    <ListItemText primary={text} />
  </ListItem >
}

function ListItemButton({ onClick, text, open }) {
  return <ListItem button onClick={onClick}>
    <ListItemIcon> {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}</ListItemIcon>
    <ListItemTextBold primary={text} />
  </ListItem>
}