import React from 'react'
import { useSelector } from 'react-redux';
import { List, ListItem, ListItemText } from '@material-ui/core';
import IconStore from '@material-ui/icons/Store'
import IconAnalytics from '@material-ui/icons/Score'
import IconSettings from '@material-ui/icons/Settings'
import ListIcon from '@material-ui/icons/List';
import { makeStyles } from '@material-ui/core/styles';
import ListItemLink from './ListItemLink'
import classNames from 'classnames';
import { API_BO } from '../../conf'
import checkCan from '../../functions/can'
import IconProd from '@material-ui/icons/Domain'
import IconMaterials from '@material-ui/icons/ListAlt'
import PeopleIcon from '@material-ui/icons/People';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nestedOpen: {
    transition: theme.transitions.create('padding-left',
      {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    paddingLeft: theme.spacing(4),
  },
  nestedClose: {
    transition: theme.transitions.create('padding-left',
      {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    paddingLeft: theme.spacing(2),
  },
  selectedClass: {
    backgroundColor: '#909090'
  },
  selected: {
    backgroundColor: '#bababa'
  },
}));


const arr = [
  { check: 'material:read', to: '/est-menu', icon: <ListIcon />, primary: 'Смета' },
  { check: 'material:read', to: '/documents', icon: <IconMaterials />, primary: 'Снабжение' },
  { check: 'material:read', to: '/prodmenu', icon: <IconProd />, primary: 'Производство' },
  { check: 'storage:read', to: '/storage', icon: <IconStore />, primary: 'Склад' },
  { check: 'project:analytics', to: '/analytics', icon: <IconAnalytics />, primary: 'Аналитика' },
  { check: 'project:update', to: `/settings`, icon: <IconSettings />, primary: 'Настройка' },
  { check: 'material:read', to: `/contractor`, icon: <PeopleIcon />, primary: 'Подрядчики' },
]
function getArr(idProject) {
  return arr.map(({ check, to, icon, primary }) =>
    checkCan(check) && { to: `${API_BO}/projects/${idProject}` + to, icon, primary, id: to }
  ).filter(key => typeof key !== 'boolean')
}
export default function SelectedProject({ setSelected, selectedDriver, open }) {
  const classes = useStyles()
  const projectId = useSelector(state => state.header?.projectId || 0)
  return (
    <List component="nav">
      <ListItem>
        <ListItemText inset primary={"Проект"} primaryTypographyProps={{ style: { fontWeight: 'bold' } }} />
      </ListItem>
      {getArr(projectId).map(obj => <ListItemLink
        key={obj.id}
        disabled={projectId === 0}
        onClick={() => setSelected(obj.id)} {...obj}
        classes={
          classNames({
            [classes.nestedOpen]: open,
            [classes.nestedClose]: !open,
            [classes.selected]: selectedDriver === obj.id
          })
        }
      />)}
    </List>
  )
}