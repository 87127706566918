import React from 'react'
import { Button, Drawer, List, ListItem, ListItemText, Typography, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { openHistory, selectHistory, delHistory, clearHistory } from '../../reducers/history';
import DeleteIcon from '@material-ui/icons/Delete';

export default function HistoryDrawer({ idProject }) {
  const historySel = useSelector(state => state.history)
  const dispatch = useDispatch()
  const params = historySel[idProject] ? historySel[idProject] : historySel.default
  return <Drawer
    anchor='right'
    open={historySel.open}
    onClose={() => dispatch(openHistory(false))}
  >
    <div style={{ paddingLeft: 20, maxWidth: '25vw' }}>
      <Button onClick={() => dispatch(clearHistory(idProject))} color='primary'>Отчистить историю</Button>
      <Typography>Материалы</Typography>
      <List component="nav">
        {params.allIdsM.map(key => {
          const obj = params.byIdM[key]
          return <ListItem button key={key} onClick={() => dispatch(selectHistory('material_' + key))}>
            <ListItemText primary={obj.name} secondary={obj.groupName} />
            <ListItemSecondaryAction>
              <IconButton onClick={() => dispatch(delHistory(key, { idProject, type: 'material' }))} size='small'>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        })}
      </List>
      <Typography>Работа</Typography>
      <List component="nav">
        {params.allIdsW.map(key => {
          const obj = params.byIdW[key]
          return <ListItem button key={key} onClick={() => dispatch(selectHistory('work_' + key))}>
            <ListItemText primary={obj.name} secondary={obj.groupName} />
            <ListItemSecondaryAction>
              <IconButton onClick={() => dispatch(delHistory(key, { idProject, type: 'work' }))} size='small'>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        })}
      </List>
    </div>
  </Drawer>
}