import React from 'react';
import { getWork, deleteWorkById } from '../../api/work'
import { typework } from '../../api/list'
import PageList from '../../components/PageListAPI'
import can from '../../functions/can'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom';

export default function WorkList(props) {
  const columns = [
    { id: 'number', name: 'Номер', },
    { id: 'name', name: 'Наименование', },
    { id: 'storageUnit', name: 'Ед. изм.', },
    { id: 'count', name: 'Количество', },
    { id: 'balance', name: 'Выполнено', },
    { id: 'done', name: 'Осталось', },
    { id: 'typeW', name: 'Тип', },
    can('work:readAll') && { id: 'price', name: 'Стоимость единицы', },
  ].filter(el => typeof el === 'object')
  const toLinkPrice = React.forwardRef((props1, ref) => (<Link ref={ref} to={props.match.url + '/price'} {...props1} />))
  const toLinkLoad = React.forwardRef((props1, ref) => (<Link ref={ref} to='/multi/material/load' {...props1} />));
  const toLinkLoadTemplate = React.forwardRef((props1, ref) => (<Link ref={ref} to={props.match.url + '/load-template'} {...props1} />));
  return (
    <div className='App-paper'>
      <PageList
        name='worlList'
        columns={columns}
        createItem={can('work:create')}
        deleteItem={can('work:delete')}
        loadAPI={{
          param: {
            id: props.match.params.idProject
          },
          transform: (res => {
            return {
              count: res.count,
              get: res.get.map(obj => {
                return { ...obj, done: Math.round((obj.count - obj.balance) * 1000) / 1000 }
              })
            }
          }),
          request: getWork,
        }}
        deleteAPI={deleteWorkById(props.match.params.idProject)}
        disabledDelete={obj => obj.balance > 0}
        headerLink={
          <React.Fragment>
            {can('work:create') && <Button component={toLinkLoad} color="primary" >Загрузить </Button>}
            {can('work:create') && <Button component={toLinkLoadTemplate} color="primary" >Загрузить из шаблона</Button>}
            <Button component={toLinkPrice} color='primary'>Изменить цены</Button>
          </React.Fragment>
        }
        filterProps={[
          {
            type: 'autocomplite',
            path: typework.getList.path,
            field: 'idType',
            name: 'Тип работ',
          },
        ]}
        {...props}
      />
    </div>
  )
}