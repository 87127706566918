import React, { useState, useEffect } from 'react'
import { Dialog, Grid, DialogActions, DialogTitle, DialogContent, Button, Tooltip, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import AutoComplite from '../../components/Autocomplite'
import * as actions from '../../reducers/form';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import arrowType from './gantArrowType'
import { addArrowWorkToGant } from '../../api/form-gant.api'
import { fetchDispath } from '../../functions/fetch'
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import { addGantArrow } from '../../reducers/gant'

export default function GantAddArrow({ formName, idProject }) {
  const [open, setOpen] = useState(false)
  return (
    <React.Fragment>
      <Tooltip title="Добавить связь" aria-label="add">
        <IconButton color='primary' onClick={() => setOpen(true)}><SettingsEthernetIcon /></IconButton>
      </Tooltip>
      {open && <ArrowDialog open={open} onClose={() => setOpen(false)} formName={formName} idProject={idProject} />}
    </React.Fragment>
  );
}

function ArrowDialog({ formName, idProject, open, onClose }) {
  const [workFrom, setWorkFrom] = useState(null)
  const [workTo, setWorkTo] = useState(null)
  const [type, setArrType] = useState(null)
  const dispatch = useDispatch()
  const gantFlat = useSelector(state => state.gant.flat) 
  const byId = useSelector(state => state.form[formName]?.values.byId || {})
  const [arr, setArr] = useState([])
  useEffect(() => {
    setArr(gantFlat.filter(e=>!e.color).map((e, i) => {
      const obj = byId['id'+e.id]
      return {
        value: obj.id,
        idAPI: obj.idAPI,
        label: `${e.pos} ${obj.name}`,
        index: i
      }
    }))
  }, [gantFlat, byId])

  const changeWork = (text, type) => {
    if (type === 'form') {
      setWorkFrom(text)
    }
    else {
      setWorkTo(text)
    }
  }
  const handleOk = () => {
    const obj = byId[workFrom.value]
    dispatch(fetchDispath({
      param: {
        id: idProject,
        idWork: obj.idAPI
      },
      body: {
        idTo: workTo.idAPI,
        typeArrow: type.value,
      },
      request: addArrowWorkToGant,
    })).then(res => {
      dispatch(actions.formObjChange([...obj.dependencies, {
        id: res.id,
        to: workTo.value,
        toIndex: workTo.index,
        type: type.value
      }], {
        name: formName,
        field: 'byId',
        fieldObj: 'dependencies',
        id: workFrom.value
      }))
      dispatch(addGantArrow({
        fromId: obj.idAPI,
        id: res.id,
        to: workTo.idAPI,
        type: type.value
      }))
      onClose()
    }).catch(err => console.log(err))
  }
  return (
    <React.Fragment>
      <Dialog
        open={open}
        maxWidth='sm'
        keepMounted
        fullWidth
        scroll="paper"
        onClose={onClose}
        aria-labelledby="gantt-arrow-add-title"
        PaperProps={{ style: { height: 450 } }}
      >
        <DialogTitle id="gantt-arrow-add-title">Создать связь</DialogTitle>
        <DialogContent >
          <AutoComplite
            arr={arr}
            action={(e) => changeWork(e, 'form')}
            value={workFrom}
            formName={formName}
            palaceHolder='Начало'
          />
          <AutoComplite
            arr={arr}
            action={(e) => changeWork(e, 'to')}
            value={workTo}
            formName={formName}
            palaceHolder='Конец'
          />
          <AutoComplite
            arr={arrowType.arr}
            action={(e) => setArrType(e)}
            value={type}
            formName={formName}
            palaceHolder='Тип связи'
          />
        </DialogContent>
        <DialogActions>
          <Grid container direction='row' alignItems="flex-end">
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CheckIcon />}
                onClick={handleOk}
                size='small'
              >Сохранить
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloseIcon />}
                onClick={onClose}
                size='small'
              >Отменить
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}