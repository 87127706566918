import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { formChange as formChangeAction } from '../reducers/form'
import * as actions from '../reducers/autocomplite'
import { Dialog, DialogContent, NoSsr } from '@material-ui/core'
import { components, useStyle, } from './AutocompliteComponents'
import CreatableSelect from 'react-select/creatable';

function AutocompliteWithLoadCreateModal({
  classes,
  field, // field form
  name,
  path,
  meta,
  formName,
  change,
  selectProps,
  textFieldProps,
  formChange = true,
  value,
  modal
}) {
  const dispatch = useDispatch()
  const metaField = meta.field
  const initValue = value
  const [openDialog, setOpenDialog] = useState(false)
  const classesAuto = useStyle()

  useEffect(() => {
    dispatch(actions.initialAutocomplite({ field: metaField, value: initValue }))
    return () => {
      dispatch(actions.clearAutocomplite({ field: metaField }))
    }
  }, [dispatch, metaField, initValue])
  useEffect(() => {
    dispatch(actions.loadAutocompliteAPI(path, meta))
  }, [dispatch, path])
  const handleChange = (e) => {
    dispatch(actions.set(e, { field: meta.field }))
    if (formChange) dispatch(formChangeAction(e.value + '', { field: field, name: formName }))
    if (change) change(e)
  }
  const handleCreate = (inputValue) => {
    setOpenDialog(true)
    return { success: false }
  }
  const auto = useSelector(state => state.autocomplite[meta.field] || state.autocomplite.default)
  const handleAdd = (res)=>{
    setOpenDialog(false)
    if(res.success){
      let row = { value: res.get[meta.value] + '' }
      if (meta.hidden) meta.hidden.forEach(key => row[key] = res.get[key])
      if (meta.label) row.label = res.get[meta.label] + ''
      else row.label = meta.labelFunc(res.get) + ''
      dispatch(actions.add(row, { field: meta.field }))
      handleChange(row)
    }
  }
  return <div className={classes.formControl}>
    <Dialog
      open={openDialog}
      maxWidth='sm'
      keepMounted
      fullWidth
      scroll="paper"
      onClose={() => setOpenDialog(false)}
      PaperProps={{ style: { height: 400 } }}
    >
      <DialogContent >
        {modal({ handleClose: handleAdd })}
      </DialogContent>
    </Dialog>
    <NoSsr>
      <CreatableSelect
        isDisabled={openDialog}
        isLoading={openDialog}
        onCreateOption={handleCreate}
        classes={classesAuto}
        options={auto.arr}
        textFieldProps={{
          label: name,
          InputLabelProps: {
            shrink: true,
          },
          ...textFieldProps
        }}
        components={components}
        value={value|| auto.value}
        onChange={text => handleChange(text)}
        placeholder={name}
        native
        formatCreateLabel={(e) => {
          return 'Создать "' + e + '"'
        }}
        {...selectProps}
      />
    </NoSsr>
  </div>
}


export default AutocompliteWithLoadCreateModal;