import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../reducers/form';
import {
  COLUMNS_ARR, formViewProps, columnsObjTransform, columnsObjTransformParent,
} from '../gant.const'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  textProps: {
    pointerEvents: "none"
  },
  rectProps: {
    fill: '#ffffff',
    fillOpacity: '0.0',
    "&:hover": {
      fill: "#C0C0C0",
      fillOpacity: '1'
    }
  },
  grid_rect_props: {
    cursor: 'pointer'
  },
  unselectable: {
    '-webkit-touch-callout': 'none', /* iOS Safari */
    '-webkit-user-select': 'none',   /* Chrome/Safari/Opera */
    '-khtml-user-select': 'none',    /* Konqueror */
    '-moz-user-select': 'none',      /* Firefox */
    '-ms-user-select': 'none',       /* Internet Explorer/Edge */
    'user-select': 'none'           /* Non-prefixed version, currently
                                    not supported by any browser */
  },
}));
// таблица с данными --------------------------------------------
export default function TableData({ formName, formDialog, yPadding }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const arrFilter = useSelector(state => state.form?.[formName]?.values.arrFilter) || []
  const startDiag = useSelector(state => state.form[formViewProps]?.values.startDiag) || 1810
  const columnsObj = useSelector(state => state.form[formViewProps]?.values.columnsObj) || {}
  const columnsArrHide = useSelector(state => state.form[formViewProps]?.values.columnsArrHide) || []
  const gantFlat = useSelector(state => state.gant.flat)
  const gantTree = useSelector(state => state.gant.tree)
  const hideParent = (idAPI, hidden) => {
    const arrFlat = [idAPI]
    const find = (par) => {
      gantFlat.filter(e => e.parent === par && !!e.color).forEach(e => {
        arrFlat.push(e.id)
        find(e.id)
      })
    }
    find(idAPI)
    if (hidden) {
      dispatch(actions.formArrayDeleteMany(arrFlat, { name: formName, field: 'arrFilter' }))
    }
    else {
      dispatch(actions.formArrayAddMany(arrFlat, { name: formName, field: 'arrFilter' }))
    }
  }

  const allIdsFiltered = treeToFlatAndFilter(gantTree, arrFilter)
  return <g id="sticky-work">
    <rect
      x={0}
      y={0}
      fill="white"
      fillOpacity={1}
      width={startDiag}
      height={allIdsFiltered.length * yPadding}
      stroke='none'
    />
    {allIdsFiltered.map((flat, i) => {
      const key = 'id' + flat.id
      return <g
        key={key}
        id={flat.id}
        transform={`translate(0,${yPadding * i})`} className={classes.unselectable}
      >
        <rect key={i} x={0} y={0}
          width={startDiag}
          height={yPadding}
          fill={(i % 2 > 0) ? '#ffffff' : "#f5f5f5"}
        />
        <WorkBlock
          formDialog={formDialog}
          id={key}
          classes={classes}
          pos={flat?.pos || ''}
          color={flat?.colorParent || 'none'}
          formName={formName}
          hideParent={() => hideParent(flat.id, flat.hidden)}
          yPadding={yPadding}
          hidden={flat.hidden}
          startDiag={startDiag}
          columnsObj={columnsObj}
          columnsArrHide={columnsArrHide}
        />
      </g>
    })}
  </g>
}
const WorkBlock = memo(function WorkBlock({ formDialog, id, classes, pos, color,
  formName, hideParent, yPadding, hidden, startDiag,
  columnsObj, columnsArrHide, }) {
  const obj = useSelector(state => state.form?.[formName]?.values.byId[id])
  const selected = useSelector(state => {
    return state.form?.[formName]?.values.selected.includes(Number(id.replace('id', '')))
  })

  const dispatch = useDispatch()
  const handleChangeRow = () => {
    dispatch(actions.formChangeAsObj({ obj, id, open: true, newRow: false }, { name: formDialog }))
  }
  const handleChange = () => {
    hideParent(obj.idAPI)
  }
  const handleSelected = () => {
    if (selected) {
      dispatch(actions.formArrayDelete(obj.idAPI, { name: formName, field: 'selected' }))
    }
    else {
      dispatch(actions.formArrayAdd(obj.idAPI, { name: formName, field: 'selected' }))
    }
  }
  let x = 0
  const objView = obj.typeGant === 'parent' ? columnsObjTransformParent({ ...obj, pos }) : columnsObjTransform({ ...obj, pos })
  return <g className={classes.grid_rect_props}>
    <g onClick={handleChangeRow} >
      <rect
        x={0}
        y={0}
        width={obj.typeGant === 'parent' ? startDiag : columnsObj.selected.width}
        height={yPadding}
        fill={obj.typeGant === 'parent' ? obj.color : color}
      />
      <rect x={0} y={0} width={startDiag} height={yPadding} className={classes.rectProps} fill='#ffffff' fillOpacity={'0.0'} />
      {COLUMNS_ARR.filter(e => !columnsArrHide.includes(e)).map(key => {
        const column = columnsObj[key]
        const { width } = column
        x += width
        const props = (key === 'name') ? { clipPath: 'url(#nameChip)' } : {}
        return <text
          key={key}
          x={x - ((column.align === 'center') ? width / 2 : (width - 4))}
          y={yPadding / 2}
          dominantBaseline="middle"
          textAnchor={column.align === 'center' ? 'middle' : 'start'}
          className={classes.textProps}
          fontWeight={obj.typeGant === 'parent' ? 'bold' : 'normal'}
          {...props}
        >{objView[key]}
        </text>
      })}
    </g>
    {obj.typeGant === 'parent'
      ? (<ButtonSelected
        key={id + '_selected_parent'}
        handleSelected={handleChange}
        widthSelected={columnsObj.selected.width}
        yPadding={yPadding}
        pathSvg={hidden
          ? "M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" // V
          : "M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" // ^
        }
      />)
      : (
        <ButtonSelected
          key={id + 'selected_no-parent'}
          handleSelected={handleSelected}
          widthSelected={columnsObj.selected.width}
          yPadding={yPadding}
          pathSvg={selected
            ? "M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM17.99 9l-1.41-1.42-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99z"
            : "M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
          }
        />)
    }
  </g>
})

function ButtonSelected({ handleSelected, widthSelected, yPadding, pathSvg }) {
  return <g onClick={handleSelected}>
    <g transform={`translate(${widthSelected / 2 - 14},${yPadding / 2 - 16}) scale(1.2) `} >
      <path
        fill='#555555'
        d={pathSvg}
      />
    </g>
    <rect
      x={0}
      y={0}
      width={widthSelected}
      height={yPadding}
      fillOpacity={0.0}
    />
  </g>
}

function treeToFlatAndFilter(tree, arrFilter) {
  const sorted = [];
  const func = (partTree, pos) => {
    partTree.forEach(path => {
      const { children, ...other } = path
      const hidden = arrFilter.includes(other.id)
      sorted.push({ ...other, pos: pos + (path.pos || ''), hidden: hidden });
      if (children.length > 0 && !hidden) {
        func(children, pos + (other.pos || '') + '.');
      }
    });
  };
  func(tree, '');
  return sorted;
}