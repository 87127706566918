import React, { useEffect, useState } from 'react';
import {
  Button, Tabs, Tab, Typography, Table, Input,
  TableBody, TableCell, TableHead, TableRow,
  Dialog, DialogTitle, DialogActions
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../reducers/form';
import { fetchDispath } from '../../functions/fetch'
import { getMyTenderById, delMyTenderRequest, updTendersEstimate } from '../../api/tender.api'
import moment from 'moment'
import { TENDER_STATUS } from '../tenderOur/TenderOurList'
import ProjectsCreate from './tenderOne/ProjectCreate'
import { getShareTenderById, updTendersShareEstimate, delShareTender } from '../../api/tender.api'
import { API_BO } from '../../conf'

export const TENDER_STATUS_BID = [
  { value: 'none', label: 'не подана', color: '#aaa' },
  { value: 'set', label: 'заявка подана', color: '#333' },
  { value: 'confirm', label: 'заявка принята', color: '#4caf50' },
  { value: 'rejected', label: 'заявка отклонена', color: '#f44336' },
  { value: 'win', label: 'заявка выиграна', color: '#2a3eb1' },
]

const formName = 'TenderOurOneHeader'
const formContacts = 'TenderContact'
const formLots = 'TenderLots'
export default function TenderMyRequestOne({ match, history, share = false }) {
  const dispatch = useDispatch()
  const [tabsId, setTabsId] = useState(0)
  const [statusBid, setStatusBid] = useState('none')
  const [status, setStatus] = useState('draft')
  useEffect(() => {
    dispatch(actions.formInitialize({
      name: '',
      statusRus: '',
      facilityName: '',
      typeTenderRus: '',
      dateConfirmStart: '',
      dateConfirmEnd: '',
      dateOfferEnd: '',
      dateSummingEnd: '',
    }, { name: formName }))
    dispatch(actions.formInitialize({ byId: {}, allIds: [], }, { name: formContacts }))
    dispatch(actions.formInitialize({ projectsArr: [], projectsObj: {}, estimateObj: {} }, { name: formLots }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(actions.formDestroy({ name: formContacts }))
      dispatch(actions.formDestroy({ name: formLots }))
    }
  }, [dispatch])
  useEffect(() => {
    let request = {}
    if (share) {
      request = {
        request: getShareTenderById,
      }
    }
    else {
      request = {
        request: getMyTenderById,
        param: {
          id: match.params.idDoc
        }
      }
    }
    dispatch(fetchDispath({
      progress: true,
      ...request
    })).then(res => {
      dispatch(actions.formChangeAsObj({
        name: res.get.name,
        facilityName: res.get.facilityName,
        dateConfirmStart: moment(res.get.dateConfirmStart).format('LLL'),
        dateConfirmEnd: moment(res.get.dateConfirmEnd).format('LLL'),
        dateOfferEnd: moment(res.get.dateOfferEnd).format('LLL'),
        dateSummingEnd: moment(res.get.dateSummingEnd).format('LLL'),
        typeTenderRus: res.get.typeTender === 'open' ? 'открытый' : 'закрытый',
        statusRus: TENDER_STATUS.find(s => s.value === res.get.status).label,
        regionsArr: res.get.regions,
        regions: res.get.regions.join(', '),
        specializationsArr: res.get.specializations,
        specializations: res.get.specializations.join(', '),
        statusBid: res.get.statusBid,
        statusBidRus: TENDER_STATUS_BID.find(s => s.value === res.get.statusBid).label,
      }, { name: formName }))
      setStatusBid(res.get.statusBid)
      setStatus(res.get.status)
      let byId = {}
      const allIds = res.get.contacts.map(e => {
        const newId = 'id' + e.id;
        byId[newId] = { ...e, fio: [e.surname, e.name, e.patronymic].join(' ') }
        return newId
      });
      dispatch(actions.formChangeAsObj({ byId, allIds }, { name: formContacts }))
      const { estimate, projects } = res.get.estimate;
      const projectsArr = []
      let projectsObj = {}
      projects.forEach(obj => {
        projectsArr.push(obj.id)
        let groupObj = {}
        const groupArr = obj.group.map(e => {
          groupObj[e.id] = { ...e, child: [] };
          return e.id
        })
        projectsObj[obj.id] = {
          id: obj.id,
          dateStart: moment(obj.dateStart).format('YYYY-MM-DD'),
          dateEnd: moment(obj.dateEnd).format('YYYY-MM-DD'),
          name: obj.name,
          group: {
            arr: groupArr,
            obj: groupObj,
          }
        }
      })
      let estimateObj = {}
      estimate.forEach(e => {
        const chooseDateStart = e.chooseDateStart ? moment(e.chooseDateStart).format('YYYY-MM-DD') : ''
        const chooseDateEnd = e.chooseDateEnd ? moment(e.chooseDateEnd).format('YYYY-MM-DD') : ''
        let choose = {
          chooseExist: e.chooseExist,
          chooseDateStart: chooseDateStart === '' ? moment(e.dateStart).format('YYYY-MM-DD') : chooseDateStart,
          chooseDateEnd: chooseDateEnd === '' ? moment(e.dateEnd).format('YYYY-MM-DD') : chooseDateEnd,
          choosePrice: e.choosePrice || 0
        }
        estimateObj[e.id] = {
          ...e,
          dateStart: moment(e.dateStart).format('DD.MM.YYYY'),
          dateEnd: moment(e.dateEnd).format('DD.MM.YYYY'),
          typeEstimate: e.typeEstimate === 'material' ? 'М' : 'Р',
          ...choose
        }
        projectsObj[e.idProject]?.group.obj[e.idGroup]?.child.push(e.id);
      })
      dispatch(actions.formChangeAsObj({ projectsArr, projectsObj, estimateObj }, { name: formLots }))

    }).catch(err => console.log(err))
  }, [dispatch, match.params.idDoc])

  return <div className='App-paper'>
    <HederProps idDoc={match.params.idDoc} history={history} share={share} />
    <Tabs
      value={tabsId}
      onChange={(e, v) => setTabsId(v)}
      indicatorColor='primary'
      style={{ borderBottom: '1px solid #e8e8e8', }}
    >
      <Tab label='Контакты' />
      <Tab label='Лоты' />
      {!share && <Tab label='Создать' disabled={!(statusBid === 'win' && status === 'finished')} />}
    </Tabs>
    {tabsId === 0 && <ContactsTenderAll />}
    {tabsId === 1 && <Lots match={match} share={share} />}
    {tabsId === 2 && !share && <ProjectsCreate idDoc={match.params.idDoc} />}
    {!share && <Button color="primary" onClick={() => history.goBack()}>Назад </Button>}
    {share && <Button color="primary" onClick={() => {
      localStorage.setItem('token', '');
      history.push(API_BO + '/tender_share/login')
    }}>Выйти</Button>}
  </div>
}


const headerCol = [
  { id: 'name', name: 'Название' },
  { id: 'statusRus', name: 'Статус' },
  { id: 'facilityName', name: 'Объект' },
  { id: 'typeTenderRus', name: 'Тип тендера' },
  { id: 'dateConfirmStart', name: 'Дата начала подтверждения участия', },
  { id: 'dateConfirmEnd', name: 'Дата окончания подтверждения участия', },
  { id: 'dateOfferEnd', name: 'Дата окончания предложения', },
  { id: 'dateSummingEnd', name: 'Дата подведения итогов', },
  { id: 'regions', name: 'Регионы', },
  { id: 'specializations', name: 'Специализация', },
]
function HederProps({ idDoc, history, share }) {
  const values = useSelector(state => state.form[formName]?.values) || {}
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const handleDelete = () => {
    if (share && localStorage.getItem('token') === '') return false
    dispatch(fetchDispath({
      progress: true,
      request: share ? delShareTender : delMyTenderRequest,
      param: share ? undefined : {
        id: idDoc,
      },
    })).then((res) => {
      if (share && res?.success) {
        localStorage.setItem('token', '');
        history.push(API_BO + '/tender_share/login')
      }
      history.goBack()
    })
  }
  return <div>
    {headerCol.map(obj => {
      return <div key={obj.id}>{obj.name}:  {values[obj.id]}</div>
    })}
    <Typography style={{ fontWeight: 'bold' }}>Статус заявки: {values.statusBidRus}</Typography>
    <Button color="primary" onClick={() => setOpen(true)}>Удалить заявку</Button>
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      closeAfterTransition={false}
    >
      <DialogTitle id="alert-dialog-title">{"Вы хотите отозвать заявку?"}</DialogTitle>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Нет
        </Button>
        <Button onClick={handleDelete} color="primary" autoFocus>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  </div>
}
const contactsCol = [
  { id: 'fio', type: 'text', name: 'Имя' },
  { id: 'email', type: 'text', name: 'Почта' },
  { id: 'phone', type: 'text', name: 'Телефон' },
  { id: 'jobTitle', type: 'text', name: 'Должность' },
  { id: 'additionalContacts', type: 'text', name: 'Доп. контакт' },
]
export function ContactsTenderAll() {
  const values = useSelector(state => state.form[formContacts]?.values) || { byId: {}, allIds: [] }
  return <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>№</TableCell>
        {contactsCol.map(obj => {
          return <TableCell key={obj.id}>{obj.name}</TableCell>
        })}
      </TableRow>
    </TableHead>
    <TableBody>
      {values.allIds.map((key, i) => {
        return <TableRow key={key} >
          <TableCell padding='checkbox'>{i + 1}</TableCell>
          {contactsCol.map(obj => {
            return <TableCell key={obj.id} >{values.byId[key][obj.id]}</TableCell>
          })}
        </TableRow>
      })}
    </TableBody>
  </Table>
}
const tableHead = [
  { name: 'Номер', id: 'number' },
  { name: 'Тип', id: 'typeEstimate' },
  { name: 'Наименование', id: 'name' },
  { name: 'Ед.изм.', id: 'storageUnit' },
  { name: 'Количество', align: 'right', id: 'count' },
  { name: 'Дата начала', align: 'right', id: 'dateStart' },
  { name: 'Дата окончания', align: 'right', id: 'dateEnd' },
]
function Lots({ match, share }) {
  const values = useSelector(state => state.form[formLots]?.values) || { projectsArr: [], projectsObj: {}, estimateObj: {} }
  const statusBid = useSelector(state => state.form[formName]?.values?.statusBid) || "none"
  const handleDownload = () => {
    const data = ['\ufeff __ \n']
    const column = [
      ...tableHead.map(e => e.name),
      'Цена', 'Сумма', 'Дата начала', 'Дата окончания'
    ].join(';') + '\n'
    values.projectsArr.forEach(key => {
      const project = values.projectsObj[key]
      data.push(`;${project.name}\n`)
      data.push([';Дата работ с', project.dateStart, 'по', project.dateEnd].join(';') + '\n')
      data.push(column)
      project.group.arr.forEach(groupKey => {
        const obj = project.group.obj[groupKey]
        data.push(`${obj.numberPos};${obj.name}\n`)
        const child = project.group.obj[groupKey].child
        child.forEach((id, i) => {
          const est = { ...values.estimateObj[id], number: obj.numberPos + '.' + (i + 1) };
          const str = [
            ...tableHead.map(e => {
              if (e.id === 'name') {
                return est[e.id].replace(/;/g, ' ')
              }
              return est[e.id]
            }),
            est.choosePrice,
            (Math.round(est.choosePrice * est.count * 100) / 100),
            est.chooseDateStart,
            est.chooseDateEnd,
          ]
          data.push(str.join(';') + '\n')
        })
      })
    })
    const file = new Blob(data, { type: 'text/csv' });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
      window.navigator.msSaveOrOpenBlob(file, 'tender.csv');
    else { // Others
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = 'tender.csv';
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }
  const disabledEdit = statusBid !== 'confirm'
  return <div>
    <Button color="primary" onClick={handleDownload}>Скачать </Button>
    {values.projectsArr.map(key => {
      const project = values.projectsObj[key]
      let summAll = 0
      project.group.arr.forEach(groupKey => {
        const child = project.group.obj[groupKey].child
        child.forEach(id => {
          const est = values.estimateObj[id];
          summAll += (Math.round(est.choosePrice * est.count * 100) / 100)
        })
      })
      return <div key={key} style={{ marginBottom: 20 }}>
        <Typography variant='h6'>{project.name}</Typography>
        <div>Дата работ с:  {project.dateStart} по:  {project.dateEnd}</div>
        <div>Сумма: {summAll.toLocaleString()}</div>
        <Table size='small'>
          <TableHead>
            <TableRow>
              {tableHead.map(obj => {
                const align = obj.align || 'inherit'
                return (<TableCell key={obj.id} align={align}>{obj.name}</TableCell>);
              })}
              <TableCell align='right' >Цена</TableCell>
              <TableCell align='right' >Сумма</TableCell>
              <TableCell >Дата начала</TableCell>
              <TableCell >Дата окончания</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {project.group.arr.map(groupKey => {
              return <Row
                key={groupKey}
                obj={project.group.obj[groupKey]}
                child={project.group.obj[groupKey].child}
                estimate={values.estimateObj}
                idTender={match.params.idDoc}
                disabledEdit={disabledEdit}
                share={share}
              />
            })}
          </TableBody>
        </Table>
      </div>
    })}
  </div>
}

function Row({ obj, child, estimate, idTender, disabledEdit, share }) {
  const [show, changeShow] = useState(true)
  const dispatch = useDispatch()
  const handleChangePrice = (est) => {
    let request = {}
    if (share) {
      request = {
        request: updTendersShareEstimate,
        param: {
          idEstimate: est.id
        },
      }
    }
    else {
      request = {
        request: updTendersEstimate,
        param: {
          id: idTender,
          idEstimate: est.id
        },
      }
    }
    dispatch(fetchDispath({
      progress: true,
      ...request,
      body: {
        exist: est.chooseExist,
        price: est.choosePrice,
        dateStart: est.chooseDateStart,
        dateEnd: est.chooseDateEnd,
      }
    })).then((res) => {
      if (res.success) {
        dispatch(actions.formObjChangeObj({
          chooseExist: true
        }, { field: 'estimateObj', id: est.id, name: formLots }))
      }
    })
  }
  const handleChange = (value, field, id) => {
    dispatch(actions.formObjChangeObj({
      [field]: value
    }, { field: 'estimateObj', id: id, name: formLots }))
  }
  return (
    <React.Fragment>
      <TableRow style={{ backgroundColor: '#eee', cursor: 'pointer' }} onClick={() => changeShow(!show)}>
        <TableCell>{obj.numberPos}</TableCell>
        <TableCell colSpan={10}>{obj.name}</TableCell>
      </TableRow>
      {
        show && child.map((id, i) => {
          const est = { ...estimate[id], number: obj.numberPos + '.' + (i + 1) };
          return <TableRow key={id} >
            {tableHead.map(e => {
              return <TableCell key={e.id} align={e.align}>{est[e.id]}</TableCell>
            })}
            <TableCell align='right'><Input
              type='number'
              value={est.choosePrice}
              onChange={(e) => {
                handleChange(e.target.value, 'choosePrice', est.id)
              }}
              inputProps={{ min: 0, style: { textAlign: 'right' } }}
              onSelect={(e) => e.target.select()}
              onBlur={() => handleChangePrice(est)}
              disabled={disabledEdit}
            /></TableCell>
            <TableCell align='right'>{(Math.round(est.choosePrice * est.count * 100) / 100).toLocaleString()}</TableCell>
            <TableCell ><Input
              type='date'
              value={est.chooseDateStart}
              onChange={(e) => {
                handleChange(e.target.value, 'chooseDateStart', est.id)
              }}
              onBlur={() => handleChangePrice(est)}
              disabled={disabledEdit}
            /></TableCell>
            <TableCell ><Input
              type='date'
              value={est.chooseDateEnd}
              onChange={(e) => {
                handleChange(e.target.value, 'chooseDateEnd', est.id)
              }}
              onBlur={() => handleChangePrice(est)}
              disabled={disabledEdit}
            /></TableCell>
          </TableRow>
        })}
    </React.Fragment>
  );
}