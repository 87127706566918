import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { addTeam } from '../../api/team'
import { getWorker } from '../../api/project-worker'
import * as actions from '../../reducers/form';
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite } from '../../reducers/autocomplite'
import { Grid, TextField, Button, Typography } from '@material-ui/core'
import AutoComplite from '../../components/Autocomplite'
import TableTeam from './source/TableTeamAdd'
const formName = 'teamAdd'
const formArr = 'workerProject'
export default function TeamAdd({ history, match }) {
  const dispatch = useDispatch()
  const idProject = match.params.idProject
  useEffect(() => {
    dispatch(actions.formInitialize({
      byId: {},
      allIds: [],
      name: '',
      phone: '',
      master: null,
      idRow: 0
    }, { name: formName }))
    dispatch(initialAutocomplite({ field: formArr }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(clearAutocomplite({ field: formArr }))
    }
  }, [dispatch]);
  useEffect(() => {
    dispatch(loadAutocompliteAPI(getWorker.path({ id: idProject }), {
      field: formArr,
      value: 'id',
      hidden: ['position'],
      labelFunc: (obj) => obj.name + ' ' + obj.surname + ' ' + obj.patronymic
    }))
  }, [dispatch, idProject])
  const workerArr = useSelector(state => state.autocomplite[formArr] || state.autocomplite.default)
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const handleSave = () => {
    dispatch(actions.formSubmitAPI({
      props: {
        name: values.name,
        idMaster: values.idMaster,
        phone: values.phone,
        worker: Object.values(values.byId).map(v => v.id)
      },
      url: addTeam.path({ id: idProject }),
      method: addTeam.type,
      history: history,
    }, formName))
  }
  return <div className='App-paper'>
    <Typography variant='h6'>Создть бригаду</Typography>
    <Grid container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-end"
      spacing={1}
    >
      <Grid item xs={3}>
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            dispatch(actions.formChange(e.target.value, { field: 'name', name: formName }))
          }}
          values={values.name}
          label='Название'
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            dispatch(actions.formChange(e.target.value, { field: 'phone', name: formName }))
          }}
          values={values.name}
          label='телефон для связи'
        />
      </Grid>
      <Grid item xs={6}>
        <AutoComplite
          arr={workerArr.arr}
          action={(e) => { dispatch(actions.formChange(e.value, { field: 'idMaster', name: formName })) }}
          formName={formName}
          palaceHolder='Мастер'
        />
      </Grid>
    </Grid>
    <TableTeam formName={formName} formArr={formArr} />
    <Button color='primary' component="span" onClick={() => history.goBack()}>Назад</Button>
    <Button color='primary' component="span" onClick={handleSave}>Сохранить</Button>
  </div>
}