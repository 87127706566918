import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string'
import * as actions from '../../reducers/formTable';
import CustomTable2 from '../../components/TableCardForm'
import { Link } from 'react-router-dom';
import { fetchDispath } from '../../functions/fetch'
import download from '../../functions/download'
import { enqueueSnackbar } from '../../reducers/notifier'
import FilterArray from '../../components/FilterArray';
import { Typography, Menu, MenuItem, Button, Fade } from "@material-ui/core";
import PageListColumn from '../../components/PageListFilterColumn'
import { formInitialize } from '../../reducers/form';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
  },
  butt: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1
  },
  row: {
    color: '#000000',
    '&$selected': {
      color: '#aaaaaa'
    }
  },
  selected: {},
  table: {
    height: 700
  },
  cell: {
    // fontSize: '0.8rem'
  },
  pagination: {},
  container: {
    height: '70vh'
  }
}));

export default function PageListCustom({
  classes,
  name,
  columns,
  createItem = false,
  deleteItem = false,
  idName = 'id',
  tableProps,
  click = true,
  csv = true,
  headerInit = {},
  notLoad = false,
  notClear = false,
  pagination = true,
  reload = false,
  reloadFunc,
  onClickFunction,
  filterProps = [],
  title = '',
  deleteMulti = false,
  deleteMultiProps = 'arr',
  location,
  match,
  history,
  loadAPI,
  deleteAPI,
  disabledDelete,
  headerLink,
  headerObj,
  tableActions = {},
  additionalActions = []
}) {
  const classesPage = { ...useStyles(), ...classes }
  const dispatch = useDispatch();
  const searchLocation = location.search
  let obj = queryString.parse(searchLocation)
  if (pagination && !obj.limit) {
    obj.limit = '100'
    obj.offset = '0'
  }
  const [search, setSearch] = useState(obj)
  const [searchOld, setSearchOld] = useState('-1')
  const [arrDelete, setArrDelete] = useState([])
  const [arrHidden, setArrHidden] = useState([])
  const formTable = useSelector(state => state.formTable[name] || state.formTable.default)
  const firstHistoryLength = React.useRef(history?.length || 0)
  const load = useCallback((search) => {
    dispatch(fetchDispath({
      querty: {
        ...search,
        ...loadAPI.querty
      },
      param: loadAPI.param,
      progress: true,
      request: loadAPI.request,
      transform: loadAPI.transform,
    })).then(res => {
      dispatch(actions.loadFormTable({ ...res }, { name: name, id: idName }))
      dispatch(actions.setSortFormTable({ sortBy: 'number', sortDirection: 'desc' }, { name: name }))
    }).catch(err => console.log(err))
  }, [dispatch, loadAPI, name, idName])
  useEffect(() => {
    dispatch(actions.initFormTable({ name: name, header: { ...headerInit } }))
    return () => {
      dispatch(actions.clearFormTable(name))
    }
  }, [dispatch, name, notLoad, notClear])
  useEffect(() => {
    if (notLoad) return;
    if (searchLocation === '' && pagination) {
      load(search)
      return;
    }
    if (searchLocation !== searchOld) {
      const searchNew = queryString.parse(searchLocation)
      setSearch(searchNew)
      setSearchOld(searchLocation)
      load(searchNew)
      return;
    }
    if (reload) {
      reloadFunc()
      load(queryString.parse(searchLocation))
      return;
    }
  }, [dispatch, notLoad, searchLocation, load, reload, reloadFunc, searchOld, pagination, search])

  const deleteCustom = (param, body) => {
    dispatch(fetchDispath({
      param,
      body,
      progress: false,
      request: deleteAPI
    }))
      .then((res) => {
        let msg = {}
        if (res) {
          load(search)
          msg = {
            message: `success`,
            options: { variant: 'success' }
          }
        }
        else {
          msg = {
            message: `not deleted`,
            options: { variant: 'error' }
          }
        }
        dispatch(enqueueSnackbar(msg))
      })
  }
  const handleDeleteOne = (id) => deleteCustom({ id: id })
  const handleDeleteMulti = () => {
    deleteCustom(undefined, {
      [deleteMultiProps]: arrDelete.map(id => formTable.byId[id][idName])
    })
    setArrDelete([])
  }
  const linkURL = (id) => `${match.url}/${id}`
  const editFilterValue = (field, value) => {
    let searchNew = {
      ...search,
      limit: 25,
      offset: 0,
      [field]: value
    };
    setSearch(searchNew)
    history.push(`?${queryString.stringify(searchNew)}`)
  }
  const addToArrDelete = (id) => {
    if (arrDelete.includes(id)) {
      setArrDelete(arrDelete.filter(e => id !== e))
    }
    else {
      setArrDelete([...arrDelete, id])
    }
  }
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      if (disabledDelete) {
        const arr = []
        formTable.allIds.forEach(key => {
          const obj = formTable.byId[key]
          const addDel = disabledDelete(obj)
          if (!addDel) arr.push(key)
        })
        setArrDelete(arr)
        return;
      }
      setArrDelete(formTable.allIds)
      return;
    }
    setArrDelete([])
  };
  const handleSort = (sortBy, sortDirection) => {
    dispatch(actions.setSortFormTable({ sortBy, sortDirection }, { name: name }))
  }
  const handleChangePage = (event, pageNew) => {
    let searchQuery = queryString.parse(searchLocation)
    const { limit, offset } = search
    const page = offset / limit
    if (page !== pageNew && event) {
      searchQuery.offset = limit * pageNew
      if (typeof searchQuery.limit === 'undefined') searchQuery.limit = limit
      history.push(`?${queryString.stringify(searchQuery)}`)
      window.scrollTo(0, 0)
    }
  };
  const handleChangeRowsPerPage = event => {
    let searchQuery = queryString.parse(searchLocation)
    if (Number(searchQuery.limit) !== Number(event.target.value)) {
      searchQuery.limit = event.target.value
      searchQuery.offset = 0
      history.push(`?${queryString.stringify(searchQuery)}`)
    }
  };
  const toLinkAdd = React.forwardRef((props, ref) => (
    <Link ref={ref} to={`${match.url}/add`} {...props} />
  ));
  const hideColumn = (id, add) => {
    if (add) {
      setArrHidden([...arrHidden, id])
    }
    else {
      setArrHidden(arrHidden.filter(e => id !== e))
    }
  }
  return (
    <React.Fragment>
      {title !== '' && <Typography variant='h6'>{title}</Typography>}
      <div className={classesPage.header}>
        {createItem && <Button component={toLinkAdd} color="primary" >Добавить</Button>}
        {headerLink}
        <div className={classesPage.grow} />
        <ActionSelected
          deleteItem={deleteItem}
          arrSelected={arrDelete.map(id => formTable.byId[id][idName])}
          handleDeleteMulti={handleDeleteMulti}
          history={history}
          match={match}
        />
        {csv && <PageListColumn
          csv={csv}
          columns={columns}
          arrHidden={arrHidden}
          hideColumn={hideColumn}
          additionalActions={additionalActions}
          name={name}
        />}
      </div>
      {headerObj}
      {filterProps.length > 0 && <FilterArray
        change={editFilterValue}
        arr={filterProps}
        name={name}
      />}
      <CustomTable2
        idName={idName}
        classes={classesPage}
        tableActions={{
          onDeleteRow: (deleteItem) ? handleDeleteOne : undefined,
          onClickRow: (click) ? linkURL : undefined,
          onClickFunction: onClickFunction,
          disabledDelete: disabledDelete,
          addToArrDelete: addToArrDelete,
          handleSelectAllClick: handleSelectAllClick,
          setSort: handleSort,
          ...tableActions
        }}
        tableHead={columns}
        tableData={formTable}
        history={history}
        search={{ limit: search.limit, page: search.offset / search.limit }}
        deleteMulti={deleteMulti}
        arrDeleteMulti={arrDelete}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        arrHidden={arrHidden}
        goBack={true}
        firstHistoryLength={firstHistoryLength}
        {...tableProps}
      />
    </React.Fragment>
  )
}


function ActionSelected({ arrSelected, handleDeleteMulti, history, match, deleteItem }) {
  const [open, changeOpen] = useState(false)
  const [anchorRef, changeRef] = useState(null)
  const dispatch = useDispatch()
  const addToForm = () => dispatch(formInitialize({ arr: arrSelected }, { name: 'materialSelected' }))
  const handleToggle = (e) => {
    changeOpen(!open)
    changeRef(!anchorRef ? e.currentTarget : null)
  }
  const handleClose = () => {
    changeOpen(false)
    changeRef(null)
  }
  const handleBuy = () => {
    addToForm();
    history.push(`/projects/${match.params.id}/shipment/materials/add`)
  }
  const handleOrderProvider = () => {
    addToForm();
    history.push(`/projects/${match.params.id}/order/provider/add`)
  }
  const handleOrder = () => {
    addToForm();
    history.push(`/projects/${match.params.id}/orders/my/add`)
  }
  return <React.Fragment>
    <Button onClick={() => { handleDeleteMulti(); handleClose(); }} disabled={arrSelected.length === 0} color="primary">Удалить</Button>
    <Button onClick={handleToggle} disabled={arrSelected.length === 0} color="primary">Действие</Button>
    <Menu
      id="fade-menu"
      anchorEl={anchorRef}
      keepMounted
      open={open}
      onClose={handleClose}
      TransitionComponent={Fade}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <MenuItem onClick={handleOrderProvider} >Создать документ заказ поставщику</MenuItem>
      <MenuItem onClick={handleBuy} >Создать документ закупки</MenuItem>
      <MenuItem onClick={handleOrder} >Создать документ давальческих материалов</MenuItem>
      <MenuItem onClick={() => { handleDeleteMulti(); handleClose(); }} disabled={!deleteItem}>Удалить</MenuItem>
    </Menu>
  </React.Fragment>
}