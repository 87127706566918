import React, { useEffect, useState } from 'react';
import { Button, Tabs, Tab, TableHead, Table, TableCell, TableRow, TableBody, TextField, Typography, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { typework, getStorageUnit, addStorageUnit, getResources } from '../../api/list'
import { getWorkById, updateWorkById, addWorkResources, updWorkResources, deleteWorkResources } from '../../api/work'
import { fetchDispath } from '../../functions/fetch'
import validateProps from '../../functions/validateProps'
import * as actions from '../../reducers/form';
import { enqueueSnackbar } from '../../reducers/notifier'
import SimpleAdd from '../../components/SimpleAdd'
import {
  loadAutocompliteAPI, initialAutocomplite, clearAutocomplite,
  addFilterAutocomplite, set as setAutocomplite, addFilterManyAutocomplite, dellFilterAutocomplite
} from '../../reducers/autocomplite'
import { makeStyles } from '@material-ui/core/styles';
import AutoComplite from '../../components/Autocomplite'
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import { formObjDelete, formArrayDelete, formObjChangeObj } from '../../reducers/form';

const formName = 'workEdit'
const arrResources = 'arrResources'

const getStorageUnitFromType = (type) => type === 'labor' ? 'чел.-ч' : 'маш.-ч'

export default function WorkEdit({ match, history }) {
  const dispatch = useDispatch()
  const arrProps = [
    { field: 'name', type: 'text', label: 'Название', validator: [value => value !== ''], errorText: 'Поле обязательно', },
    { field: 'number', type: 'text', label: 'Номер' },
    { field: 'count', type: 'text', label: 'Количество', textProps: { type: 'number', min: '0' }, validator: [value => value > 0], errorText: 'на не может быть меньше нуля' },
    { field: 'price', type: 'text', label: 'Цена', textProps: { type: 'number', min: '0' }, validator: [value => value >= 0], errorText: 'на не может быть меньше нуля' },
    {
      field: 'idStorageUnit', type: 'autoLoadCreate', label: 'Ед. изм.', textProps: {
        validator: [value => !!value], errorText: 'Поле обязательно',
        createAPI: addStorageUnit,
        createBody: 'name',
        path: getStorageUnit.path,
        meta: {
          field: 'storageUnit',
          value: 'id',
          labelFunc: (obj) => obj.name
        }
      }
    },
    {
      field: 'idType', type: 'autoLoadCreate', label: 'Тип', textProps: {
        createAPI: typework.addOne,
        createBody: 'name',
        path: typework.getList.path,
        meta: {
          field: 'typeWork',
          value: 'id',
          labelFunc: (obj) => obj.name
        }
      }
    }
  ]
  useEffect(() => {
    dispatch(initialAutocomplite({ field: arrResources }))

    return () => {
      dispatch(clearAutocomplite({ field: arrResources }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(loadAutocompliteAPI(getResources.path+'?all=1', {
      field: arrResources,
      value: 'id',
      hidden: ['type', 'price'],
      labelFunc: (obj) => ((obj.isFolder)?'папка:':'')+obj.name
    }))
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: match.params.idProject,
        idWork: match.params.id
      },
      progress: true,
      request: getWorkById,
    })).then(res => {
      let byId = {}
      const filterAdd = []
      const allIds = res.resources.map(e => {
        byId['id' + e.id] = { ...e, storageUnit: getStorageUnitFromType(e.type), value: { value: e.id + '', label: e.name, }, oldRow: true };
        filterAdd.push({ value: e.id + '', id: 'id' + e.id, })
        return 'id' + e.id
      })
      dispatch(actions.formInitialize({
        name: res.get.name,
        number: res.get.number || '',
        idType: res.get.idType,
        typeE: res.get.typeE,
        idStorageUnit: res.get.idStorageUnit,
        count: res.get.count,
        price: res.get.price,
        errorValidate: {
          name: false,
          count: false,
          price: false,
          idStorageUnit: false,
          idType: false
        },
        byId,
        allIds,
        idRow: 0
      }, { name: formName }))
      dispatch(setAutocomplite({ value: res.get.idType + '', label: res.get.typeW }, { field: 'typeWork' }))
      dispatch(setAutocomplite({ value: res.get.idStorageUnit + '', label: res.get.storageUnit }, { field: 'storageUnit' }))

      dispatch(addFilterManyAutocomplite(filterAdd, { field: arrResources }))

    }).catch(err => console.log(err))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch, match.params.id, match.params.idProject])
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const handleUpdate = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    const params = {
      name: values.name,
      number: values.number,
      idType: values.idType,
      count: values.count,
      price: values.price,
      idStorageUnit: values.idStorageUnit,
    }
    dispatch(actions.formSubmitAPI({
      props: params,
      url: updateWorkById.path({
        id: match.params.idProject,
        idWork: match.params.id
      }),
      method: updateWorkById.type,
      history: history
    }))
  }
  const [value, setValue] = useState(0)
  return <div className='App-paper' >
    <Tabs value={value} onChange={(e, v) => setValue(v)} indicatorColor='primary'>
      <Tab label='Основные параметры' />
      <Tab label='Ресурсы' />
    </Tabs>
    {value === 0 && <form autoComplete="off" onSubmit={handleUpdate}>
      <div style={{ padding: '16px' }}>
        <SimpleAdd formName={formName} arr={arrProps} title='Редактировать работу' />
      </div>
      <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
      <Button type="submit" color="primary">Сохранить </Button>
    </form>}
    {value === 1 && <React.Fragment>
      <ResourcesTable
        arrResources={arrResources}
        formName={formName}
        idProject={match.params.idProject}
        idWork={match.params.id}
      />
      <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
    </React.Fragment>
    }
  </div>
}


function ResourcesTable({ formName, arrResources, idProject, idWork }) {
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const { byId, allIds = [], idRow } = values
  const dispatch = useDispatch()
  const addRow = () => {
    const newIdRow = idRow + 1;
    const rowAdd = {
      storageUnit: '',
      value: null,
      oldRow: false,
      price: 0,
      count: 0
    }
    dispatch(actions.formObjAdd(rowAdd, { field: 'byId', id: 'id_new' + newIdRow, name: formName }))
    dispatch(actions.formArrayAdd('id_new' + newIdRow, { field: 'allIds', name: formName, end: true }))
    dispatch(actions.formChange(newIdRow, { field: 'idRow', name: formName }))
  }
  return <div>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: 10 }} padding={'none'}>
            <IconButton onClick={addRow}  ><AddIcon /></IconButton>
          </TableCell>
          {['Ресурс', 'Количество', 'Ед. изм.', 'Цена'].map(e => (<TableCell key={e}>{e}</TableCell>))}
        </TableRow>
      </TableHead>
      <TableBody>
        {allIds.map(row => {
          const obj = byId[row]
          return <Row key={row} obj={obj} row={row} arrResources={arrResources} idProject={idProject} idWork={idWork} />
        })}
      </TableBody>
    </Table>
  </div>
}


const useStylesSimpleCell = makeStyles({
  textField: {
    width: '95%',
  },
});

function SimpleCell({ id, field, type, value, formName, textProps = {}, handleBlur }) {
  const dispatch = useDispatch()
  const classes = useStylesSimpleCell()
  const handleChange = (e) => {
    dispatch(formObjChangeObj({ [field]: e.target.value }, { field: 'byId', id: id, name: formName }))

  }
  return <TableCell padding='none'>
    <TextField
      className={classes.textField}
      margin="dense"
      type={type}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      {...textProps}
    />
  </TableCell>
}

function Row({ obj, row, arrResources, idProject, idWork }) {
  const resourcesArr = useSelector(state => state.autocomplite[arrResources] || state.autocomplite.default)
  const dispatch = useDispatch()
  const changeMaterial = text => {
    if (obj.value) {
      dispatch(dellFilterAutocomplite(obj.value.value, { field: arrResources, id: row }))
    }
    dispatch(formObjChangeObj({
      storageUnit: getStorageUnitFromType(text.type),
      value: text,
      name: text.label
    }, { field: 'byId', id: row, name: formName }))
    dispatch(addFilterAutocomplite(text.value, { field: arrResources, id: row }))
  };
  const filterArr = resourcesArr.arr.filter(e => {
    return !(typeof e.select !== 'undefined' && e.select !== row)
  })
  const handleDelete = () => {
    dispatch(fetchDispath({
      param: { id: idProject, idWork: idWork, idResource: obj.value.value },
      progress: true,
      request: deleteWorkResources,
    })).then(e => {
      if (obj.value) {
        dispatch(dellFilterAutocomplite(obj.value.value, { field: arrResources, id: row }))
      }
      dispatch(formArrayDelete(row, { field: 'allIds', name: formName }));
      dispatch(formObjDelete({ id: row, field: 'byId', name: formName }));
    })
  }
  const handleSave = () => {
    dispatch(fetchDispath({
      param: { id: idProject, idWork: idWork },
      body: {
        idResource: obj.value.value,
        count: obj.count,
        price: obj.price,
        type: obj.value.type
      },
      progress: true,
      request: addWorkResources,
    })).then(e => {
      dispatch(formObjChangeObj({ oldRow: true }, { field: 'byId', id: row, name: formName }))
    })
  }
  const handleBlur = () => {
    if (obj.oldRow) {
      dispatch(fetchDispath({
        param: { id: idProject, idWork: idWork, idResource: obj.value.value },
        body: { price: obj.price, count: obj.count },
        progress: true,
        request: updWorkResources,
      }))
    }
  }
  return <TableRow>
    <TableCell style={{ width: 10 }} padding={'none'}>
      {obj.oldRow && <IconButton onClick={handleDelete}  ><DeleteIcon /></IconButton>}
      {!obj.oldRow && <IconButton onClick={handleSave}  ><SaveIcon /></IconButton>}
    </TableCell>
    <TableCell>
      {obj.oldRow && <Typography>{obj.name}</Typography>}
      {!obj.oldRow && <AutoComplite
        arr={filterArr}
        action={changeMaterial}
        value={obj.value}
        formName={formName}
      />
      }
    </TableCell>
    <SimpleCell
      formName={formName}
      field="count"
      type="number"
      value={obj.count}
      id={row}
      handleBlur={handleBlur}
    />
    <TableCell>{obj.storageUnit}</TableCell>
    <SimpleCell
      formName={formName}
      field="price"
      type="number"
      value={obj.price}
      id={row}
      handleBlur={handleBlur}
    />
  </TableRow>
}