import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, List, ListItem, ListItemText } from '@material-ui/core';
import AutoComplite from '../../../../components/Autocomplite'
import { useSelector, useDispatch } from 'react-redux';
import { formObjAdd, formArrayAdd, } from '../../../../reducers/form';
import { addFilterAutocomplite } from '../../../../reducers/autocomplite'
import { getTeamById } from '../../../../api/team'
import { fetchDispath } from '../../../../functions/fetch'

export default function DialogAddTeam({ formTeam, formName, idProject, formWorker }) {
  const [open, setOpen] = useState(false)
  const [idRow, setIdRow] = useState(0)
  const [worker, serWorker] = useState([])
  const dispatch = useDispatch()
  const workerArr = useSelector(state => state.autocomplite[formWorker] || state.autocomplite.default)
  const addTeam = () => {
    setOpen(false)
    if (worker.length === 0) return;
    const arrAdd = worker.map(e => String(e.id))
    const arrSelected = workerArr.arr.filter(e => typeof e.select !== 'undefined').map(e => e.value)
    let difference = arrAdd.filter(x => !arrSelected.includes(x)).map(e => workerArr.arr.find(obj => e === obj.value))
    if (difference.length === 0) return;
    let newRow = idRow
    difference.forEach(text => {
      newRow -= 1;
      dispatch(formObjAdd({
        position: text.position,
        idWorker: text.value,
        name: text.label,
        value: text,
      }, {
        field: 'wById',
        id: 'id' + newRow,
        name: formName
      }))
      dispatch(formArrayAdd('id' + newRow, {
        field: 'wAllIds', name: formName
      }))
      dispatch(addFilterAutocomplite(text.value, { field: formWorker, id: 'id' + newRow }))
    })
    setIdRow(newRow)
  }
  const editTeam = text => {
    dispatch(fetchDispath({
      param: {
        id: text.value
      },
      progress: true,
      request: getTeamById(idProject),

    })).then(res => {
      if (res.success) {
        serWorker(res.get.worker)
      }
    })
  }
  const teamArr = useSelector(state => state.autocomplite[formTeam] || state.autocomplite.default)
  return <React.Fragment>
    <Button color='primary' onClick={() => setOpen(true)}>Добавить бригаду</Button>
    <Dialog
      open={open}
      maxWidth='sm'
      keepMounted
      fullWidth
      scroll="paper"
      onClose={() => (setOpen(false))}
      aria-labelledby="alert-dialog-title"
      PaperProps={{ style: { height: 400 } }}
    >
      <DialogTitle id="alert-dialog-title">Добавить бригаду</DialogTitle>
      <DialogContent >
        <AutoComplite
          arr={teamArr.arr}
          action={editTeam}
          palaceHolder='Команда'
        />
        {
          <List component="nav" aria-label="main mailbox folders">
            {worker.map(obj => {
              return <ListItem
                key={obj.id}
              >
                <ListItemText primary={obj.name} />
              </ListItem>
            })}
          </List>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Отмена
        </Button>
        <Button onClick={addTeam} color="primary" autoFocus>
          Добавить
        </Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
}