import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, InputLabel, Input, FormHelperText, FormControl } from '@material-ui/core'
import { formChange } from '../reducers/form'
import AutocompliteWithLoad from './AutocompliteWithLoad'
import AutocompliteWithLoadCreate from './AutocompliteWithLoadCreate'
import Autocomplite from './Autocomplite'
import AutoCompliteCreateModal from './AutocompliteWithLoadCreateModal'

export default function SimpleAdd({
  formName,
  validField = 'errorValidate',
  arr = [],
  title,
  gridProps = {},
  children = null
}) {
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const validate = values[validField] || {}
  const handleChange = useCallback((value, field) => {
    dispatch(formChange(value, { field, name: formName }))
  }, [dispatch, formName])
  return <React.Fragment>
    {title && <Typography variant='h6' style={{ marginBottom: '8px' }}>{title}</Typography>}
    {children}
    <Grid container
      item
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
      xs={6}
      {...gridProps}
    >
      {arr.map((v, i) => {
        let field = null
        const onChange = (value, field) => {
          handleChange(value, field)
          if (v.change) v.change(value)
        }
        const handleBlur = () => {
          if (v.validator) {
            let check = false
            v.validator.forEach(func => {
              check = check || !func(values[v.field])
            })
            dispatch(formChange({ ...validate, [v.field]: check }, { field: validField, name: formName }))
          }
        }
        switch (v.type) {
          case 'text':
            field = <SimpleInput
              field={v.field}
              label={v.label}
              labelProps={v.labelProps}
              textProps={v.textProps}
              onChange={onChange}
              error={validate[v.field]}
              errorText={v.errorText}
              value={values[v.field]}
              handleBlur={handleBlur}
            />
            break;
          case 'autoLoadCreate':
            field = <AutoLoadCreate
              label={v.label}
              field={v.field}
              textProps={v.textProps}
              formName={formName}
              change={v.change}
              error={validate[v.field]}
              errorText={v.errorText}
              handleBlur={handleBlur}
            />
            break;
          case 'autoLoadCreateModal':
            field = <AutoLoadCreateModal
              label={v.label}
              field={v.field}
              textProps={v.textProps}
              formName={formName}
              change={v.change}
              error={validate[v.field]}
              errorText={v.errorText}
              handleBlur={handleBlur}
            />
            break;
          case 'autoLoad':
            field = <AutoLoad
              label={v.label}
              field={v.field}
              textProps={v.textProps}
              formName={formName}
              change={v.change}
              error={validate[v.field]}
              errorText={v.errorText}
              handleBlur={handleBlur}
            />
            break;
          case 'autoRedux':
            field = <AutoRedux
              label={v.label}
              field={v.field}
              textProps={v.textProps}
              change={v.change}
              formArr={v.textProps.formArr}
              handleChange={handleChange}
              error={validate[v.field]}
              errorText={v.errorText}
              handleBlur={handleBlur}
              valueSet={values["value" + v.field]}
            />
            break;
          case 'autoArr':
            field = <AutoArr
              onChange={handleChange}
              label={v.label}
              arr={v.textProps.arr}
              textProps={v.textProps}
              field={v.field}
              error={validate[v.field]}
              errorText={v.errorText}
              value={values[v.field]}
              valueSet={values["value" + v.field]}
              handleBlur={handleBlur}
              change={v.change}
            />
            break;
          case 'component':
            field = v.textProps.component(values[v.field], handleChange)
            break;
          case 'divider':
            field = <div />
            break;
          default:
            break;
        }
        return <Grid item xs={v.xs || 12} {...(v.bp || {})} key={i}>{field}</Grid>
      })}
    </Grid>
  </React.Fragment>
}

function AutoLoad({ field, label, textProps, change, formName, error, errorText, handleBlur }) {
  // dispatch(autoSet({ value: res.get.idParent+"", label: res.get.parent }, { field: meta.field })) set
  return <FormControl error={error} fullWidth>
    <AutocompliteWithLoad
      classes={{}}
      field={field}
      name={label}
      path={textProps.path}
      meta={textProps.meta}
      formName={formName}
      change={change}
      selectProps={{
        onBlur: () => handleBlur(),
        ...textProps.selectProps || {}
      }}
      textFieldProps={{
        error,
      }}
    />
    <FormHelperText id="component-error-text">{error ? errorText : ''}</FormHelperText>
  </FormControl>
}

function AutoLoadCreate({ label, field, textProps, formName, change, error, errorText, handleBlur, }) {
  return <FormControl error={error} fullWidth>
    <AutocompliteWithLoadCreate
      classes={{}}
      field={field}
      name={label}
      createAPI={textProps.createAPI}
      createBody={textProps.createBody}
      path={textProps.path}
      meta={textProps.meta}
      formName={formName}
      change={change}
      selectProps={{
        onBlur: () => handleBlur(),
        ...textProps.selectProps || {}
      }}
      textFieldProps={{
        error,
      }}
    />
    <FormHelperText id="component-error-text">{error ? errorText : ''}</FormHelperText>
  </FormControl>
}
function AutoLoadCreateModal({ label, field, textProps, formName, change, error, errorText, handleBlur, }) {
  return <FormControl error={error} fullWidth>
    <AutoCompliteCreateModal
      classes={{}}
      field={field}
      name={label}
      createAPI={textProps.createAPI}
      createBody={textProps.createBody}
      path={textProps.path}
      meta={textProps.meta}
      modal={textProps.modal}
      formName={formName}
      change={change}
      selectProps={{
        onBlur: () => handleBlur(),
        ...textProps.selectProps || {}
      }}
      textFieldProps={{
        error,
      }}
    />
    <FormHelperText id="component-error-text">{error ? errorText : ''}</FormHelperText>
  </FormControl>
}
function AutoArr({ onChange, change, label, arr = [], field, error, errorText, handleBlur, textProps, valueSet }) {
  // set "value"+field
  const handleChangeAction = (e) => {
    onChange(e.value + '', field)
    onChange(e, 'value' + field)
    if (change) change(e)
  }
  return <FormControl error={error} fullWidth>
    <Autocomplite
      palaceHolder={label}
      action={handleChangeAction}
      selectProps={{
        onBlur: () => handleBlur(),
        ...textProps.selectProps || {}
      }}
      textFieldProps={{
        error,
      }}
      arr={arr}
      value={valueSet}
    />
    <FormHelperText id="component-error-text">{error ? errorText : ''}</FormHelperText>
  </FormControl>
}

function AutoRedux({ label, field, change, formArr, handleChange, error, errorText, handleBlur, textProps, valueSet }) {
  const reduxArr = useSelector(state => state.autocomplite[formArr] || state.autocomplite)
  const handleChangeAction = (e) => {
    handleChange(e.value + '', field)
    handleChange(e, 'value' + field)
    if (change) change(e)
  }
  return <FormControl fullWidth>
    <Autocomplite
      palaceHolder={label}
      action={handleChangeAction}
      arr={reduxArr.arr || []}
      selectProps={{
        onBlur: () => handleBlur(),
        ...textProps.selectProps || {}
      }}
      textFieldProps={{
        error,
      }}
      value={valueSet}
    />
    <FormHelperText id="component-error-text">{error ? errorText : ''}</FormHelperText>
  </FormControl>
}

function SimpleInput({ error, label, labelProps, textProps, field, errorText, value = '', onChange, handleBlur }) {
  return <FormControl error={error} fullWidth>
    <InputLabel htmlFor={"editor" + field} {...labelProps}>{label}</InputLabel>
    <Input
      id={"editor" + field}
      value={value}
      onChange={(e) => {
        onChange(e.target.value, field)
      }}
      onFocus={e => { if (textProps && textProps.type === 'number') e.target.select() }}
      onBlur={() => handleBlur()}
      name={field}
      {...textProps}
    />
    <FormHelperText id="component-error-text">{error ? errorText : ''}</FormHelperText>
  </FormControl>
}