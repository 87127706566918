import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button, Grid, TextField, Typography,
  Divider, List, ListItem, ListItemText,
  IconButton, ListItemIcon
} from '@material-ui/core'
import { fetchDispath } from '../../functions/fetch'
import GridProps from '../../components/GridProps'
import moment from 'moment'
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import * as apiApp from '../../api/app_worker'


export default function WorkerMachineId({ match, history, location }) {
  const [mileageStart, setMileageStart] = useState(0)
  const [mileageEnd, setMileageEnd] = useState(0)
  const [dateStart, setDateStart] = useState(null)
  const [dateEnd, setDateEnd] = useState(null)
  const [diffTime, setDiffTime] = useState('')
  const [param, setParam] = useState({
    date: '',
    time: '',
    duration: '',
    model: '',
    govNumber: '',
    driver: '',
    typeM: '',
  })
  const dispatch = useDispatch({ match })

  useEffect(() => {
    dispatch(fetchDispath({
      param: { id: match.params.id, },
      progress: true,
      request: apiApp.getWaybillById,
    }))
      .then(res => {
        if (!res.success) return;
        setParam({
          date: moment(res.get.date).format('DD.MM.YYYY'),
          time: res.get.time,
          duration: res.get.duration,
          model: res.get.model,
          govNumber: res.get.govNumber,
          driver: `${res.get.surname} ${res.get.name} ${res.get.patronymic}`,
          typeM: res.get.typeM,
        })
        setDateStart(res.get.dateStart)
        setDateEnd(res.get.dateEnd)
        setMileageStart(res.get.mileageStart || 0)
        setMileageEnd(res.get.mileageEnd || 0)
        if (!res.get.dateStart) return;
        const diff = moment(new Date()).diff(res.get.dateStart);
        const duration = moment.duration(diff);
        const hrs = duration.hours(),
          mins = duration.minutes(),
          secs = duration.seconds();
        setDiffTime(`${hrs > 9 ? hrs : '0' + hrs}:${mins > 9 ? mins : '0' + mins}:${secs > 9 ? secs : '0' + secs}`)

      }).catch(err => console.log(err))
  }, [dispatch, match.params.id])
  const handleStart = () => {
    dispatch(fetchDispath({
      param: { id: match.params.id, },
      body: { mileageStart: mileageStart },
      progress: true,
      request: apiApp.updMachineStart,
    })).then(res => {
      setDateStart(new Date())
    }).catch(err => console.log(err))
  }
  const handleEnd = () => {
    dispatch(fetchDispath({
      param: { id: match.params.id, },
      body: { mileageEnd: mileageEnd },
      progress: true,
      request: apiApp.updMachineEnd,
    })).then(res => {
      setDateEnd(new Date())
    }).catch(err => console.log(err))
  }
  useEffect(() => {
    if (!dateStart) return;
    setTimeout(() => {
      const diff = moment(new Date()).diff(dateStart);
      const duration = moment.duration(diff);
      const hrs = duration.hours(),
        mins = duration.minutes(),
        secs = duration.seconds();
      setDiffTime(`${hrs > 9 ? hrs : '0' + hrs}:${mins > 9 ? mins : '0' + mins}:${secs > 9 ? secs : '0' + secs}`)
    }, 1000 * 60)
    return () => {
    }
  }, [dateStart, diffTime])
  const [selected, setSelected] = useState('main')
  const handleBack = () => {
    if (selected === 'main') {
      history.goBack()
    }
    else {
      setSelected('main')
    }
  }
  return <div style={{ padding: 10 }}>
    <Typography variant='h6' style={{ textAlign: 'center' }} >{param.govNumber}  {param.date}</Typography>
    <Button color="primary" onClick={handleBack}>Назад</Button>
    <Page selected={selected === 'main'}>
      <WorkerDriverMain selectItem={(e) => setSelected(e)} />
    </Page>
    <Page selected={selected === 'info'}>
      <Info
        model={param.model}
        govNumber={param.govNumber}
        date={param.date}
        time={param.time}
        duration={param.duration}
        dateStart={dateStart}
        dateEnd={dateEnd}
        mileageEnd={mileageEnd}
        mileageStart={mileageStart}
      />
    </Page>
    <Page selected={selected === 'question'}>
      <QuestionList idWaybill={match.params.id} />
    </Page>
    <Page selected={selected === 'task'}>
      <TaskList idWaybill={match.params.id} typeM={param.typeM} />
    </Page>
    <Page selected={selected === 'start'}>
      {!dateStart && <div style={{ padding: 10 }}>
        <TextField label="Значение одометра на старте" type='number' variant="outlined" fullWidth onChange={(e) => setMileageStart(e.target.value)} value={mileageStart} />
        <Button onClick={handleStart} color='primary'>Начать работу</Button>
      </div>}
      {dateStart && !dateEnd && <div style={{ padding: 10 }}>
        <Typography variant='h6' color='secondary'>Время с начала работы {diffTime}</Typography>
        <TextField label="Значение одометра в конце" type='number' variant="outlined" fullWidth onChange={(e) => setMileageEnd(e.target.value)} value={mileageEnd} />
        <Button onClick={handleEnd} color='primary'>Закончить работу</Button>
      </div>}
      {dateStart && dateEnd && <Typography variant='h6' color='secondary'>Работы завершены</Typography>}
    </Page>
  </div>
}

function Page({ selected, children }) {
  if (selected) {
    return children
  }
  else {
    return null
  }
}

const arrList = [
  { value: 'info', label: 'Информация' },
  { value: 'start', label: 'Начать/Завершить работу' },
  { value: 'question', label: 'Контрольные листы' },
  { value: 'task', label: 'Задачи' }
]
function WorkerDriverMain({ selectItem }) {
  return <List>
    {arrList.map(e => {
      return <ListItem button key={e.value} onClick={() => { selectItem(e.value) }} >
        <ListItemText primary={<Typography style={{ textAlign: 'center' }}>{e.label}</Typography>} />
      </ListItem>
    })}
  </List>
}

const column = [
  { id: 'order', type: 'text', name: 'в чье распоряжение' },
  { id: 'arrivalTime', type: 'text', name: 'время прибытия, ч. мин' },
  { id: 'loadingAddress', type: 'text', name: 'адрес погрузки' },
  { id: 'unloadingAddress', type: 'text', name: 'адрес разгрузки' },
  { id: 'cargoName', type: 'text', name: 'наименование груза' },
  { id: 'count', type: 'number', name: 'количество ездок' },
  { id: 'distance', type: 'number', name: 'расстояние, км' },
  { id: 'transported', type: 'number', name: 'перевезти, тонн' },
  { id: 'description', type: 'text', name: 'примечание' },
]
const columnSpec = [
  { id: 'order', type: 'text', name: 'в чье распоряжение' },
  { id: 'arrivalTime', type: 'time', name: 'время прибытия, ч. мин' },
  { id: 'typeWork', type: 'text', name: 'вид работы' },
  { id: 'description', type: 'text', name: 'примечание' },
]
const columnResult = [
  { id: 'count', type: 'number', name: 'количество ездок' },
  { id: 'distance', type: 'number', name: 'расстояние, км' },
  { id: 'transported', type: 'number', name: 'перевезти, тонн' },
]
const rowDefault = {
  key: '',
  id: '',
  idTask: '',
  count: 1,
  distance: '',
  transported: '',
  arrivalTime: '',
}
function TaskList({ idWaybill, typeM }) {
  const [result, setResult] = useState([])
  const [task, setTask] = useState([])
  const [indexTask, setIndexTask] = useState(0)
  const dispatch = useDispatch()
  const loadInfo = useCallback(() => {
    return dispatch(fetchDispath({
      param: { id: idWaybill, },
      progress: true,
      request: apiApp.getMachineTask,
    }))
      .then(res => {
        if (!res.success) return;
        setTask(res.task)
        setResult(res.taskResult.map(obj => ({
          key: 'id-' + obj.id,
          id: obj.id,
          idTask: obj.idTask,
          count: obj.countResult,
          distance: obj.distanceResult,
          transported: obj.transportedResult,
          arrivalTime: obj.arrivalTimeResult,
        })))
      }).catch(err => console.log(err))
  }, [dispatch, idWaybill])

  useEffect(() => {
    loadInfo()
  }, [loadInfo])

  const handleClick = (id) => {
    setResult(old => [...old, { ...rowDefault, idTask: id, key: 'id' + indexTask }])
    setIndexTask(old => (old + 1))
  }
  const fndObj = (key) => result.find(e => key === e.key);
  const findIndex = (key) => result.findIndex(e => key === e.key);
  const handleChange = (value, field, key) => {
    const i = findIndex(key);
    const arr = [...result];
    arr[i][field] = value;
    setResult(arr)
  }
  const handleSaveTaskRes = (key) => {
    const obj = fndObj(key);
    dispatch(fetchDispath({
      param: { id: idWaybill },
      body: {
        idTaskResult: obj.id,
        idTask: obj.idTask,
        arrivalTime: obj.arrivalTime,
        count: obj.count,
        distance: obj.distance,
        transported: obj.transported,
      },
      progress: true,
      request: apiApp.updMachineTaskResult,
    })).then(res => {
      if (res.success) {
        handleChange(res.idTaskResult, 'id', key)
      }
    }).catch(err => console.log(err))
  }
  const removeTaskResult = (key) => {
    const i = findIndex(key)
    setResult([
      ...result.slice(0, i),
      ...result.slice(i + 1, result.length)
    ]);
  }
  const handleDeleteTaskRes = (key) => {
    const obj = fndObj(key)
    if (obj.id === '') {
      removeTaskResult(key)
    }
    else {
      dispatch(fetchDispath({
        param: { id: idWaybill },
        body: {
          idTaskResult: obj.id,
        },
        progress: true,
        request: apiApp.removeMachineTaskResult,
      })).then(res => {
        if (res.success) {
          removeTaskResult(key)
        }
      }).catch(err => console.log(err))
    }
  }
  const handleClickFinishedStart = (id, isFinished) => {
    dispatch(fetchDispath({
      param: { id: idWaybill },
      body: {
        idTask: id,
        isFinished: isFinished,
      },
      progress: true,
      request: apiApp.updMachineTaskFinish,
    })).then(res => {
      if (res.success) {
        loadInfo()
      }
    }).catch(err => console.log(err))
  }
  const col = typeM === 'спец. автомобиль' ? columnSpec : column
  return <div>
    <Typography>Задание водителю</Typography>
    {task.map(e => {
      return <div className='App-paper' style={{ margin: 10 }} key={e.id}>
        {col.map(key => {
          return <Typography key={key.id}><b>{key.name}:</b> {e[key.id]}</Typography>
        })}
        <Button color='primary' disabled={e.isFinished} onClick={() => handleClick(e.id)}>Добавить результат</Button>
        {!e.isFinished && <Button color='primary' onClick={() => handleClickFinishedStart(e.id, true)}>Завершить</Button>}
        {e.isFinished && <Button color='primary' onClick={() => handleClickFinishedStart(e.id, false)}>Продолжить</Button>}
        {result.filter(s => s.idTask === e.id).map((res, i) => {
          if (e.isFinished) {
            return <div key={res.key}>
              <Divider />
              <Typography><b>№{i + 1}</b></Typography>
              <Typography><b>время прибытия, ч. мин:</b> {res.arrivalTime}</Typography>
              {typeM !== 'спец. автомобиль' && columnResult.map(key => {
                return <Typography key={key.id}><b>{key.name}:</b> {res[key.id]}</Typography>
              })}
            </div>
          }
          return <div key={res.key}>
            <Divider />
            <Typography><b>№{i + 1}</b></Typography>
            <TextField
              fullWidth
              label="время прибытия ч. мин."
              value={res.arrivalTime}
              type="time"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleChange(e.target.value, 'arrivalTime', res.key)} />
            {typeM !== 'спец. автомобиль' && <>
              <TextField
                fullWidth
                label="расстояние,км"
                value={res.distance}
                onChange={(e) => handleChange(e.target.value, 'distance', res.key)} />
              <TextField
                fullWidth
                label="перевезено, тонн"
                value={res.transported}
                onChange={(e) => handleChange(e.target.value, 'transported', res.key)} />
            </>}
            <Button color='primary' onClick={() => handleSaveTaskRes(res.key)}>Сохранить</Button>
            <Button color='secondary' onClick={() => handleDeleteTaskRes(res.key)}>Удалить</Button>
          </div>
        })}
      </div>
    })}
  </div>
}

function Info(param) {
  return <Grid
    container
    direction="column"
    justifyContent="flex-start"
    alignItems="flex-start"
  >
    <Grid item container xs={12}>
      <GridProps name='Модель транспорта:' value={param.model} />
      <GridProps name='Гос. номер:' value={param.govNumber} />
      <GridProps name='Дата:' value={param.date} />
      <GridProps name='Время:' value={param.time} />
      <GridProps name='Продолжительность:' value={`${param.duration} ч.`} />
      <GridProps name='Начало работы:' value={param.dateStart ? moment(param.dateStart).format('LLL') : ''} />
      <GridProps name='Конец работы:' value={param.dateEnd ? moment(param.dateEnd).format('LLL') : ''} />
      <GridProps name='Значение одометра на старте:' value={param.mileageStart} />
      <GridProps name='Значение одометра в конце:' value={param.mileageEnd} />
    </Grid>
  </Grid>
}


function QuestionList({ idWaybill }) {
  const [arr, setArr] = useState([])
  const [idSelect, setSelect] = useState(-1)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDispath({
      param: { id: idWaybill, },
      progress: true,
      request: apiApp.getMachineCheckList,
    })).then(res => {
      if (!res.success) return;
      setArr(res.get)
    }).catch(err => console.log(err))
    return () => {
    }
  }, [dispatch, idWaybill])
  const handleSelectId = (id, idChWB) => {
    if (idChWB) setSelect(idChWB)
    else {
      dispatch(fetchDispath({
        param: { id: idWaybill, idChl: id },
        progress: true,
        request: apiApp.startMachineCheckList,
      })).then(res => {
        if (!res.success) return;
        setSelect(res.get)
      }).catch(err => console.log(err))
    }
  }
  const handleFinished = () => {
    setSelect(-1)
    dispatch(fetchDispath({
      param: { id: idWaybill },
      progress: true,
      request: apiApp.getMachineCheckList,
    })).then(res => {
      if (!res.success) return;
      setArr(res.get)
    }).catch(err => console.log(err))
  }
  return <div>
    <Typography>Контрольные листы</Typography>
    {idSelect === -1 && <List>
      {arr.map(e => {
        return <ListItem key={e.id} button onClick={() => { handleSelectId(e.id, e.checklistWaybillId) }}>
          <ListItemIcon>
            {e.dateFinish && <CheckCircleOutlineIcon style={{ color: 'green' }} />}
            {!e.dateFinish && <RadioButtonUncheckedIcon style={{ color: 'blue' }} />}
          </ListItemIcon>
          <ListItemText>{e.name}</ListItemText>
        </ListItem>
      })}
    </List>}
    {idSelect !== -1 && <div>
      <Button onClick={() => setSelect(-1)} color="primary">К списку</Button>
      <QuestionOne idWaybill={idWaybill} id={idSelect} finished={() => handleFinished()} />
    </div>}
  </div>
};


function QuestionOne({ idWaybill, id, finished }) {
  const [arr, setArr] = useState([])
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(-1)
  const [currentQuestion, setCurrentQuestion] = useState({
    id: 1,
    questionNumber: 1,
    question: "",
    description: "",
    answerType: "checkbox",
    answerParam: {},
    idAnswer: null,
    answer: null
  })
  const [finish, setFinish] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    if (id === -1) return
    dispatch(fetchDispath({
      param: { id: idWaybill, idChWB: id },
      progress: true,
      request: apiApp.getCheckListWBId,
    })).then(res => {
      if (!res.success) return;
      setArr(res.get)
      if (res.get.length > 0) {
        setCurrentQuestionNumber(0)
        setCurrentQuestion(res.get[0])
      }
    }).catch(err => console.log(err))
    return () => {
    }
  }, [dispatch, idWaybill, id])
  const handleNext = (answerSet) => {
    if (answerSet) {
      const newArr = [...arr]
      newArr[currentQuestionNumber].answer = answerSet
      setArr(newArr)
    }
    if (arr.length === currentQuestionNumber + 1) setFinish(true)
    else {
      setCurrentQuestion(arr[currentQuestionNumber + 1])
      setCurrentQuestionNumber(e => e + 1)

    }
  }
  const handlePrev = () => {
    setCurrentQuestion(arr[currentQuestionNumber - 1])
    setCurrentQuestionNumber(e => e - 1)
  }
  const handleFinish = () => {
    dispatch(fetchDispath({
      param: { id: idWaybill, idChWB: id },
      progress: true,
      request: apiApp.updCheckListWBIdStop,
    })).then(res => {
      if (!res.success) return;
      finished()
    }).catch(err => console.log(err))
  }
  if (id === -1) return null
  if (arr.length === 0) return null
  if (finish) {
    return <div className='App-paper' style={{ margin: 10 }}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Grid><Typography><b>Вопросы закончились</b></Typography></Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>
            <Button onClick={() => { setFinish(false) }} variant="outlined" color="primary">Назад</Button>
          </Grid>
          <Grid>
            <Button onClick={handleFinish} variant="contained" color="primary">Завершить</Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  }
  return <div className='App-paper' style={{ margin: 10 }}>
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <Grid><Typography><b>{currentQuestion.question}</b></Typography></Grid>
      <Grid style={{ minHeight: 200, textAlign: 'left', width: '100%' }}><Typography>{currentQuestion.description}</Typography></Grid>
      <Grid><AnswerType
        idWaybill={idWaybill}
        idChWB={id}
        answerType={currentQuestion.answerType}
        answerParam={currentQuestion.answerParam}
        questionId={currentQuestion.id}
        answer={currentQuestion.answer}
        handleNext={(answerSet) => handleNext(answerSet)}
      /></Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid>
          <IconButton onClick={handlePrev} disabled={currentQuestionNumber === 0}>
            {currentQuestionNumber === 0 ? <FirstPageIcon /> : <ArrowBackIosIcon />}
          </IconButton>
        </Grid>
        <Grid>
          <IconButton onClick={() => handleNext()}>
            {currentQuestionNumber === arr.length - 1 ? <LastPageIcon /> : <ArrowForwardIosIcon />}
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  </div>
}


function AnswerType({ idWaybill, idChWB, answerType, answerParam, questionId, handleNext, answer }) {
  const dispatch = useDispatch()
  const handleAnswer = (answerSet) => {
    dispatch(fetchDispath({
      param: { id: idWaybill, idChWB: idChWB },
      body: {
        questionId,
        answer: answerSet
      },
      progress: true,
      request: apiApp.updCheckListWBIdAnswer,
    })).then(res => {
      if (!res.success) return;
      handleNext(answerSet)
    }).catch(err => console.log(err))
  }
  useEffect(() => {
    setAnswerNew(answer)
  }, [questionId,])
  const [answerNew, setAnswerNew] = useState('')
  switch (answerType) {
    case 'checkbox': {
      return <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid><Button onClick={() => handleAnswer('Да')} variant="outlined" color={answer === 'Да' ? "secondary" : "primary"}>Да</Button></Grid>
        <Grid><Button onClick={() => handleAnswer('Нет')} variant="outlined" color={answer === 'Нет' ? "secondary" : "primary"}>Нет</Button></Grid>
      </Grid>
    }
    case 'button': {
      return <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        {answerParam && answerParam.buttonArr && answerParam.buttonArr.map((e, i) => {
          return <Grid>
            <Button
              key={i}
              onClick={() => handleAnswer(e.name)}
              variant="outlined"
              color={answer === e.name ? "secondary" : "primary"}
            >{e.name}
            </Button>
          </Grid>
        })}
      </Grid>
    }
    case 'text': {
      return <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={10}><TextField
          fullWidth
          onChange={(e) => setAnswerNew(e.target.value)}
          value={answerNew}
          variant="outlined"
        /></Grid>
        <Grid item xs={2}><IconButton onClick={() => handleAnswer(answerNew)}>
          <ArrowForwardIosIcon />
        </IconButton></Grid>
      </Grid>
    }
    default:
      return null;
  }
}