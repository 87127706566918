import React, { useState, useEffect } from 'react'
import { TableRow, TableCell, IconButton, TextField, Tooltip, } from '@material-ui/core'
import * as actions from '../reducers/form';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux'
import AutoComplite from './Autocomplite'
import { addFilterAutocomplite, dellFilterAutocomplite } from '../reducers/autocomplite'

export default function DocRow({ id, obj, formName, column, allIdsField, byIdField }) {
  const dispatch = useDispatch()
  const deleteRow = () => {
    column.filter(e => e.type === 'auto' && e.filtered).forEach(e => {
      if (obj[e.id]) {
        dispatch(dellFilterAutocomplite(obj[e.id].value, { field: e.formArr, id: id }))
      }
    })
    dispatch(actions.formArrayDelete(id, { field: allIdsField, name: formName }))
    dispatch(actions.formObjDelete({ id: id, field: byIdField, name: formName }))
  }
  return <TableRow>
    <TableCell style={{ width: 10 }} padding={'none'}>
      <IconButton onClick={deleteRow}  ><DeleteIcon /></IconButton>
    </TableCell>
    {column.map(e => {
      switch (e.type) {
        case 'string':
          return <TableCell key={id + '' + e.id} style={{ width: e.width ? e.width : undefined }}>{obj[e.id]}</TableCell>
        case 'time':
        case 'text':
        case 'number':
          return <SimpleCell
            key={id + '' + e.id}
            id={id}
            formName={formName}
            field={e.id}
            title={e.title ? e.title(obj) : undefined}
            type={e.type}
            value={obj[e.id]}
            textProps={(e.textProps ? e.textProps(obj) : {})}
            byIdField={byIdField}
            width={e.width}
            change={e.change}
            obj={obj}
          />
        case 'auto':
          return <AutoCell
            key={id + '' + e.id}
            id={id}
            formArr={e.formArr}
            filtered={e.filtered}
            value={obj[e.id]}
            formName={formName}
            changeValue={e.changeValue}
            byIdField={byIdField}
            width={e.width}
            grouped={e.grouped}
          />
        case 'component':
          return e.component({ id, obj, value: obj[e.id], formName, byIdField, ...e.componentProps })
        default:
          break;
      }
      return null//<TableCell>d</TableCell>
    })}
  </TableRow>
}


function SimpleCell({ id, field, title, type, value, formName, textProps = {}, byIdField, width, change, obj }) {
  const dispatch = useDispatch()
  const handleChange = (e) => {
    if (change) {
      change({ value: e.target.value, id: id, obj: obj })
    }
    dispatch(actions.formObjChangeObj({ [field]: e.target.value }, { field: byIdField, id: id, name: formName }))
  }
  const handleKeyPress = (e) => {
    var keyCode = e.keyCode ? e.keyCode : e.which;
    if (keyCode !== 65 || typeof title === 'undefined') return;
    else handleChange({ target: { value: Number(title) || 0 } })
  }
  const Text = <TextField
    fullWidth
    margin="dense"
    type={type}
    value={value}
    onChange={handleChange}
    onKeyUp={handleKeyPress}
    onFocus={e => { if (type === 'number') e.target.select() }}
    {...textProps}
  />
  return <TableCell padding='none' style={{ width: width ? width : undefined }}>
    {title
      ? <Tooltip title={title} placement="bottom" >
        {Text}
      </Tooltip>
      : Text
    }
  </TableCell>
}

function AutoCell({ id, formArr, filtered = true, value, formName, changeValue, byIdField, width, grouped }) {
  const materialArr = useSelector(state => state.autocomplite[formArr] || state.autocomplite.default)
  const valueExist = typeof value?.value==='undefined'
  const [edit, changeEdit] = useState(valueExist)
  useEffect(()=>{
    changeEdit(valueExist)
  },[valueExist])
  const dispatch = useDispatch()
  let filterArr = !filtered ? materialArr.arr : materialArr.arr.filter(obj => {
    return !(typeof obj.select !== 'undefined' && obj.select !== id)
  })
  if (grouped) {
    const groupObj = JSON.parse(JSON.stringify(materialArr.group))
    filterArr.forEach(e => {
      if (groupObj[e.groupKey]) {
        groupObj[e.groupKey].options.push(e)
      }
    })
    filterArr = Object.values(groupObj)
  }
  const handleChange = text => {
    if (filtered && value) {
      dispatch(dellFilterAutocomplite(value.value, { field: formArr, id: id }))
    }
    dispatch(actions.formObjChangeObj(changeValue(text), { field: byIdField, id: id, name: formName }))
    if (filtered) dispatch(addFilterAutocomplite(text.value, { field: formArr, id: id }))
    changeEdit(false)
  }
  if (value && !edit) {
    return <TableCell style={{ width: width ? width : undefined }}    >
      <div onClick={() => changeEdit(true)}>
        {value.label}
      </div>
    </TableCell>
  }
  return <TableCell>
    <AutoComplite
      arr={filterArr}
      action={handleChange}
      value={value}
      palaceHolder=''
    />
  </TableCell>
}