import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../reducers/form'
import * as actions2 from '../../reducers/formTable';
import Grid from '@material-ui/core/Grid'
import { fetchDispath } from '../../functions/fetch'
import { getAnalyticsMaterialById } from '../../api/analytics'
import AutoCompliteWithLoad from '../../components/AutocompliteWithLoad'
import { Button, Tabs, Tab } from '@material-ui/core';
import { getMaterials, getMaterialById } from '../../api/material'
import MaterialPrice from './source/AnalitycMaterialPrice'
import MaterialStorage from './source/AnalitycMaterialStorage'
import moment from 'moment'
import 'moment/locale/ru';
import { nds } from '../../enum/nds.enum'


const formName = 'AnalyticMaterial'
const formPrice = 'price'
const formStorage = 'storage'
export default function AnalitycMaterial ({ match }) {
  const [value, setValue] = useState(0)
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  useEffect(() => {
    if(values.idMaterial) return {}
    dispatch(actions.formInitialize({
      idMaterial: 0,
      balance: 0,
      count: 0,
      costs: 0,
      storageUnit: '',
      storage: 0,
      price: 0,
      sumPrice: 0,
      avgPrice: 0,
      countPrice: 0,
      linePrice: [],
      arrPrice: [],
    }, { name: formName }))
    dispatch(actions2.initFormTable({ name: formPrice }))
    dispatch(actions2.initFormTable({ name: formStorage }))
    // return () => {
    //   dispatch(actions.formDestroy({ name: formName }))
    //   dispatch(actions2.clearFormTable(formPrice))
    //   dispatch(actions2.clearFormTable(formStorage))
    // }
  }, [dispatch,values.idMaterial])
  const load = () => {
    if (!values.idMaterial) return;
    dispatch(fetchDispath({
      progress: true,
      request: getAnalyticsMaterialById,
      param: {
        id: match.params.idProject,
        idMaterial: values.idMaterial
      }
    })).then(res => {
      if (res.success) {
        let smmPrice = 0
        let smmCount = 0
        const arrPrice = [];
        const linePrice = []
        const price = res.price.map((obj, index) => {
          smmPrice += obj.price * obj.count
          smmCount += obj.count
          arrPrice.push(obj.price)
          linePrice.push(moment(obj.date).format('YYYY-MM-DD'))
          return { 
            ...obj, 
            date:  moment(obj.date).format('DD.MM.YYYY'),
            estimate: obj.estimate ? obj.estimate : obj.name,
            nds_count: Number(obj.sum) + Number(obj.nds_count),
            nds: nds.find(e => e.value === (obj.nds || 'none')).label,
            idDoc: obj.id,
            id: index
           }
        })
        const storage = res.storage.map((obj, index) => ({ ...obj, id: index }))
        dispatch(actions2.loadFormTable({ get: price, count: price.length }, { name: 'price', id: 'id' }))
        dispatch(actions2.loadFormTable({ get: storage, count: storage.length }, { name: 'storage', id: 'id' }))
        const dataForm = {
          sumPrice: smmPrice,
          avgPrice: smmPrice / smmCount,
          countPrice: smmCount,
          linePrice,
          arrPrice
        }
        dispatch(actions.formChangeAsObj(dataForm, { name: formName }))
      }
    }).catch(err => console.log(err))
    dispatch(fetchDispath({
      param: {
        id: match.params.idProject,
        idMaterial: values.idMaterial
      },
      progress: true,
      request: getMaterialById,
    })).then(res => {
      if (res.success) {
        const dataForm = {
          balance: res.get.balance,
          count: res.get.count,
          costs: res.get.costs,
          storageUnit: res.get.storageUnit,
          storage: res.get.storage,
          price: res.get.price,
        }
        dispatch(actions.formChangeAsObj(dataForm, { name: formName }))
      }

    }).catch(err => console.log(err))
  }
  return (<div className='App-paper'>
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-end"
    >
      <Grid item >
        <Button onClick={load} color='primary'>Сформировать</Button>
      </Grid>
      <Grid item xs={9}>
        <AutoCompliteWithLoad
          classes={{}}
          field='idMaterial'
          name='Материал'
          path={getMaterials.path({ id: match.params.idProject })}
          meta={{
            field: 'material',
            value: 'id',
            labelFunc: (obj) => obj.name + ' ' + obj.typeMark + ' ' + obj.vendorCode
          }}
          formName={formName}
        />
      </Grid>
    </Grid>
    <Tabs value={value} onChange={(e, v) => setValue(v)} indicatorColor='primary'>
      <Tab label="Цена" />
      <Tab label="Склад" />
    </Tabs>
    {value === 0 && <MaterialPrice formName={formName} formPrice={formPrice} idProject={match.params.idProject}/>}
    {value === 1 && <MaterialStorage formName={formName} formStorage={formStorage}/>}
  </div>
  )
}