import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import Autocomplite from './Autocomplite'
import { IconButton, InputAdornment } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear';

import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme) => ({
  adornedEnd: {
    color: "hsl(0, 0%, 80%)",
    "&:hover": { color: 'hsl(0, 0%, 60%)' }
  },
}));
export default function FilterDate({
  filter, onChange, changeDate, filterDate
}) {
  const classes = useStyles();
  return <div >
    <Grid container spacing={2} alignItems='flex-end'>
      <Grid xs={3} item>
        <Autocomplite
          selectProps={{
            style: { width: '100%' },
            isClearable: true
          }}
          palaceHolder='Группировка'
          action={(e) => onChange(e?.value)}
          arr={[{ value: 'year', label: 'Год' }, { value: 'day', label: 'День' }, { value: 'month', label: 'Месяц' }]}
        />
      </Grid>
      <Grid item>
        {(filter === "year") ?
          <TextField
            id="date"
            name='date'
            label='Год'
            type="number"
            value={filterDate}
            inputProps={{
              min: 2018,
              max: 2099,
              step: 1
            }}
            InputProps={{
              endAdornment: (<InputAdornment position="end">
                <IconButton size="small" className={classes.adornedEnd}
                  onClick={(e) => changeDate({ ...e, target: { value: '' } })}
                ><ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>)
            }}
            onChange={changeDate}
          />
          : <TextField
            id="date"
            name='date'
            type={(filter === "month") ? "month" : "date"}
            label={(filter === "month") ? "Месяц" : "Дата"}
            value={filterDate}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (<InputAdornment position="end">
                <IconButton size="small"  className={classes.adornedEnd}
                  onClick={(e) => changeDate({ ...e, target: { value: '' } })}
                ><ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>),
            }}
            onChange={changeDate}

          />}
      </Grid>
    </Grid>
  </div>
}