// @ts-check
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton, MenuItem, Fade, Menu, Dialog,
  DialogTitle, DialogContent, List, ListItemText, ListItem,
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { useSelector } from 'react-redux';
import download from '../functions/download'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  profileButton: {
    position: 'relative',
    marginRight: '10%',
    marginLeft: 'inherit',
    color: "inherit",
  },
}));
/** 
* @param {import("./types/PageListFilterColumn").PageListFilterColumn} param0 
*/
export default function PageListFilterColumn({ csv, columns, arrHidden, hideColumn, additionalActions = [], name }) {
  const classes = useStyles()
  const [openDialog, changeOpenDialog] = useState(false)
  return <div className={classes.root}>
    <MenuButton
      classes={classes}
      openDialog={() => changeOpenDialog(true)}
      csv={csv}
      additionalActions={additionalActions}
      columns={columns}
      name={name}
    />
    <DialogColumn columns={columns} open={openDialog} onClose={() => changeOpenDialog(false)} arrHidden={arrHidden} hideColumn={hideColumn} />
  </div>
}
/** 
* @param {import("./types/PageListFilterColumn").MenuButton} param0 
*/
function MenuButton({ classes, openDialog, additionalActions, csv, columns, name }) {
  const [open, changeOpen] = useState(false)
  const [anchorRef, changeRef] = useState(null)
  /** @type (e:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>void */
  const handleToggle = (e) => {
    changeOpen(!open)
    changeRef(!anchorRef ? e.currentTarget : null)
  }
  const handleClose = () => {
    changeOpen(false)
    changeRef(null)
  }
  const allIds = useSelector(state => state.formTable[name]?.allIds || [])
  const byId = useSelector(state => state.formTable[name]?.byId)
  const handleClickCSV = () => {
    download(allIds, byId, columns.map(obj => obj.id), columns.map(obj => obj.name), name + '.csv')
    handleClose()
  }
  return <>
    <IconButton
      className={classes.profileButton}
      aria-label="Настройки таблицы"
      aria-controls="menu-setting-table"
      aria-haspopup="true"
      onClick={handleToggle}
      color="inherit"
    >
      <SettingsIcon />
    </IconButton>
    <Menu
      id="menu-setting-table"
      anchorEl={anchorRef}
      keepMounted
      open={open}
      onClose={handleClose}
      TransitionComponent={Fade}
    >
      {csv && <MenuItem onClick={handleClickCSV} >Скачать таблицу</MenuItem>}
      <MenuItem onClick={() => { openDialog(); handleClose(); }} >Колонки</MenuItem>
      {additionalActions.map((v, i) => {
        return <MenuItem key={i} onClick={() => { v.action(); handleClose(); }} >{v.name}</MenuItem>
      })}
    </Menu>
  </>
}
/** 
* @param {import("./types/PageListFilterColumn").DialogColumn} param0 
*/
function DialogColumn({ columns, open, onClose, arrHidden, hideColumn }) {
  return (
    <Dialog
      open={open}
      maxWidth='sm'
      keepMounted
      fullWidth
      scroll="paper"
      onClose={onClose}
      aria-labelledby="column-dialog-title"
      PaperProps={{ style: { height: 400 } }}
    >
      <DialogTitle id="column-dialog-title">Скрыть колонки</DialogTitle>
      <DialogContent >
        <List component="nav" aria-label="Список колонок">
          {columns.map(obj => {
            const selected = arrHidden.includes(obj.id)
            return <ListItem
              selected={selected}
              key={obj.id}
              button
              onClick={() => selected ? hideColumn(obj.id, false) : hideColumn(obj.id, true)}
            >
              <ListItemText primary={obj.name} />
            </ListItem>
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
}