import React from 'react';
import * as api from '../../../../api/shipment'
import PageList from '../../../../components/PageListAPI'
import getStatus from '../../../../functions/statusDoc'

/**
 * @type {import("../../../../components/types/PageListAPI").Columns[]} 
 */
const head = [
  { id: 'id', name: '№' },
  { id: 'date', name: 'Дата создания', type: 'date' },
  { id: 'provider', name: 'Поставщик', },
  { id: 'user', name: 'Добавил', },
  { id: 'sum', name: 'Сумма', type: 'price' },
  {
    id: 'paidStatusRus', name: 'Статус оплаты', type: 'component',
    component: ({ obj }) => <div style={{ color: obj.paidStatus === 'paid' ? undefined : '#C51010' }}>{obj.paidStatusRus}</div>
  },
  { id: 'statusRus', name: 'Статус доставки', type: 'component',
    component: ({ obj }) => <div style={{ color: obj.allDelivery ? undefined : '#C51010' }}>{obj.statusRus}</div>
   },
  { id: 'dateDelivery', name: 'Дата доставки', type: 'date', formatDate: 'DD.MM.YYYY' },
]
export default function ShipmentMaterialList({ history, match, location }) {
  return <div className='App-paper'>
    <PageList
      name='shipmentMaterialProject'
      title='Документы закупки'
      columns={head}
      createItem={true}
      deleteItem={true}
      idName='id'
      history={history}
      match={match}
      location={location}
      loadAPI={{
        querty: {
          idProject: match.params.idProject
        },
        request: api.loadShipmenMaterial,
        transform: (res => {
          return {
            count: res.count,
            get: res.get.map(obj => {
              return { ...obj, statusRus: getStatus(obj.allDelivery), paidStatusRus: (obj.paidStatus === 'paid') ? 'Оплачено' : 'Не оплачено' }
            })
          }
        }),
      }}
      deleteAPI={api.deleteShipmentMaterial}
      headerInit={{
        sortBy: 'date',
        sortDirection: 'desc',
      }}
      disabledDelete={(obj) => obj.related}
      filterProps={[
        {
          arr: [{ value: '1', name: 'Оплачено' }, { value: '0', name: 'Не оплачено' }],
          field: 'paid',
          name: 'Статус оплаты',
          type: 'select'
        },
        {
          arr: [{ value: '1', name: 'Доставлено' }, { value: '0', name: 'Заказано' }],
          field: 'delivery',
          name: 'Статус доставки',
          type: 'select'
        },
        {
          field: 'date',
          name: 'Дата создания',
          type: 'date'
        }
      ]}
    />
  </div>
}

