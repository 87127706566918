import template from "./template_crud";
// Material
const shipment = '/shipment'
export const loadShipmenMaterial = template.getList(shipment);
export const getShipmentMaterialNotTable = template.getById(shipment, '/nottable');
export const deleteShipmentMaterial = template.deleteById(shipment);
export const getShipmentMaterialById = template.getById(shipment);
export const printShipmentMaterialById = template.getById(shipment, '/print');
export const addShipmenMaterialById = {
  path: (params)=>`/shipment/${params.id}`,
  type: 'POST'
}
export const deleteShipmentMaterialByIdRow = (id)=>({
  path: (params)=>`/shipment/${id}/${params.id}`,
  type: 'DELETE'
})
export const putPaidStatusShipmentById = {
  path: (params)=>`/shipment/${params.id}/paid`,
  type: 'PUT'
}
export const putFileShipmentById = {
  path: (params)=>`/shipment/${params.id}/file`,
  type: 'PUT'
}
export const addShipmentMaterial = template.addOne(shipment);
// Consumables
const equipment = '/shipment/equipment'; 
export const loadShipmenConsumables = template.getList(equipment);
export const loadShipmenConsumablesById = template.getById(equipment);
export const addShipmenConsumablesById = {
  path: (params) => `/shipment/equipment/${params.id}`,
  type: 'POST'
}
export const addShipmenConsumables = template.addOne(equipment);
export const deleteShipmentConsumables = template.deleteById(equipment);
//Rent
const rent = '/shipment/rent';
export const loadRentEquipment = template.getList(rent);
export const loadRentEquipmentWriteOf = template.getList(rent+'/list');
export const getRentEquipmentWriteOfById = template.getById(rent+'/list');
export const writeOfByIdRentEquipment = template.deleteById(rent+'/list')
export const addShipmentRent = template.addOne(rent);