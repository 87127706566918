import React, { useState } from 'react'
import { Typography, Checkbox, FormControlLabel, Button, Grid, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { formSubmitAPI } from '../../reducers/form';
import { copyProject } from '../../api/projects'
import AutocompliteWithLoad from '../../components/AutocompliteWithLoad'
import { loadProject } from '../../api/projects'

export default function ProjectCopy({ idProject, history }) {
  const dispatch = useDispatch()
  const [material, setMaterial] = useState(true)
  const [work, setWork] = useState(true)
  const [estimate, setEstimate] = useState(true)
  const [copyIdProject, setIdProject] = useState('')
  const handleCopy = () => {
    if (copyIdProject === '') return;
    dispatch(formSubmitAPI({
      props: {
        material: Number(material),
        work: Number(work),
        estimate: Number(estimate),
        copyIdProject: Number(copyIdProject)
      },
      url: copyProject.path({ id: idProject }),
      method: copyProject.type,
      // history: history
    }, 'default'))
  }
  return <div>
    <Typography variant='h6'>Копировать данные из проекта</Typography>
    <div style={{ padding: '16px' }}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid container
          item
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          xs={6}>
          <FormControl fullWidth>
            <AutocompliteWithLoad
              classes={{}}
              form={false}
              field='idProject'
              name='Проект'
              path={loadProject.path}
              meta={{
                field: 'progects',
                value: 'id',
                labelFunc: (obj) => `${obj.facility} ${obj.name}`
              }}
              formName={"formName"}
              change={(e) => {
                setIdProject(e.value)
              }}
            />
          </FormControl>
        </Grid>
        <CheckboxOne label='Копировать материалы' value={material} setValue={setMaterial} />
        <CheckboxOne label='Копировать работу' value={work} setValue={setWork} />
        <CheckboxOne label='Копировать смету' value={estimate} setValue={setEstimate} />
      </Grid>
    </div>
    <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
    <Button color="primary" onClick={handleCopy}>Копировать</Button>

  </div>
}

const useStyle = makeStyles(theme => ({
  formControl: {
    minWidth: 240,
    marginTop: 5,
    marginBottom: 3,
    width: '50%'
  },
}));

function CheckboxOne({ label, value, setValue }) {
  const classes = useStyle()
  return <Grid item>
    <FormControlLabel
      className={classes.formControl}
      control={
        <Checkbox
          checked={value}
          onChange={(e) => { setValue(e.target.checked) }}
          color="primary"
        />
      }
      label={label}
    />
  </Grid>
}