import React from 'react'
import Button from '@material-ui/core/Button';
import { withStyles, Typography, TextField, Drawer } from '@material-ui/core';
import { connect } from 'react-redux';
import * as actions from '../../reducers/form';
import { updateMaterialStorage } from '../../api/material'
import { getStorageUnit, addStorageUnit } from '../../api/list'
import { fetchDispath } from '../../functions/fetch'
import AutoCompliteCreate from '../../components/AutocompliteWithLoadCreate'
import { set as setAutocomplite } from '../../reducers/autocomplite'
import { Grid } from '@material-ui/core'
const styles = theme => ({
  main: {
    maxWidth: 320,
    margin: theme.spacing(2),
  }
})
class MaterialChangeStorageUnit extends React.Component {
  constructor(props) {
    super()
    this.state = { formName: 'MaterialChangeStorageUnit' }
    props.actions.formInitialize({ coef: 0, idStorageUnit: null })
  }
  changeCoef = (e) => {
    this.props.actions.formChange(e.target.value, { field: 'coef' })
  }
  saveChange = () => {
    const { formName } = this.state
    const { form } = this.props
    const { values } = form[formName] || form.default
    this.props.actions.formSubmit({
      props: {
        idStorageUnit: values.idStorageUnit,
        coef: values.coef
      },
      url: updateMaterialStorage.path({
        id: this.props.idProject,
        idMaterial: this.props.idMaterial
      }),
      method: updateMaterialStorage.type,
      endFunc: this.props.close
    })
  }
  render() {
    const { classes, storageUnit, open, close } = this.props
    const { formName } = this.state
    const { form } = this.props
    const { values } = form[formName] || form.default
    return <Drawer open={open} onClose={close} anchor="right">
      <div className={classes.main}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={8}
        >
          <Grid item>
            <Typography variant='h6'>Изменить еденицу хранения</Typography>
          </Grid>
          <Grid item>
            <Typography>Старое значение: {storageUnit}</Typography>
          </Grid>
          <Grid item>
            <AutoCompliteCreate
              classes={classes}
              field='idStorageUnit'
              name='Еденица хранения'
              path={getStorageUnit.path}
              createAPI={addStorageUnit}
              createBody='name'
              meta={{
                field: 'idStorageUnit',
                value: 'id',
                labelFunc: (obj) => obj.name
              }}
              formName={formName}
            />
          </Grid>
          <Grid item>
            <TextField
              value={values.coef}
              onChange={this.changeCoef}
              label='коэффициент'
              fullWidth
            />
          </Grid>
          <Grid item>
            <Typography color='error'>Внимание! При изменениии автоматически скорректируются цена и количество. Если есть докуметны, то изменения не сохранятся</Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={8}
          >
            <Grid item>
              <Button color="primary" className={classes.btn} onClick={(e) => close()}>Отменить</Button>
            </Grid>
            <Grid item>
              <Button color="primary" className={classes.btn} onClick={this.saveChange}>Сохранить</Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Drawer >
  }
}


const mapStateToProps = store => {
  return {
    form: store.form
  }
}
const mapDispatchToProps = dispatch => {
  return {
    actions: {
      formDestroy: () => dispatch(actions.formDestroy({ name: 'MaterialChangeStorageUnit' })),
      formInitialize: obj => dispatch(actions.formInitialize(obj, { name: 'MaterialChangeStorageUnit' })),
      formSubmit: (obj) => dispatch(actions.formSubmitAPI(obj, 'MaterialChangeStorageUnit')),
      formChange: (obj, meta) => dispatch(actions.formChange(obj, { ...meta, name: 'MaterialChangeStorageUnit' })),
      fetchDispath: obj => dispatch(fetchDispath(obj)),
      setAutocomplite: (obj, meta) => dispatch(setAutocomplite(obj, meta)),
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MaterialChangeStorageUnit));