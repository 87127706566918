import React from 'react'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { fetchDispath } from '../../functions/fetch'
import { getWorkerAttend } from '../../api/project-worker'
import { loadFormTable, clearFormTable, initFormTable } from '../../reducers/formTable';
import moment from 'moment'
import TableA from './source/PWATableA'
import TableNA from './source/PWATableNA'
import DriverNA from './source/PWADriver'
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button'
const tablePWA = 'project-workrer-a'
const tablePWNA = 'project-workrer-na'
class ProjectWorkerAttend extends React.Component {
  constructor(props) {
    super(props)
    props.actions.init({ name: tablePWA })
    props.actions.init({ name: tablePWNA })
    this.state = {
      date: moment(new Date()).format('YYYY-MM-DD'),
      open: false,
      id: '',
      snp: ''
    }
    this.loadTable(this.state.date)
  }
  loadTable(date) {
    if (date === '') return 1;
    this.props.actions.fetchDispath({
      progress: true,
      request: getWorkerAttend,
      param: { id: this.props.match.params.idProject },
      querty: { date }
    }).then(res => {
      if (res.success) {
        let arrA = []
        let arrNA = []
        res.get.forEach(obj => {
          if (obj.state) {
            arrA.push(obj);
          }
          else {
            arrNA.push(obj);
          }
        })
        this.props.actions.load({ get: arrA, count: arrA.length }, { name: tablePWA, id: 'id' })
        this.props.actions.load({ get: arrNA, count: arrNA.length }, { name: tablePWNA, id: 'id' })
      }
    }).catch(err => console.log(err))
  }
  changeDate = (value, name) => {
    if (this.state[name] !== value) {
      this.setState({ [name]: value })
      this.loadTable(value)
    }
  }
  onCloseDriver = (state) => {
    this.setState({ open: false })
    if (state) this.loadTable(this.state.date)
  }
  selectWorker = (obj) => {
    this.setState({ id: obj.id, snp: obj.snp, open: true })
  }
  componentWillUnmount() {
    this.props.actions.clear(tablePWA)
    this.props.actions.clear(tablePWNA)
  }
  render() {
    return (
      <div className='App-paper'>
        <Typography variant='h6'>Учет присутствия работников</Typography>
        <DriverNA
          date={this.state.date}
          id={this.state.id}
          snp={this.state.snp}
          onClose={this.onCloseDriver}
          open={this.state.open}
          idProject={this.props.match.params.idProject}
        />
        <TextField
          type="date"
          label='Дата'
          value={this.state.date}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => this.changeDate(e.target.value, 'date')}
        />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item>
            <TableNA selectWorker={this.selectWorker} />
          </Grid>
          <Grid item>
            <TableA />
          </Grid>
        </Grid>
      <Button color='primary' component="span" onClick={() => this.props.history.goBack()}>Назад</Button>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      fetchDispath: obj => dispatch(fetchDispath(obj)),
      load: (obj, meta) => dispatch(loadFormTable(obj, meta)),
      clear: obj => dispatch(clearFormTable(obj)),
      init: obj => dispatch(initFormTable(obj)),
    }
  }
}
export default connect(
  undefined,
  mapDispatchToProps
)(ProjectWorkerAttend);