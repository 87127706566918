import React from 'react';
import Select from 'react-select';
import NoSsr from '@material-ui/core/NoSsr';
import { components, useStyle } from './AutocompliteComponents'

/** 
 * @param {import("./types/Autocomplite").Autocomplite} param0 
 */
export default function Autocomplite({
  arr,
  palaceHolder,
  value,
  action,
  selectProps = {},
  textFieldProps = {}
}) {
  const classes = useStyle()
  return <div className={classes.root}>
    <NoSsr>
      <Select
        classes={classes}
        options={arr}
        textFieldProps={{
          label: palaceHolder,
          InputLabelProps: {
            shrink: true,
          },
          ...textFieldProps
        }}
        components={components}
        value={value}
        onChange={value => action(value)}
        placeholder={palaceHolder}
        native
        {...selectProps}
      />
    </NoSsr>
  </div>
}