import React, { useState, useEffect } from 'react';
import { Button, Card, CardActions, CircularProgress, Grid, TextField, Typography, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { parser } from '../../functions/fetch'
import { API_BO, API } from '../../conf'
import { resetPwd } from '../../api/users'
import { enqueueSnackbar } from '../../reducers/notifier'

const useStyles = makeStyles(theme => ({
  main: {
    minHeight: '100vh',
    backgroundColor: "#163dec"
  },
  card: {
    minWidth: 300,
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
}));
const fetchGen = (path, body) => {
  return fetch(API + path, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  })
    .then(parser)
}
export default function Login({ history, match }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [password2, changePassword2] = useState('')
  const [password, changePassword] = useState('')
  const [isLoading, changeLoading] = useState(false)
  const [redirectToReferrer, changeRedirect] = useState(false)
  const snackbar = obj => dispatch(enqueueSnackbar(obj))
  useEffect(() => {
    if (redirectToReferrer) {
      history.push(API_BO + '/login')
    }
  }, [redirectToReferrer, history])
  const handleSubmit = (e) => {
    e.preventDefault();
    changeLoading(true)
    if (password !== password2) {
      snackbar({
        message: `Пароли не совпадают`,
        options: { variant: 'error' }
      })
      return;
    }
    fetchGen(resetPwd.path, { token: match.params.token, password: password })
      .then(res => {
        if (res.success) {
          changeRedirect(true)
        }
        else {
          changeLoading(false)
          snackbar({
            message: `Ошибка изменения пароля`,
            options: { variant: 'error' }
          })
        }
      }).catch(() => {
        changeLoading(false)
        snackbar({
          message: `Server error`,
          options: { variant: 'error' }
        })
      })
  }
  return (
    <div className={classes.main}>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="stretch"
        className={classes.main}
      >
        <Hidden only={['xs', 'sm']}>
          <Grid
            item
            xs={7}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item >
              <div>
                <img src='/logoWhite.png' width={"70%"} alt="logo" style={{ margin: 'auto', display: 'block' }} />
              </div>
            </Grid>
          </Grid>
        </Hidden>
        <Grid
          item
          md={5}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ backgroundColor: '#fff' }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item >
              <Hidden only={['md', 'lg', 'xl']}>
                <img src='/logo.svg' width={"70%"} alt="logo" style={{ margin: 'auto', display: 'block' }} />
              </Hidden>
            </Grid>
            <Grid item>
              <Card className={classes.card}>
                <Typography style={{ textAlign: 'center' }} variant="h6">Восстановление доступа</Typography>
                <form onSubmit={handleSubmit}>
                  <div className={classes.form}>
                    <div className={classes.input}>
                      <TextField
                        label='Пароль'
                        value={password2}
                        type="password"
                        onChange={(e) => changePassword2(e.target.value)}
                        disabled={isLoading}
                        fullWidth
                        error={password !== password2}
                      />
                    </div>
                    <div className={classes.input}>
                      <TextField
                        label='Повторите пароль'
                        value={password}
                        type="password"
                        onChange={(e) => changePassword(e.target.value)}
                        disabled={isLoading}
                        fullWidth
                        error={password !== password2}
                      />
                    </div>
                  </div>
                  <CardActions className={classes.actions}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-around"
                    >
                      <Grid>
                        <Button
                          variant="contained"
                          type="submit"
                          style={{ backgroundColor: '#163dec', color: '#fff' }}
                          disabled={isLoading}
                          className={classes.button}
                          fullWidth
                        >
                          {isLoading && (<CircularProgress size={25} thickness={2} />)}Сменить пароль
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </form>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}