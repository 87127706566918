import React, { useState, useEffect } from 'react'
import {
  getWorkerOne, getWorkerResourceList,
  addWorkerResource, deleteWorkerResource, getWorkerDocList,
  updWorkerLogin, updWorkerChapters,
  addWorkerDoc, deleteWorkerDoc
} from '../../api/worker'
import {
  Button, Grid, Dialog, Input, InputAdornment,
  List, ListItem, ListItemText, FormControl, InputLabel,
  ListItemSecondaryAction, Checkbox, TextField,
  ListItemIcon, IconButton, Tabs, Tab, DialogContent, DialogActions, Typography, Select
} from '@material-ui/core'

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import DeleteIcon from '@material-ui/icons/Delete';
import PeopleIcon from '@material-ui/icons/People';
import FolderIcon from '@material-ui/icons/Folder';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { useDispatch, useSelector } from 'react-redux';
import { fetchDispath, } from '../../functions/fetch'
import can from '../../functions/can';
import { Link } from 'react-router-dom';
import GridProps from '../../components/GridProps'
import Rating from '../../components/Rating'
import * as actions from '../../reducers/formTable';
import * as actionsForm from '../../reducers/form';

import PageList from '../../components/PageListAPI'
import { makeStyles } from '@material-ui/core/styles';
import { getResources } from '../../api/list'


import moment from 'moment'
import SimpleAdd from '../../components/SimpleAdd'
import docEnum from './doc.enum'
import { formSubmitAPI } from '../../reducers/form';

export default function WorkerOne(props) {
  const [state, setState] = useState({
    name: '',
    patronymic: '',
    position: '',
    surname: '',
    rating: '',
    type: '',
    series: '',
    numberP: '',
    issued: '',
    issueDate: '',
    sex: '',
    birthdate: '',
    birthplace: '',
    address: '',
    avatar: null,
    avatarName: '',
    login: '',
    chapter: []
  })
  const [tabc, changeTab] = useState(0)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: props.match.params.id,
      },
      progress: true,
      request: getWorkerOne,
    })).then(res => {
      if (res.success) {
        setState({
          name: res.get.name,
          patronymic: res.get.patronymic,
          position: res.get.position,
          surname: res.get.surname,
          rating: res.get.rating || '',
          type: res.get.type,
          series: res.get.series,
          numberP: res.get.numberP,
          issued: res.get.issued,
          issueDate: moment(res.get.issueDate).format('DD.MM.YYYY'),
          sex: res.get.sex,
          birthdate: moment(res.get.birthdate).format('DD.MM.YYYY'),
          birthplace: res.get.birthplace,
          address: res.get.address,
          avatarName: res.get.avatarName,
          avatar: res.get.avatar,
          login: res.get.login || '',
          chapter: res.get.chapter || [],
        })
      }
    }).catch(err => console.log(err))
  }, [dispatch, props.match.params.id])
  const toLinkChange = React.forwardRef((props3, ref) => (<Link ref={ref} to={props.match.url + '/edit'} {...props3} />))
  const [reload, setReload] = useState(false)
  const [reloadDoc, setReloadDoc] = useState(false)
  return <div className='App-paper'>
    <Grid
      container
      spacing={1}
    >
      <Grid item xs={3}>
        <img src={state.avatar ? `/api/1/files/${state.avatarName}` : '/avatar-default.svg'} alt='avatar' width={'80%'} height={'80%'} />
      </Grid>
      <Grid item xs={9}>
        <GridProps name='Фамилия' typographyProps1={{ style: { fontWeight: 'bold' } }} value={state.surname} xsStart={3} xsEnd={9} />
        <GridProps name='Имя' typographyProps1={{ style: { fontWeight: 'bold' } }} value={state.name} xsStart={3} xsEnd={9} />
        <GridProps name='Отчество' typographyProps1={{ style: { fontWeight: 'bold' } }} value={state.patronymic} xsStart={3} xsEnd={9} />
        <GridProps name='Должность' typographyProps1={{ style: { fontWeight: 'bold' } }} value={state.position} xsStart={3} xsEnd={9} />
        <GridProps name='Рейтинг' typographyProps1={{ style: { fontWeight: 'bold' } }} value={<Rating value={state.rating} />} xsStart={3} xsEnd={9} />
      </Grid>

    </Grid>
    <Tabs value={tabc} onChange={(e, v) => changeTab(v)} indicatorColor='primary'>
      <Tab label='Данные' />
      <Tab label='Ресурсы' />
      <Tab label='Документы' />
      <Tab label='Приложение' />
    </Tabs>
    <TabSelect value={0} tab={tabc}>
      <Grid container >
        <GridProps name='Тип документа:' value={state.type} xsStart={2} xsEnd={10} typographyProps1={{ style: { fontWeight: 'bold' } }} />
        <GridProps name='Серия:' value={state.series} xsStart={1} xsEnd={3} typographyProps1={{ style: { fontWeight: 'bold' } }} />
        <GridProps name='Номер:' value={state.numberP} xsStart={1} xsEnd={3} typographyProps1={{ style: { fontWeight: 'bold' } }} />
        <Grid item xs={4} />
        <GridProps name='Дата выдачи:' value={state.issueDate} xsStart={2} xsEnd={2} typographyProps1={{ style: { fontWeight: 'bold' } }} />
        <GridProps name='Кем выдан:' value={state.issued} xsStart={2} xsEnd={6} typographyProps1={{ style: { fontWeight: 'bold' } }} />
        <GridProps name='Пол:' value={state.sex} xsStart={1} xsEnd={11} typographyProps1={{ style: { fontWeight: 'bold' } }} />
        <GridProps name='Дата рождения:' value={state.birthdate} xsStart={2} xsEnd={2} typographyProps1={{ style: { fontWeight: 'bold' } }} />
        <GridProps name='Место рождения:' value={state.birthplace} xsStart={2} xsEnd={6} typographyProps1={{ style: { fontWeight: 'bold' } }} />
        <GridProps name='Адрес регистрации:' value={state.address} xsStart={2} xsEnd={10} typographyProps1={{ style: { fontWeight: 'bold' } }} />
      </Grid>
    </TabSelect>
    <TabSelect value={1} tab={tabc}>
      <DialogAdd idProject={props.match.params.id} setReload={() => setReload(true)} />
      <PageList
        name='workerRes'
        columns={[
          { id: 'name', name: 'Наименование ресурса' },
        ]}
        createItem={false}
        deleteItem={true}
        loadAPI={{
          param: {
            id: props.match.params.id,
          },
          request: getWorkerResourceList,
        }}
        deleteAPI={deleteWorkerResource(props.match.params.id)}
        idName='id'
        click={false}
        csv={false}
        pagination={false}
        tableProps={{ pagination: false }}
        goBack={false}
        reload={reload}
        reloadFunc={() => setReload(false)}
        {...props}
      />
    </TabSelect>
    <TabSelect value={2} tab={tabc}>
      <DialogAddDoc idProject={props.match.params.id} setReload={() => setReloadDoc(true)} />
      <PageList
        name='workerRes'
        columns={[
          { id: "docType", name: 'Тип документа' },
          { id: "docNumber", name: 'Номер документа' },
          { id: "docCat", name: 'Категории' },
          { id: "dateStart", name: 'Дата выдачи', type: 'date', formatDate: 'DD.MM.YYYY' },
          { id: "dateEnd", name: 'Дата окончания', type: 'date', formatDate: 'DD.MM.YYYY' },
        ]}
        createItem={false}
        deleteItem={true}
        loadAPI={{
          param: {
            id: props.match.params.id,
          },
          request: getWorkerDocList,
          transform: (res => ({
            count: res.count,
            get: res.get.map(obj => ({
              ...obj,
              docType: docEnum.find(e => e.value === obj.docType)?.label
            }))
          })),
        }}
        deleteAPI={deleteWorkerDoc(props.match.params.id)}
        idName='id'
        click={false}
        csv={false}
        pagination={false}
        tableProps={{ pagination: false }}
        goBack={false}
        reload={reloadDoc}
        reloadFunc={() => setReloadDoc(false)}
        {...props}
      />
    </TabSelect>
    <TabSelect value={3} tab={tabc}>
      <AppsWorker
        login={state.login}
        chapter={state.chapter}
        name={state.name}
        patronymic={state.patronymic}
        surname={state.surname}
        id={props.match.params.id}
        updParams={(e) => setState(old => ({ ...old, ...e }))}
      />
    </TabSelect>
    <Button color="primary" onClick={() => props.history.goBack()}>Назад </Button>
    {can('worker:create') && <Button color="primary" component={toLinkChange}>Изменить </Button>}
  </div>
}

function TabSelect({ value, tab, children }) {
  if (value === tab) {
    return <div>{children}</div>
  }
  return null
}

const useStyles = makeStyles(() => ({
  paper: {
    width: '70%',
    maxHeight: 435,
  },
}));
const dialogName = 'resourcesArr'

function getType(key) {
  switch (key) {
    case 'labor':
      return <PeopleIcon size='small' />
    default:
      return <FolderIcon size='small' />
  }
}

function DialogAdd({ idProject, setReload }) {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()
  const [arrParent, setArrParent] = useState([])
  const formTable = useSelector(state => state.formTable[dialogName] || state.formTable.default)
  const formTableWorker = useSelector(state => state.formTable.workerRes || state.formTable.default)
  const { allIds = [], byId = {} } = formTable
  useEffect(() => {
    const parent = (arrParent.length > 0) ? arrParent[arrParent.length - 1] : undefined
    dispatch(fetchDispath({
      querty: {
        parent,
      },
      progress: true,
      request: getResources,
    })).then(res => {
      const arrB = (formTableWorker.allIds || []).map(e => e.split('_')[0])
      const difference = res.get.filter(e => e.type !== 'machine').filter(x => !arrB.includes('id' + x.id));

      dispatch(actions.loadFormTable({ get: difference }, { name: dialogName, id: 'id' }))
    }).catch(err => console.log(err))
  }, [dispatch, arrParent, formTableWorker.allIds])
  const [checked, setChecked] = useState(-1);
  const handleClose = () => {
    setChecked(-1)
    setArrParent([])
    setOpen(false)
  }
  // const handleToggle = (value) => () => {
  //   const currentIndex = checked.indexOf(value);
  //   const newChecked = [...checked];
  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }
  //   setChecked(newChecked);
  // };
  const handleAdd = () => {
    dispatch(fetchDispath({
      param: {
        id: idProject,
      },
      body: {
        resourcesId: checked,
      },
      progress: true,
      request: addWorkerResource,
    })).then(res => {
      setChecked(-1)
      setReload(true)
    })
  }
  return <React.Fragment>
    <Button onClick={() => setOpen(true)} color="primary">Добавить ресурс</Button>
    <Dialog anchor='top' open={open} onClose={() => handleClose()} classes={{ paper: classes.paper }}>
      <div className='App-paper'>
        <IconButton disabled={arrParent.length === 0} onClick={() => setArrParent(arrParent.slice(0, -1))}><ArrowBackIcon /></IconButton>
        <Button color="primary" disabled={checked === -1} onClick={handleAdd}>Добавить</Button>
        <List>
          {allIds.map(row => {
            const obj = byId[row]
            let onClick = undefined
            if (obj.type === 'labor') onClick = () => setArrParent([...arrParent, obj.id])
            return <ListItem
              key={row}
              button={obj.type === 'labor' ? false : true}
              onClick={onClick}
            >
              <ListItemIcon >
                {getType(obj.type)}
              </ListItemIcon>
              <ListItemText primary={obj.name} />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  onChange={() => setChecked(obj.id)}
                  checked={checked === obj.id}
                />
              </ListItemSecondaryAction>
            </ListItem>
          })}
        </List>
      </div>
    </Dialog>
  </React.Fragment>
}

function DialogAddDoc({ idProject, setReload }) {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actionsForm.formInitialize({ name: '', errorValidate: { name: false } }, { name: 'docAdd' }))
    return () => {
      dispatch(actionsForm.formDestroy({ name: 'docAdd' }))
    }
  }, [dispatch])
  const handleClose = () => {
    setOpen(false)
  }
  const form = useSelector(state => state.form.docAdd || state.form.default)
  const { values } = form
  const handleAdd = () => {
    dispatch(fetchDispath({
      param: {
        id: idProject,
      },
      body: {
        docType: values.docType,
        docNumber: values.docNumber,
        docCat: values.docCat,
        dateStart: values.dateStart,
        dateEnd: values.dateStart,
      },
      progress: true,
      request: addWorkerDoc,
    })).then(res => {
      dispatch(actionsForm.formInitialize({ name: '', errorValidate: { name: false } }, { name: 'docAdd' }))
      setOpen(false)
      setReload(true)
    })
  }
  const arrProps = [
    { field: 'docType', type: 'autoArr', label: 'Тип документа', textProps: { arr: docEnum } },
    { field: "docNumber", type: 'text', label: 'Номер документа' },
    { field: "docCat", type: 'text', label: 'Категории' },
    { field: 'dateStart', type: 'text', label: 'Дата выдачи', textProps: { type: 'date' }, labelProps: { shrink: true }, },
    { field: 'dateEnd', type: 'text', label: 'Дата окончания', textProps: { type: 'date' }, labelProps: { shrink: true }, },
  ]
  return <React.Fragment>
    <Button onClick={() => setOpen(true)} color="primary">Добавить документ</Button>
    <Dialog anchor='top' open={open} onClose={() => handleClose()} classes={{ paper: classes.paper }}>
      <DialogContent>
        <SimpleAdd
          title={'Добавить новый документ'}
          arr={arrProps}
          formName='docAdd'
          gridProps={{ xs: 12 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">Отмена</Button>
        <Button onClick={handleAdd} color="primary">Добавить</Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
}

function AppsWorker({ login, chapter, name, patronymic, surname, id, updParams }) {
  return <div>
    <SetAuth
      login={login}
      name={name}
      patronymic={patronymic}
      surname={surname}
      id={id}
      updParams={updParams}
    />
    <SetChapter chapter={chapter} updParams={updParams} id={id} />
  </div>
}

function SetAuth({ login, name, patronymic, surname, id, updParams }) {
  const [changeAuth, setChangeAuth] = useState(false)
  const [newLogin, setLogin] = useState('')
  const [newPassword, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [errorLogin, setErrorLogin] = useState(false)
  useEffect(() => {
    setLogin(login)
  }, [login, changeAuth])
  const dispatch = useDispatch()
  const handleSave = () => {
    dispatch(formSubmitAPI({
      props: {
        login: newLogin,
        pswd: newPassword
      },
      url: updWorkerLogin.path({ id: id }),
      method: updWorkerLogin.type,
      endFunc: () => {
        setChangeAuth(false)
        updParams({ login: newLogin })
      },
      endFuncFalse: () => {
        setErrorLogin(true)
      }
    }), 'formName12')
  }
  return <div>
    <Typography>Логин: {newLogin}</Typography>
    <Button color="primary" onClick={() => setChangeAuth(old => !old)}>
      {changeAuth ? 'закрыть без сохранения' : 'Изменить параметры входа'}
    </Button>
    {changeAuth && <Grid>
      <Grid
        container
        item
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-end"
        xs={6}
      >
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Логин"
            value={newLogin}
            onChange={(e) => setLogin(e.target.value)}
            error={errorLogin}
            helperText={errorLogin ? "логин занят" : ''}
          />
        </Grid>
        <Grid item xs={6}>
          <Button color="primary" onClick={() => (setLogin(loginGenerate(name, patronymic, surname)))}>Сгенерировать</Button>
        </Grid>
        <Grid item xs={6}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel htmlFor="standard-adornment-password">Пароль</InputLabel>
            <Input
              id="standard-adornment-password"
              type={showPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => (setShowPassword(old => !old))}
                    onMouseDown={e => (e.preventDefault())}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Button color="primary" onClick={() => (setPassword(Math.random().toString(36).slice(-8)))}>Сгенерировать</Button>
        </Grid>
        <Grid item>
          <Button color="primary" onClick={handleSave}>Сохранить</Button>
        </Grid>
      </Grid>
    </Grid>}
  </div>
}

function loginGenerate(name = '', patronymic = '', surname = '') {
  const translate = (e) => {
    if (typeof e === "undefined") return ''
    const s = [...e.toLocaleLowerCase()]
    return s.map(e => objRusEng[e] || (e === 'ь' ? '' : e)).join('')
  }
  const surnameEng = surname === '' ? '' : translate(surname)
  const patronymicEng = patronymic === '' ? '' : translate(patronymic[0]).toUpperCase()
  const nameEng = name === '' ? '' : translate(name[0]).toUpperCase()
  return `${surnameEng}${nameEng}${patronymicEng}`
}

const objRusEng = {
  'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e',
  'ж': 'zh', 'з': 'z', 'и': 'i', 'й': 'i', 'к': 'k', 'л': 'l',
  'м': 'm', 'н': 'n', 'о': 'o', 'п': 'p', 'р': 'r', 'с': 's',
  'т': 't', 'у': 'u', 'ф': 'f', 'х': 'kh', 'ц': '	ts', 'ч': 'ch',
  'ш': 'sh', 'щ': 'shch', 'ъ': 'ie', 'ы': 'y', 'ь': '', 'э': 'e', 'ю': 'iu', 'я': 'ia',
}

const arrChapter = [
  { label: '', value: 'none' },
  { label: 'Задачи водителя', value: 'driver' },
]

function SetChapter({ chapter, updParams, id }) {
  const [newChapter, setChapter] = useState([])
  useEffect(() => {
    setChapter(chapter || [])
  }, [chapter])
  const dispatch = useDispatch()
  const handleSave = () => {
    const arrFiltered = newChapter.filter(e => e !== 'none')
    dispatch(formSubmitAPI({
      props: {
        chapters: [...new Set(arrFiltered)]
      },
      url: updWorkerChapters.path({ id: id }),
      method: updWorkerChapters.type,
      endFunc: () => {
        updParams({ chapter: newChapter })
      },
    }), 'formName23')
  }
  return <div>
    <Typography>Разделы приложения</Typography>
    <Grid>
      <Grid item>
        <Button color="primary" onClick={() => setChapter(old => ([...old, 'none']))}>Добавить</Button>
      </Grid>
      {newChapter.map((e, i) => {
        return <Grid item key={i}>
          <Select
            native
            value={e}
            onChange={(e) => {
              setChapter(old => {
                return old.map((s, j) => {
                  if (i === j) return e.target.value
                  return s
                })
              })
            }}
            style={{ minWidth: 120 }}
          >
            {arrChapter.map(el => {
              return <option key={el.value} value={el.value}>{el.label}</option>
            })}
          </Select>
          <IconButton onClick={() => (setChapter(old => {
            const arr = [...old]
            arr.splice(i, 1)
            return arr
          }))}><DeleteIcon /></IconButton>
        </Grid>
      })}
      <Grid item>
        <Button color="primary" onClick={handleSave}>Сохранить</Button>
      </Grid>
    </Grid>
  </div>
}