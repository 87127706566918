import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Tabs, Tab, Button, Typography, Grid, TextField } from '@material-ui/core'
import { formChange } from '../reducers/form'
import AutocompliteWithLoad from './AutocompliteWithLoad'
import AutocompliteWithLoadCreate from './AutocompliteWithLoadCreate'
import Autocomplite from './Autocomplite'
import AutoCompliteCreateModal from './AutocompliteWithLoadCreateModal'

export default function DocAdd({
  history,
  formName,
  title = '',
  handleSave,
  noTableProps = [],
  headerButton,
  tabHead = [],
  tab = [],
  children,
  bottom,
}) {
  const [valueTab, setValueTab] = useState(0)
  return <React.Fragment>
    <Typography variant='h6'>{title}</Typography>
    {headerButton}
    {noTableProps.length > 0 && <NoTableField noTableProps={noTableProps} formName={formName} />}
    {tab.length > 0 &&
      <React.Fragment>
        <Tabs value={valueTab} onChange={(e, v) => setValueTab(v)} indicatorColor='primary'>
          {tab.map((v, i) => <Tab key={i} label={v} />)}
        </Tabs>
        {tabHead[valueTab]}
        {React.Children.toArray(children)[valueTab]}
      </React.Fragment>
    }
    {tab.length === 0 && children}
    {bottom}
    <Button onClick={() => history.goBack()} color='primary'>Назад</Button>
    <Button onClick={handleSave} color='primary'>Сохранить</Button>
  </React.Fragment>
}


function NoTableField({ noTableProps, formName }) {
  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const handleChange = useCallback((value, field) => {
    dispatch(formChange(value, { field, name: formName }))
  }, [dispatch, formName])
  return <Grid container item xs={9} spacing={2} alignItems='flex-end'>
    {noTableProps.map((v, i) => {
      let field = null
      switch (v.type) {
        case 'text':
          field = <TextField
            label={v.label}
            onChange={(e) => {
              handleChange(e.target.value, v.field)
              if (v.onChange) v.onChange(e)
            }}
            value={values[v.field] || ''}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            {...v.textProps}
          />

          break;
        case 'autoLoadCreate':
          field = <AutocompliteWithLoadCreate
            classes={{}}
            field={v.field}
            name={v.label}
            createAPI={v.textProps.createAPI}
            createBody={v.textProps.createBody}
            path={v.textProps.path}
            meta={v.textProps.meta}
            formName={formName}
            change={v.change}
          />
          break;
        case 'autoLoadCreateModal':
          field = <AutoCompliteCreateModal
            classes={{}}
            field={v.field}
            name={v.label}
            createAPI={v.textProps.createAPI}
            createBody={v.textProps.createBody}
            path={v.textProps.path}
            meta={v.textProps.meta}
            formName={formName}
            change={v.change}
            modal={v.textProps.modal}
          />
          break;
        case 'autoLoad':
          field = <AutocompliteWithLoad
            classes={{}}
            field={v.field}
            name={v.label}
            path={v.textProps.path}
            meta={v.textProps.meta}
            formName={formName}
            change={v.change}
          />
          break;
        case 'autoRedux':
          field = <AutoRedux
            label={v.label}
            field={v.field}
            change={v.change}
            formArr={v.textProps.formArr}
            handleChange={handleChange}
          />
          break;
        case 'autoArr':
          field = <Autocomplite
            palaceHolder={v.label}
            action={(e) => {
              handleChange(e.value + '', v.field)
              if (v.change) v.change(e)
            }}
            arr={v.textProps.arr}
          />
          break;
        default:
          break;
      }
      return <Grid item xs={v.xs || 3} key={i}>{field}</Grid>
    })}
  </Grid>
}

function AutoRedux({ label, field, change, formArr, handleChange }) {
  const reduxArr = useSelector(state => state.autocomplite[formArr] || state.autocomplite)
  const handleChangeAction = (e) => {
    handleChange(e.value + '', field)
    if (change) change(e)
  }
  return <Autocomplite
    palaceHolder={label}
    action={handleChangeAction}
    arr={reduxArr.arr || []}
  />
}