import React, { useEffect, useState } from 'react';
import { Button, Typography, Grid, Table, TableBody, TableCell, TableHead, TableRow, Checkbox, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDispath } from '../../functions/fetch'
import { getTenderOurById } from '../../api/tender.api'
import AutoCompliteWithLoad from '../../components/AutocompliteWithLoad'
import { getFacilityOurProject } from '../../api/facility'
import { getGroup } from '../../api/estimate'
import * as actions from '../../reducers/form';
import { addTenderOurLot } from '../../api/tender.api'

const formName = 'lots'
export default function TenderLotAdd({ match, history }) {
  const [idFacility, setFacility] = useState(null)
  const [idProject, setProject] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({ byId: {}, allIds: [], groups: [], dateStart: '', dateEnd: '' }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      progress: true,
      request: getTenderOurById,
      param: {
        id: match.params.idDoc
      }
    })).then(res => {
      setFacility(res.get.idFacility)
    }).catch(err => console.log(err))
  }, [dispatch, match.params.idDoc])

  useEffect(() => {
    if (!idProject) return;
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      progress: true,
      request: getGroup,
    })).then(res => {
      const { list, group } = res
      let gObj = {}
      group.forEach((el, i) => {
        gObj[el.id] = { res: [], summ: 0 };
      });
      list.forEach((el, i) => {
        gObj[el.idGroup].res.push('id' + el.id + '_' + i);
        gObj[el.idGroup].summ += Number(el.summ) || 0
      });

      let byId = {}
      const allIds = list.map((e, i) => {
        const newId = 'id' + e.id + '_' + i;
        byId[newId] = { ...e, selected: false, countSelect: e.count, dateStart: '', dateEnd: '' }
        return newId
      });
      dispatch(actions.formChangeAsObj({
        byId, allIds,
        groups: group.map(e => {
          return { ...e, res: gObj[e.id].res }
        })
      }, { name: formName }))
    })
  }, [idProject, dispatch])
  return <div className='App-paper'>
    <Typography>Добавить лот</Typography>
    {idFacility &&
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <ButtonSave idTender={match.params.idDoc} idProject={idProject} history={history} />
        <Grid item md={6} lg={6} >
          <AutoCompliteWithLoad
            classes={{}}
            field='idProject'
            name='Проект'
            path={getFacilityOurProject.path({ id: idFacility })}
            meta={{
              field: 'idProject',
              value: 'id',
              labelFunc: (obj) => obj.name
            }}
            form={false}
            change={e => { setProject(e.value) }}
          />
        </Grid>
        <HeaderDateStartEnd />
      </Grid>}
    {idProject && <LotsListMaterial />}

  </div>
}
function ButtonSave({ idTender, idProject, history }) {
  const values = useSelector(state => state.form[formName]?.values)
  const groupsProject = useSelector(state => state.form[formName]?.values.groups) || []
  const dispatch = useDispatch()
  const handleSave = (e) => {
    e.preventDefault();
    const groups = []
    const estimate = []
    const groupSelected = new Set()
    values.allIds.forEach(key => {
      const obj = values.byId[key]
      if (obj.selected) {
        estimate.push({
          typeEstimate: obj.type,
          count: obj.countSelect,
          price: 0,
          comment: '',
          idMaterial: obj.type === 'material' ? obj.id : null,
          idWork: obj.type === 'work' ? obj.id : null,
          dateStart: obj.dateStart,
          dateEnd: obj.dateEnd,
          groupNumber: obj.idGroup,
        })
        groupSelected.add(obj.idGroup)
      }
    })
    groupsProject.forEach(g => {
      if (groupSelected.has(g.id)) {
        groups.push({
          name: g.name,
          id: g.id,
          numberPos: g.number,
        })
      }
    })
    const arr = groups.sort((a, b) => a.numberPos - b.numberPos).map((e, i) => ({ ...e, numberPos: i + 1 }))
    const arrEst = estimate.map(e => {
      return { ...e, groupNumber: arr.find(s => e.groupNumber === s.id).numberPos }
    })
    dispatch(actions.formSubmitAPI({
      props: {
        groups: arr,
        estimate: arrEst,
        idProject: idProject,
        dateWorkStart: values.dateStart,
        dateWorkEnd: values.dateEnd,
      },
      url: addTenderOurLot.path({ id: idTender }),
      method: addTenderOurLot.type,
      history: history
    }, formName))
  }
  return <>
    <Grid>
      <Button color="primary" onClick={() => history.goBack()}>Назад</Button>
    </Grid>
    <Grid>
      <Button color="primary" onClick={handleSave}>Сохранить</Button>
    </Grid>
  </>
}
function HeaderDateStartEnd() {
  const dateStart = useSelector(state => state.form[formName]?.values?.dateStart) || ''
  const dateEnd = useSelector(state => state.form[formName]?.values?.dateEnd) || ''
  const allIds = useSelector(state => state.form[formName]?.values?.allIds) || []
  const dispatch = useDispatch()
  const change = (value, field) => {
    dispatch(actions.formChange(value, { name: formName, field: field }))
    allIds.forEach(id => {
      dispatch(actions.formObjChange(value, { name: formName, field: 'byId', id, fieldObj: field }))
    })
  }
  return <>
    <Grid item>
      <TextField
        fullWidth
        label='Дата начала'
        margin="dense"
        type="date"
        value={dateStart}
        onChange={(e) => change(e.target.value, 'dateStart')}
        autoFocus
        size='small'
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
    <Grid>
      <TextField
        fullWidth
        label='Дата окончания'
        margin="dense"
        type="date"
        value={dateEnd}
        onChange={(e) => change(e.target.value, 'dateEnd')}
        autoFocus
        size='small'
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
  </>
}

const tableHead = [
  { name: 'Номер', id: 'number' },
  { name: 'Тип', id: 'type' },
  { name: 'Наименование', id: 'name' },
  { name: 'Тип, марка', id: 'typeMark' },
  { name: 'Артикул', id: 'vendorCode' },
  { name: 'Ед.изм.', id: 'storageUnit' },
  { name: 'Количество по смете', align: 'right', id: 'count' },
  // { name: 'Цена', align: 'right', id: 'price' },
  // { name: 'Сумма', align: 'right', id: 'summ' },
]
function LotsListMaterial() {
  const values = useSelector(state => state.form[formName]?.values) || { byId: {}, allIds: [], groups: [] }
  return <Table size='small' stickyHeader >
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        {tableHead.map(obj => {
          const align = obj.align || 'inherit'
          return (<TableCell key={obj.id} align={align}>{obj.name}</TableCell>);
        })}
        <TableCell align='right'>Выбранное количество</TableCell>
        <TableCell align='right'>Дата начала</TableCell>
        <TableCell align='right'>Дата окончания</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {values.groups.map(objGroup => {
        return <Row key={objGroup.id} name={objGroup.number + '. ' + objGroup.name} res={objGroup.res} />
      })}
    </TableBody>
  </Table>
}

function Row({ res, name }) {
  const [show, changeShow] = useState(true)
  const values = useSelector(state => state.form[formName]?.values) || { byId: {}, allIds: [], groups: [] }
  const dispatch = useDispatch()
  const objChange = (id, value, field) => dispatch(actions.formObjChange(value, { name: formName, field: 'byId', id, fieldObj: field }))

  const changeSelect = (id, selected) => objChange(id, selected, 'selected')
  const changeDateStart = (id, date) => objChange(id, date, 'dateStart')
  const changeDateEnd = (id, date) => objChange(id, date, 'dateEnd')
  const handleChangeCount = (id, value) => objChange(id, value, 'countSelect')

  function setChecked() {
    if (res.length === 0) {
      return {
        checked: false,
        indeterminate: false
      }
    }
    const checked = values.byId[res[0]].selected
    let indeterminate = false

    res.forEach(id => {
      const selected = values.byId[id].selected;
      if (selected !== checked) {
        indeterminate = true
        return { checked, indeterminate }
      }
    })
    return { checked, indeterminate }
  }
  const rowCheckbox = setChecked()
  const changeSelectMany = () => {
    res.forEach(id => objChange(id, !rowCheckbox.checked, 'selected'))
  }
  return (
    <React.Fragment>
      <TableRow style={{ backgroundColor: '#eee' }}>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={rowCheckbox.indeterminate}
            checked={rowCheckbox.checked}
            onClick={() => changeSelectMany()}
          />
        </TableCell>
        <TableCell onClick={() => changeShow(!show)} colSpan={12}>
          {name}
        </TableCell>
      </TableRow>
      {
        show && res.map(id => {
          const obj = values.byId[id];
          return <TableRow key={id} >
            <TableCell padding="checkbox">
              <Checkbox
                checked={obj.selected}
                onClick={() => changeSelect(id, !obj.selected)}
              />
            </TableCell>
            {tableHead.map(e => {
              return <TableCell key={e.id} align={e.align} onClick={() => changeSelect(id, !obj.selected)}>{obj[e.id]}</TableCell>
            })}
            <TableCell align='right'>
              <TextField
                error={Number(obj.countSelect) !== obj.count}
                fullWidth
                margin="dense"
                type="number"
                value={obj.countSelect || ''}
                onChange={(e) => handleChangeCount(id, e.target.value)}
                autoFocus
                size='small'
                inputProps={{ max: obj.count }}
              />
            </TableCell>
            <TableCell align='right'>
              <TextField
                fullWidth
                margin="dense"
                type="date"
                value={obj.dateStart || ''}
                onChange={(e) => changeDateStart(id, e.target.value)}
                autoFocus
                size='small'
              />
            </TableCell>
            <TableCell align='right'>
              <TextField
                fullWidth
                margin="dense"
                type="date"
                value={obj.dateEnd || ''}
                onChange={(e) => changeDateEnd(id, e.target.value)}
                autoFocus
                size='small'
              />
            </TableCell>
          </TableRow>
        })}
    </React.Fragment>
  );
}