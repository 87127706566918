import React, { useState } from 'react';
import NoSsr from '@material-ui/core/NoSsr';
import CreatableSelect from 'react-select/creatable';
import { components, useStyle } from './AutocompliteComponents'

export default function IntegrationReactSelect({ arr, action, value, palaceHolder, create, textFieldProps={}, selectProps }) {
  const [loading, setLoading] = useState(false)
  const handleCreate = (inputValue) => {
    setLoading(true)
    create(inputValue)
      .then(end => {
        setLoading(false)
        if (end.success) action(end.obj)
      })
  };
  const classes = useStyle()
  return (
    <div className={classes.root}>
      <NoSsr>
        <CreatableSelect
          isDisabled={loading}
          isLoading={loading}
          onCreateOption={handleCreate}
          classes={classes}
          options={arr}
          textFieldProps={{
            label: palaceHolder,
            InputLabelProps: {
              shrink: true,
            },
            ...textFieldProps
          }}
          components={components}
          value={value}
          onChange={text => action(text)}
          placeholder={palaceHolder}
          native
          formatCreateLabel={(e) => {
            return 'Создать "' + e + '"'
          }}
          {...selectProps}
        />
      </NoSsr>
    </div>
  );
}