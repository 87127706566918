import React, { useEffect, useState } from 'react'
import { getRentEquipmentWriteOfById, writeOfByIdRentEquipment } from '../../../api/shipment'
import Button from '@material-ui/core/Button'
import { useDispatch, useSelector } from 'react-redux';
import { fetchDispath } from '../../../functions/fetch'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import 'moment/locale/ru';
import * as actions from '../../../reducers/form';

const formName = 'ArendaEquipmentByIdWriteOff'
export default function ArendaEquipmentByIdWriteOff({ match, history }) {
  const [extend, setExtend] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({
      user: '',
      dateCreate: '',
      provider: '',
      name: '',
      serialNumber: '',
      time: '',
      dateStart: '',
      dateEnd: '',
      autoWriteOff: '',
      price: '',
      alarm: false,
      idHour: false,
      idProvider: null,
      idEquipment: null
    }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: match.params.idArenda,
      },
      progress: true,
      request: getRentEquipmentWriteOfById,
    }))
      .then(res => {
        if (res.success) {
          const dataForm = {
            user: res.get.user,
            dateCreate: res.get.dateCreate,
            provider: res.get.provider,
            name: res.get.name,
            serialNumber: res.get.serialNumber || ' ',
            time: res.get.time,
            dateStart: moment(res.get.dateStart).format('LLL'),
            dateEnd: res.get.dateEnd,// данные передаются на ArendaEquipentAdd поэтому без moment
            autoWriteOff: res.get.autoWriteOff,
            price: res.get.price,
            alarm: res.get.alarm,
            isHour: res.get.isHour,
            idProvider: res.get.idProvider,
            idEquipment: res.get.idEquipment
          }
          dispatch(actions.formChangeAsObj(dataForm, { name: formName }))
        }
      }).catch(err => console.log(err))
  }, [dispatch, match.params.idArenda])
  useEffect(() => {
    if (extend) {
      const path = `/projects/${match.params.id}/shipment/arenda/add`
      history.push(path);
    }
  }, [extend, history, match.params.id])
  const handlerWriteOff = () => {
    dispatch(actions.formSubmitAPI({
      url: writeOfByIdRentEquipment.path({
        id: match.params.idArenda,
      }),
      method: writeOfByIdRentEquipment.type,
      history: history
    }))
  }
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  return <div className='App-paper'>
    <Typography variant='h6'>{values.name} {values.serialNumber}</Typography>
    <Grid
      container
      direction="row"
      justifyContent='flex-start'
      alignItems="flex-start"
    >
      <Grid item >
        <Typography>Добавил:</Typography>
        <Typography>Поставщик:</Typography>
        <Typography>Время аренды:</Typography>
        <Typography>Дата начала:</Typography>
        <Typography>Дата окончания аренды: </Typography>
        <Typography>Тип списания:</Typography>
      </Grid>
      <Grid item >
        <Typography>{values.user}</Typography>
        <Typography>{values.provider}</Typography>
        <Typography>{values.time} {(values.isHour) ? '(час)' : '(день)'}</Typography>
        <Typography>{values.dateStart}</Typography>
        <Typography>{moment(values.dateEnd).format('LLL')}</Typography>
        <Typography>{(values.autoWriteOff) ? 'автоматически' : 'вручную'}</Typography>
      </Grid>
    </Grid>
    <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
    <Button color="primary" onClick={handlerWriteOff}>Списать </Button>
    <Button color="primary" onClick={() => setExtend(true)}>Продлить </Button>
  </div>
}