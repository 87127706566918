import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'

export default function ShipmentByIdBottom({ name }) {
  const formTable = useSelector(state => state.formTable[name] || state.formTable.default)
  const { obj = {} } = formTable
  return <div style={{ textAlign: 'right' }}>
    <Grid
      container
      direction="row"
      justifyContent='flex-end'
      alignItems="flex-end"
    >
      <Grid item xs={3}><Typography variant='h6'>Всего</Typography></Grid>
      <Grid item xs={3}><Typography variant='h6'>{Number(obj.sumDoc || 0).toLocaleString()}</Typography></Grid>
    </Grid>
  </div>
}