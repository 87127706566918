import React, { useMemo, memo } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, IconButton, TableContainer } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useSelector } from 'react-redux';
import TableRowShipmentMaterial from './TableRow'
import AllPrice from './AllPrice';

function TableShipment({ formName, addRow, idProject, loadOrder }) {
  const allIds = useSelector(state => state.form[formName]?.values?.allIds || [])
  return (<div>
    <TableContainer style={{ width: '100%', height: '64vh', overflowX: 'auto' }}>
      <Table style={{ minWidth: 1000 }} stickyHeader >
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 10 }} padding={'none'}>
              {(loadOrder)
                ? <IconButton onClick={addRow}  ><AddIcon /></IconButton>
                : <CircularProgress size={25} />}
            </TableCell>
            <TableCell padding='checkbox' style={{ width: '42%' }}>Материал(наименование по смете)</TableCell>
            {['Количество', 'Цена за ед.', 'Ед. изм.', 'K=(new/old)', 'Сумма', 'НДС', 'Сумма с НДС'].map((name, i) => {
              return <TableCell key={i} padding='checkbox' style={{ width: '9%' }}>{name}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {allIds
            .map(row => {
              return <TableRowShipmentMaterial
                key={row}
                id={row}
                formName={formName}
                idProject={idProject}
              />
            })}
          <TableRow style={{ height: 20 * 5 }}>
            <TableCell colSpan={9} />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <AllPriceCustom formName={formName} />
  </div>)
}

export default memo(TableShipment);
// export default TableShipment;

function AllPriceCustom({ formName }) {
  const allIds = useSelector(state => state.form[formName]?.values?.allIds || [])
  const byId = useSelector(state => state.form[formName]?.values?.byId || {})
  const summ = useMemo(() => {
    let res = 0
    allIds.forEach(key => {
      const obj = byId[key]
      res += Math.round(obj.count * obj.price * 100 * ((obj.nds === 'NDS20') ? 1.2 : 1)) / 100
    })
    return res
  }, [allIds, byId])
  return <AllPrice summ={summ || 0} />
}


{/* 
  может использую
  <Hidden smUp>
<IconButton onClick={addRow} ><AddIcon /></IconButton>
<Driver
  idProject={idProject}
  formName={formName}
  add={addRow}
/>
{allIds
  .map(row => {
    const obj = byId[row];
    return <Card
      key={row}
      id={row}
      obj={obj}
      idProject={idProject}
      formName={formName}
    />
  })}
</Hidden> */}