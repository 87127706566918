import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Checkbox, FormControlLabel, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import * as actions from '../../reducers/materialLoad';
import * as actions2 from '../../reducers/form';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import { loadGrand } from '../../api/grand'
import SimpleAdd from '../../components/SimpleAdd'
// переделать 
const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  input: {
    display: 'none',
  },
  edt: {
    padding: '1px',
  },
  main: {
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: 240,
    marginTop: 5,
    marginBottom: 3,
    width: '50%'
  },
});
class LoadGrand extends React.Component {
  constructor(props) {
    super()
    this.state = {
      paramsAdd: [
        {
          field: 'consist', type: 'autoArr', label: 'Если есть',
          validator: [value => !!value], errorText: 'Поле обязательно',
          textProps: { arr: [{ value: 'change', label: 'Изменить' }, { value: 'add', label: 'Добавить' }], defaultValue: 'add', },
        },
      ],
      loading: false,
      formName: 'loadGrand'
    }
    props.actions.formInitialize({
      consist: '',
      fileShort: '',
      file: '',
      fileId: '',
      estimate: ''
    })
  }
  _handleImageChange(e) {
    e.preventDefault();
    if (typeof e.target.files[0] === 'undefined' || e.target.files[0].length === 0) return
    this.setState({ loading: true })

    let shortName = e.target.files[0].name
    if (shortName.length > 18) {
      shortName = e.target.files[0].name.slice(0, 7) + '...' + e.target.files[0].name.slice(-8)
    }
    this.props.actions.formChange(e.target.files[0].name, { field: 'file' })
    this.props.actions.formChange(shortName, { field: 'fileShort' })
    this.props.actions.loadFile({ file: e.target.files[0], path: `/files/add` })
      .then(res => {
        if (res.success) {
          this.props.actions.formChange(res.id, { field: 'fileId' })
        }
        this.setState({ loading: false })
      })
      .catch()
  }
  handleLoad = () => {
    const { formName } = this.state
    const { form } = this.props
    const { values } = form[formName] || form.default
    const params = {
      idProject: this.props.match.params.id,
      estimate: values.estimate || false,
      consist: values.consist || 'change',
      idFiles: values.fileId
    }
    this.props.actions.formSubmit({
      props: params,
      url: loadGrand.path,
      method: loadGrand.type,
      history: this.props.history
    })
  }
  componentWillUnmount() {
    this.props.actions.formDestroy()
  }
  render() {
    const { classes, form } = this.props
    const { formName } = this.state
    const { values } = form[formName] || form.default

    return (<div className='App-paper'>
      <div className={classes.formControl}>
        <input
          className={classes.input}
          id="contained-button-file"
          type="file"
          onChange={(e) => this._handleImageChange(e)}
        />
        <label htmlFor="contained-button-file">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={16}
          >
            <Grid item >
              <Button variant='contained' component="span" size="small">Файл</Button>
            </Grid>
            <Grid item >
              <Tooltip title={values.file}><Typography>{values.fileShort}</Typography></Tooltip>
            </Grid>
            <Grid item >
              {this.state.loading && <CircularProgress size={20} />}
            </Grid>
          </Grid>
        </label>
      </div>
      <SimpleAdd
        arr={this.state.paramsAdd}
        formName={formName}
      />
      <div>
        <FormControlLabel
          className={classes.formControl}
          control={
            <Checkbox
              checked={values.estimate}
              onChange={(e) => { this.props.actions.formChange(e.target.checked, { field: 'estimate' }) }}
              color="primary"
            />
          }
          label="Материалы заказчика"
        />
      </div>

      <Button color="primary" onClick={() => this.props.history.goBack()}>Назад </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={this.handleLoad}
        className={classes.button}
      >
        Загрузить
        </Button>
    </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    data: store.materialLoad,
    form: store.form
  }
}
const mapDispatchToProps = dispatch => {
  return {
    actions: {
      loadFile: (obj) => dispatch(actions.loadFileAPI(obj)),
      formSubmit: (obj) => dispatch(actions2.formSubmitAPI(obj, 'loadGrand')),
      formDestroy: () => dispatch(actions2.formDestroy({ name: 'loadGrand' })),
      formChange: (obj, meta) => dispatch(actions2.formChange(obj, { ...meta, name: 'loadGrand' })),
      formInitialize: obj => dispatch(actions2.formInitialize(obj, { name: 'loadGrand' })),
      formChangeAsObj: obj => dispatch(actions2.formChangeAsObj(obj, { name: 'loadGrand' }))
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LoadGrand));
