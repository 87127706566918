import React, { useState } from 'react';
import {
  Typography, Dialog, DialogTitle, DialogContent, IconButton,
  List, ListItem, ListItemText
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import * as actions from '../../../reducers/form';
import { fetchDispath } from '../../../functions/fetch'
import { setTenderStatus, } from '../../../api/tender.api'
import { TENDER_STATUS } from '../TenderOurList'
import EditIcon from '@material-ui/icons/Edit';
import AssignmentIcon from '@material-ui/icons/Assignment';

const useStyle = makeStyles(theme => ({
  field: {
    // marginBottom: theme.spacing(2),
  },
  block: {
    borderTop: '1px solid #e8e8e8'
  },
  textInfo: {
    fontWeight: 'bold'
  }
}));

const headerColMain = [
  // { id: 'name', name: 'Название' },
  // { id: 'statusRus', name: 'Статус' },
  { id: 'facilityName', name: 'Объект' },
  { id: 'typeTenderRus', name: 'Тип тендера' },
  { id: 'regions', name: 'Регионы', },
  { id: 'specializations', name: 'Специализация', },
]
const headerColDate = [
  { id: 'dateConfirmStart', name: 'Начало подтверждения участия', },
  { id: 'dateConfirmEnd', name: 'Окончание подтверждения участия', },
  { id: 'dateOfferEnd', name: 'Окончание предложения', },
  { id: 'dateSummingEnd', name: 'Подведение итогов', },
]
export default function HeaderOurTender({ idDoc, formName }) {
  const classes = useStyle()
  const values = useSelector(state => state.form[formName]?.values) || {}
  const handleClick = (value) => {
    navigator.clipboard.writeText(value)
  }
  return <div>
    <Typography variant='h6'>Название:  {values.name}</Typography>
    <div className={classes.block}>
      <Typography variant='h6'>Общая информация</Typography>
      <div className={classes.field}>
        <Typography variant='body1' style={{ display: 'inline-block' }}>Статус: <span className={classes.textInfo}>{values.statusRus}</span></Typography>
        <SetStatus idDoc={idDoc} formName={formName} />
      </div>
      {headerColMain.map(e => <div key={e.id} className={classes.field}>
        <Typography variant='body1'>{e.name}: <span className={classes.textInfo}>{values[e.id]}</span></Typography>
      </div>)}
    </div>
    <div className={classes.block}>
      <Typography variant='h6'>Время проведения</Typography>
      {headerColDate.map(e => <div key={e.id} className={classes.field}>
        <Typography variant='body1'>{e.name}: <span className={classes.textInfo}>{values[e.id]}</span></Typography>
      </div>)}
    </div>
    <div className={classes.block}>
      <Typography variant='h6'>Доступ к тендеру</Typography>
      {[{ id: 'uuid', name: 'UUID' }, { id: 'accessKey', name: 'Ключ' }].map(e => (<div key={e.id} className={classes.field}>
        <Typography variant='body1' style={{ display: 'inline-block' }}>{e.name}: <span className={classes.textInfo}>{values[e.id]}</span></Typography>
        <IconButton color="default" size='small' onClick={() => handleClick(values[e.id])}>
          <AssignmentIcon />
        </IconButton>
      </div>))}
      <div className={classes.field}>
        <Typography variant='body1' style={{ display: 'inline-block' }}>Внешняя ссылка: <span className={classes.textInfo}>{`${window.location.origin}/tender_share`}</span></Typography>
        <IconButton color="default" size='small' onClick={() => handleClick(`${window.location.origin}/tender_share`)}>
          <AssignmentIcon />
        </IconButton>
      </div>

    </div>
  </div>
}

function SetStatus({ idDoc, formName }) {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const handleSet = (status) => {
    dispatch(fetchDispath({
      progress: true,
      request: setTenderStatus,
      param: {
        id: idDoc,
      },
      body: {
        status: status,
      }
    })).then(() => {
      dispatch(actions.formChangeAsObj({
        statusRus: TENDER_STATUS.find(s => s.value === status).label
      }, { name: formName }))
      setOpen(false)
    })
  }
  return <>
    <IconButton style={{ fontSize: '0.875rem' }} color='primary' size='small' onClick={() => setOpen(true)}>
      <EditIcon fontSize="inherit" />
    </IconButton>
    <Dialog
      open={open}
      // maxWidth='xl'
      keepMounted
      fullWidth
      scroll="paper"
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
    // PaperProps={{ style: { minHeight: 500 } }}
    >
      <DialogTitle id="alert-dialog-title">Установить статус</DialogTitle>
      <DialogContent>
        <List>
          {TENDER_STATUS.map(obj => {
            return <ListItem key={obj.value} button onClick={() => handleSet(obj.value)}>
              <ListItemText inset primary={obj.label} />
            </ListItem>
          })}
        </List>
      </DialogContent>
    </Dialog>
  </>
}
