import React, { useState, useEffect } from 'react';
import { Button, Card, CardActions, CircularProgress, Grid, TextField, Typography, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { saveState } from '../../localStorage'
import { useDispatch } from 'react-redux';
import { editUser } from '../../reducers/users';
import { parser } from '../../functions/fetch'
import { API_BO } from '../../conf'
import { login as loginUser, resetMail } from '../../api/users'
import { selectProjectAPI } from '../../reducers/project'
import { API } from '../../conf'
import { enqueueSnackbar } from '../../reducers/notifier'

const useStyles = makeStyles(theme => ({
  main: {
    minHeight: '100vh',
    backgroundColor: "#163dec"
  },
  card: {
    minWidth: 300,
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
}));
const fetchGen = (path, body) => {
  return fetch(API + path, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  })
    .then(parser)
}
export default function Login({ history }) {
  const classes = useStyles()
  const [isReset, setReset] = useState(false)
  return (
    <div className={classes.main}>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="stretch"
        className={classes.main}
      >
        <Hidden only={['xs', 'sm']}>
          <Grid
            item
            xs={7}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item >
              <img src='/logoWhite.png' width={"70%"} alt="logo" style={{ margin: 'auto', display: 'block' }} />
            </Grid>
          </Grid>
        </Hidden>
        <Grid
          item
          md={5}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ backgroundColor: '#fff' }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item >
              <Hidden only={['md', 'lg', 'xl']}>
                <img src='/logo.svg' width={"70%"} alt="logo" style={{ margin: 'auto', display: 'block' }} />
              </Hidden>
            </Grid>
            <Grid item >
              {!isReset && <CardLogin history={history} setReset={() => setReset(true)} />}
              {isReset && <CardReset setReset={() => setReset(false)} />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}


function CardLogin({ history, setReset }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const role = localStorage.getItem('role');
  const [email, changeEmail] = useState('')
  const [password, changePassword] = useState('')
  const [isLoading, changeLoading] = useState(false)
  const [redirectToReferrer, changeRedirect] = useState(Boolean(role))
  const [errorPswd, changeErrorPswd] = useState(false)
  const snackbar = obj => dispatch(enqueueSnackbar(obj))
  useEffect(() => {
    if (redirectToReferrer && role) {
      history.push(API_BO + '/accounts')
    }
  }, [redirectToReferrer, history, role])
  const errServer = () => {
    changeLoading(false)
    snackbar({
      message: `Server error`,
      options: { variant: 'error' }
    })
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    changeLoading(true)
    fetchGen(loginUser.path, { email: email.trim(), password: password })
      .then(res => {
        if (res.success) {
          saveState(res)
          dispatch(editUser({ role: res.role, name: res.role, ...res }))
          if (!!res.project) {
            dispatch(selectProjectAPI(parseInt(res.project, 10)))
          }
          changeRedirect(true)
        }
        else {
          changeLoading(false)
          changeErrorPswd(true)
          snackbar({
            message: `Неправильный логин или пароль`,
            options: { variant: 'error' }
          })
        }
      }).catch(errServer)
  }
  return <Grid item>
    <Card className={classes.card}>
      <Typography style={{ textAlign: 'center' }} variant="h6">Добро пожаловать</Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.form}>
          <div className={classes.input}>
            <TextField
              autoFocus
              label='Email'
              value={email}
              type="text"
              onChange={(e) => changeEmail(e.target.value)}
              disabled={isLoading}
              fullWidth
              error={errorPswd}
            />
          </div>
          <div className={classes.input}>
            <TextField
              label='Password'
              value={password}
              type="password"
              onChange={(e) => changePassword(e.target.value)}
              disabled={isLoading}
              fullWidth
              error={errorPswd}
            />
          </div>
        </div>
        <CardActions className={classes.actions}>
          <Grid
            container
            direction="column"
            justifyContent="space-around"
          >
            <Grid>
              <Button
                variant="contained"
                type="submit"
                style={{ backgroundColor: '#163dec', color: '#fff' }}
                disabled={isLoading}
                className={classes.button}
                fullWidth
              >
                {isLoading && (<CircularProgress size={25} thickness={2} />)}Войти
              </Button>
            </Grid>
            <Grid>
              <Button
                color="primary"
                style={{ color: '#163dec' }}
                onClick={setReset}
              >
                Восстановить пароль
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </form>
    </Card>
  </Grid>
}

function CardReset({ setReset }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [email, changeEmail] = useState('')
  const [isLoading, changeLoading] = useState(false)
  const snackbar = obj => dispatch(enqueueSnackbar(obj))
  const errServer = () => {
    changeLoading(false)
    snackbar({
      message: `Server error`,
      options: { variant: 'error' }
    })
  }
  const handleSubmitReset = (e) => {
    e.preventDefault();
    changeLoading(true)
    fetchGen(resetMail.path, { email: email }).then(() => {
      changeLoading(false)
      snackbar({
        message: `Письмо отправлено`,
        options: { variant: 'success' }
      })
    }).catch(errServer)
  }
  return <Grid item>
    <Card className={classes.card}>
      <Typography style={{ textAlign: 'center' }} variant="h6">Восстановить пароль</Typography>
      <form onSubmit={handleSubmitReset}>
        <div className={classes.form}>
          <div className={classes.input}>
            <TextField
              autoFocus
              label='Email'
              value={email}
              type="text"
              onChange={(e) => changeEmail(e.target.value)}
              disabled={isLoading}
              fullWidth
            />
          </div>
        </div>
        <CardActions className={classes.actions}>
          <Grid
            container
            direction="column"
            justifyContent="space-around"
          >
            <Grid>
              <Button
                variant="contained"
                type="submit"
                style={{ backgroundColor: '#163dec', color: '#fff' }}
                disabled={isLoading}
                fullWidth
              >
                {isLoading && (<CircularProgress size={25} thickness={2} />)}Отправить
              </Button>
            </Grid>
            <Grid>
              <Button
                color="primary"
                style={{ color: '#163dec' }}
                onClick={setReset}
              >
                Авторизоваться
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </form>
    </Card>
  </Grid>
}