export default function getFormatDate(filter) {
  switch (filter) {
    case 'year': return 'YYYY';
    case 'day': return 'YYYY-MM-DD';
    case 'month': return 'YYYY-MM';
    default: return 'YYYY-MM-DD';
  }
}

export const  getFormatDateTable =(filter)=> {
  switch (filter) {
    case 'year': return 'YYYY';
    case 'day': return 'DD.MM.YYYY';
    case 'month': return 'MMMM YYYY';
    default: return 'DD.MM.YYYY';
  }
}