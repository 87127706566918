import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getMaterialsList } from '../../../api/material'
import { fetchDispath } from '../../../functions/fetch'
import * as actions from '../../../reducers/form';
import {
  Table, TableBody, TableCell, TableHead, Button,
  TableRow, TableContainer, Input, Typography, IconButton,
  TextField
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import download from '../../../functions/download'

const formName = 'OrderMaterialPrice'
export default function OrderMaterialProvider({ match, history }) {
  const dispatch = useDispatch()
  const projectId = match.params.idProject
  useEffect(() => {
    dispatch(actions.formInitialize({ byId: {}, allIds: [], selected: [] }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      param: { id: projectId, },
      progress: true,
      request: getMaterialsList,
    })).then(res => {
      let byId = {}
      const allIds = res.get.map((v, i) => {
        const rowId = 'b' + v.id + '_' + i
        byId[rowId] = {
          id: v.id,
          pos: v.number,
          name: v.name,
          type: v.typeMark,
          code: v.vendorCode,
          su: v.storageUnit,
          balance: Math.round((v.count - v.storage - v.costs) * 1000) / 1000,
          count: Math.round((v.count - v.storage - v.costs) * 1000) / 1000,
          finder: `${v.name?.toLocaleLowerCase() || ''}${v.typeMark?.toLocaleLowerCase() || ''} ${v.vendorCode?.toLocaleLowerCase() || ''}`
        }
        return rowId
      })
      dispatch(actions.formChangeAsObj({ byId, allIds }, { name: formName }))
    })
  }, [dispatch, projectId])
  const byId = useSelector(state => state.form[formName]?.values.byId||{})
  const selected = useSelector(state => state.form[formName]?.values.selected||[])
  const handleClickCSV = () => {
    let sById = {}
    selected.forEach(key => {
      const obj = byId[key]
      sById[key] = {
        name: (obj.name).replaceAll(/;/g, ' ').trim(),
        type: (obj.type).replaceAll(/;/g, ' ').trim(),
        code: (obj.code).replaceAll(/;/g, ' ').trim(),
        su: obj.su,
        count: obj.count
      }
    })
    download(selected, sById, ['name', 'type', 'code','su', 'count'], ['Наименование','Тип, марка', 'Код ', 'ед. изм.', 'количество'], 'order.csv')
  }
  return <div className='App-paper'>
    <Typography variant='h6'>Заказать материалы</Typography>
    <TableSelect formName={formName} />
    <TableNotSelect formName={formName} />
    <Button color="primary" onClick={() => history.goBack()}>Назад </Button>
    <Button color="primary" onClick={handleClickCSV}>Печать</Button>
  </div>
}

const RowNotSelectTable = React.memo(function RowNotSelectTable({ id, formName }) {
  const obj = useSelector(state => state.form[formName]?.values?.byId[id])
  const dispatch = useDispatch()
  const handleSelect = () => {
    dispatch(actions.formArrayAdd(id, { name: formName, field: 'selected' }))
    dispatch(actions.formArrayDelete(id, { name: formName, field: 'allIds' }))
  }
  return <TableRow onClick={handleSelect} hover>
    {['pos', 'name', 'type', 'code', 'su', 'balance'].map(v => {
      return <TableCell key={v}>{obj[v]}</TableCell>
    })}
  </TableRow>
})

function TableNotSelect({ formName }) {
  const byId = useSelector(state => state.form[formName]?.values.byId || {})
  const selected = useSelector(state => state.form[formName]?.values?.selected || [])
  const allIds = useSelector(state => state.form[formName]?.values.allIds || [])
  const [filterName, setFilterName] = useState('')
  const dispatch = useDispatch()
  const handleSelectAll = () => {
    dispatch(actions.formChangeAsObj({ allIds: [], selected: [...selected, ...allIds] }, { name: formName }))
  }
  return <>
    <Button color="primary" onClick={handleSelectAll}>Выбрать все</Button>
    <TextField style={{ width: '50%' }} value={filterName} onChange={e => setFilterName(e.target.value)} placeholder='фильтр наименование' />
    <TableContainer style={{ height: '41vh' }}>
      <Table size='small' stickyHeader >
        <TableHead>
          <TableRow>
            {['Позиция', 'Наименование', 'Тип, марка', 'Код ', 'Единица измерения', 'Требуется заказать'].map((v, i) => {
              return <TableCell key={i} style={{ minWidth: '10%' }}>{v}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {allIds.filter(e => byId[e].finder.includes(filterName.toLocaleLowerCase())).map(key => {
            return <RowNotSelectTable key={key} id={key} formName={formName} />
          })}
        </TableBody>
      </Table>
    </TableContainer>
  </>
}

const RowSelectTable = React.memo(function RowSelectTable({ id, formName }) {
  const dispatch = useDispatch()
  const obj = useSelector(state => state.form[formName]?.values?.byId[id])
  const handleChangeCount = (value) => {
    dispatch(actions.formObjChangeObj({ count: value }, { field: 'byId', id: id, name: formName }))
  }
  const handleDelete = () => {
    dispatch(actions.formArrayAdd(id, { name: formName, field: 'allIds' }))
    dispatch(actions.formArrayDelete(id, { name: formName, field: 'selected' }))
  }
  return <TableRow>
    <TableCell style={{ width: 10 }} padding={'none'}>
      <IconButton onClick={handleDelete} size="small" ><DeleteIcon /></IconButton>
    </TableCell>
    {['pos', 'name', 'type', 'code', 'su'].map(v => {
      return <TableCell key={v}>{obj[v]}</TableCell>
    })}
    <TableCell>
      <Input fullWidth value={obj.count} type='number' onChange={(e) => handleChangeCount(e.target.value)} />
    </TableCell>
  </TableRow>
})

function TableSelect({ formName }) {
  const selected = useSelector(state => state.form[formName]?.values?.selected || [])
  const allIds = useSelector(state => state.form[formName]?.values.allIds || [])
  const dispatch = useDispatch()
  const handleDeleteAll = () => {
    dispatch(actions.formChangeAsObj({ allIds: [...allIds, ...selected], selected: [] }, { name: formName }))
  }
  return <>
    <TableContainer style={{ height: '41vh' }}>
      <Table size='small' stickyHeader >
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 10 }} padding={'none'}>
              <IconButton onClick={handleDeleteAll} size="small" ><DeleteIcon /></IconButton>
            </TableCell>
            {['Позиция', 'Наименование', 'Тип, марка', 'Код ', 'Единица измерения', 'Количество'].map((v, i) => {
              return <TableCell key={i} style={{ minWidth: '10%' }}>{v}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {selected.map(key => <RowSelectTable key={key} id={key} formName={formName} />)}
        </TableBody>
      </Table>
    </TableContainer>
  </>
} 