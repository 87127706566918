import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import * as actions from '../../reducers/form';
import { fetchDispath } from '../../functions/fetch'
import { initialAutocomplite, clearAutocomplite, load as loadAuto, loadGroup } from '../../reducers/autocomplite'
import { loadGantArrow, loadGantTree, clearGantThree } from '../../reducers/gant'
import { getProjectGant } from '../../api/form-gant.api'
import { getOutfitPlanWork } from "../../api/doc";
import { getFacilityOurProject } from '../../api/facility'

import { typeGant } from '../../enum/gant.enum'
import 'moment/locale/ru';
import moment from 'moment'
import GrandView from './GrandView'
import GantDialog from './GantDialog'
import GantHeader from './GantHeader'
import { formFacilityProject } from './gant.const'
const formName = 'form-gant'
const formDialog = 'form-dialog'
const formView = 'form-view'
const formWork = 'work'
const formMaterial = 'material_gant'
const formEstimateGroup = 'estimate-group'
const oneRow = {
  id: '',
  idAPI: '',
  start: moment(new Date()),
  end: moment(new Date()).add(10, 'd'),
  startPlan: moment(new Date()),
  endPlan: moment(new Date()).add(10, 'd'),
  progress: 0,
  dependencies: [],
  name: '',
  idWork: null,
  idMaterial: null,
  duration: 1,
  pos: 0,
  idGroup: null,
  value: null,
  valueMaterial: null,
  count: 0,
  textField: '',
  typeGant: typeGant[0].value,
  parent: null,
  parentValue: null,
  child: [],
  color: null,
  selected: false,
  idDateEndLink: null,
  idDateStartLink: null,
  idProjectLink: 0
}

export default function Gant({ match, history }) {
  const dispatch = useDispatch()
  const idProject = match.params.idProject
  const [isLoad, setLoad] = useState(false)
  const [percent, setPercent] = useState(130)
  const [zoom, setZoom] = useState('day')
  const [fixTable, setFixTable] = useState(true)
  useEffect(() => {
    console.log('upd localStorage')
    let gantProps = {}
    try {
      gantProps = JSON.parse(localStorage.getItem('gantProps') || "{}")
    } catch (error) {
    }
    const gantNewProps = { percent: 130, zoom: 'day', fixTable: true, ...gantProps }
    setPercent(gantNewProps.percent)
    setZoom(gantNewProps.zoom)
    setFixTable(gantNewProps.fixTable)
    localStorage.setItem('gantProps', JSON.stringify(gantNewProps))
  }, [])
  useEffect(() => {
    dispatch(actions.formInitializeMulti({
      [formName]: {
        byId: {},
        allIds: [],
        arrFilter: [],
        selected: [],
        start: moment(),
        end: moment(),
        count: 0,
        idRow: 0
      },
      [formDialog]: {
        obj: oneRow,
        id: '',
        open: false,
        newRow: false,
        autocompliteParent: [],
      },
      [formView]: {
        obj: oneRow,
        id: '',
        open: false,
        x: 1000,
        y: 1000,
        height: 0,
        drag:false,
      },
    }, { nameArr: [formName, formDialog, formView] }))

    dispatch(initialAutocomplite({ field: formWork }))
    dispatch(initialAutocomplite({ field: formMaterial }))
    dispatch(initialAutocomplite({ field: formFacilityProject }))
    dispatch(initialAutocomplite({ field: formEstimateGroup }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(actions.formDestroy({ name: formDialog }))
      dispatch(actions.formDestroy({ name: formView }))
      dispatch(clearAutocomplite({ field: formWork }))
      dispatch(clearAutocomplite({ field: formMaterial }))
      dispatch(clearAutocomplite({ field: formFacilityProject }))
      dispatch(clearAutocomplite({ field: formEstimateGroup }))
      dispatch(clearGantThree())
    }
  }, [dispatch])
  const facilityId = useSelector(state => state.header?.facilityId)
  useEffect(() => {
    if (typeof facilityId === 'undefined' || facilityId === 0) return;
    dispatch(fetchDispath({
      param: {
        id: facilityId
      },
      progress: true,
      request: getFacilityOurProject,
    })).then(res => {
      dispatch(loadAuto(res.get.map(e => ({ value: e.id, label: e.name })), { field: formFacilityProject, }))
    })
  }, [dispatch, facilityId])
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      progress: true,
      request: getOutfitPlanWork,
    })).then(res => {
      let groupWork = {}
      const groupAll = {}
      const arWork = res.get.work.map((e, i) => {
        if (!groupWork[e.groupId]) {
          groupWork[e.groupId] = { label: e.groupName, options: [] }
          groupAll[e.groupId] = { value: e.groupId, label: `${e.groupNumber}. ${e.groupName}`, number: e.groupNumber }
        }

        return {
          label: e.name,
          value: e.id + '_' + e.groupId,
          id: e.id,
          storageUnit: e.storageUnit,
          balance: e.groupBalance,
          count: e.groupCount,
          groupId: e.groupId,
          groupName: e.groupName,
          groupKey: e.groupId,
          groupNumber: e.groupNumber
        }
      })
      dispatch(loadAuto(arWork, { field: formWork, }))
      dispatch(loadGroup(groupWork, { field: formWork }))

      let groupMaterial = {}
      const arrMaterial = res.get.material.map((e, i) => {
        if (!groupMaterial[e.groupId]) {
          groupMaterial[e.groupId] = { label: e.groupName, options: [] }
          groupAll[e.groupId] = { value: e.groupId, label: `${e.groupNumber}. ${e.groupName}`, number: e.groupNumber }
        }
        return {
          label: e.name,
          value: e.id + '_' + e.groupId,
          id: e.id,
          storageUnit: e.storageUnit,
          balance: e.groupBalance,
          count: e.groupCount,
          groupId: e.groupId,
          groupName: e.groupName,
          groupKey: e.groupId,
          groupNumber: e.groupNumber
        }
      })
      dispatch(loadAuto(arrMaterial, { field: formMaterial, }))
      dispatch(loadGroup(groupMaterial, { field: formMaterial }))

      dispatch(loadAuto(Object.values(groupAll).sort((a, b) => a.number - b.number), { field: formEstimateGroup, }))
      setLoad(true)
    }).catch(err => console.log(err))
  }, [dispatch, idProject])
  const workArr = useSelector(state => state.autocomplite[formWork] || state.autocomplite.default)
  const workMaterial = useSelector(state => state.autocomplite[formMaterial] || state.autocomplite.default)
  useEffect(() => {
    // пока уберем загрузку
    // console.log(1)
    if (!isLoad) return;
    setLoad(false)
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      progress: true,
      request: getProjectGant,
    })).then(res => {
      let byId = {}
      const arrStartDate = []
      const arrEndDate = []
      let parentChild = {}
      const dependencies = []
      const autocompliteParent = []
      let allIds = res.get.map((e, i) => {
        const id = 'id' + e.id
        const value = workArr.arr.find(obj => {
          if (obj.id === e.idWork && obj.groupId === e.idGroup) {
            return true
          }
          return false
        })
        const valueMaterial = workMaterial.arr.find(obj => {
          if (obj.id === e.idMaterial && obj.groupId === e.idGroup) {
            return true
          }
          return false
        })
        if (e.typeGant === 'parent') {
          if (parentChild[e.id]) {
            parentChild[e.id].row = id
            parentChild[e.id].name = e.name
          }
          else {
            parentChild[e.id] = { row: id, name: e.name, arr: [], start: null, end: null }
          }
        };

        let obj = {
          ...oneRow,
          ...e,
          id: id,
          idAPI: e.id,
          dependencies: e.dependencies || [],
          start: moment(e.start, 'YYYY-MM-DD'),
          end: moment(e.end, 'YYYY-MM-DD'),
          startPlan: moment(e.startPlan, 'YYYY-MM-DD'),
          endPlan: moment(e.endPlan, 'YYYY-MM-DD'),
          duration: 0,
          value: value || null,///{ value: e.idWork + '', label: e.name }
          valueMaterial: valueMaterial || null,
          price: Number(e.price),
          sum: Math.round(Number(e.price) * e.count * 100) / 100,
          perf: e.abs > 0 ? e.abs : Math.round((e.count * e.progress / 100) * 100000) / 100000,
          sumPerf: e.abs > 0 ? Math.round(10000 * Number(e.price) * (e.abs)) / 10000 : Math.round(Number(e.price) * e.count * e.progress) / 100
        }
        obj.duration = obj.end.diff(obj.start, 'days')
        dependencies.push(...obj.dependencies.map(e => ({ fromId: obj.idAPI, ...e })))

        if (obj.parent) {
          if (!parentChild[obj.parent]) {
            parentChild[obj.parent] = {
              row: null,
              name: '',
              arr: [],
              start: moment(obj.start),
              end: moment(obj.end)
            }
          }
          parentChild[obj.parent].arr.push(id)
          const startP = (obj.startPlan.isValid()) ? moment.min([obj.startPlan, obj.start]) : moment(obj.start)
          const endP = (obj.endPlan.isValid()) ? moment.max([obj.endPlan, obj.end]) : moment(obj.end)
          if (!parentChild[e.parent].start || parentChild[obj.parent].start > startP) {
            parentChild[obj.parent].start = moment(startP)
          }
          if (!parentChild[e.parent].end || parentChild[obj.parent].end < endP) {
            parentChild[obj.parent].end = moment(endP)
          }
        }
        if (obj.typeGant !== 'parent') {
          arrStartDate.push(obj.start)
          arrStartDate.push(obj.startPlan)
          arrEndDate.push(obj.end)
          arrEndDate.push(obj.endPlan)
        }
        byId[id] = obj
        return id
      })
      allIds = res.arraySort.map(e => 'id' + e)
      allIds.forEach(key => {
        const obj = byId[key]
        if (obj.parent) {
          const par = byId[parentChild[obj.parent].row]
          byId[key].parentValue = {
            value: par.id,
            label: par.pos + ' ' + par.name
          }
        }
        if (obj.typeGant === 'parent') {
          autocompliteParent.push({ value: obj.idAPI, label: obj.pos + ' ' + obj.name })
          const parent = parentChild[obj.idAPI]
          if (!parent || parent.arr.length === 0) { return; }
          byId[key].child = parent.arr
          byId[key].start = parent.start
          byId[key].end = parent.end
          byId[key].duration = parent.end.diff(parent.start, 'days')
          return;
        };
      })
      const allReverse = [...allIds].reverse()
      allReverse.forEach(key => {
        const obj = byId[key]
        const child = byId[key].child
        if (obj.typeGant === 'parent' && child.length > 0) {
          const startP = moment.min(child.map(e => byId[e].start).filter(e => e.isValid()))
          const endP = moment.max(child.map(e => byId[e].end).filter(e => e.isValid()))
          obj.start = moment(startP)
          obj.end = moment(endP)

          const startPPlan = moment.min(child.map(e => byId[e].startPlan).filter(e => e.isValid()))
          const endPPlan = moment.max(child.map(e => byId[e].endPlan).filter(e => e.isValid()))
          obj.startPlan = moment(startPPlan)
          obj.endPlan = moment(endPPlan)
        }
      })
      allReverse.forEach(key => {
        const obj = byId[key]
        if (obj.typeGant !== 'parent') { return; }
        const parent = parentChild[obj.idAPI]
        if (!parent || parent.arr.length === 0) { return; }
        byId[key].sum = parent.arr.reduce((partialSum, a) => partialSum + byId[a].sum, 0);
        byId[key].sum = Math.round(byId[key].sum * 100) / 100

        byId[key].sumPerf = parent.arr.reduce((partialSumPerf, a) => partialSumPerf + byId[a].sumPerf, 0);
        byId[key].sumPerf = Math.round(byId[key].sumPerf * 100) / 100

      })
      allIds.forEach(key => {
        const obj = byId[key]
        if (obj.dependencies.length === 0) return;
        const dependencies = []
        obj.dependencies.forEach(e => {
          const find = allIds.findIndex(s => byId[s].idAPI === e.to)
          const objTo = byId[allIds[find]]
          if (objTo.parent && obj.parent !== objTo.parent) {
            const findTo = allIds.findIndex(s => s === 'id' + objTo.parent)
            dependencies.push({
              ...e,
              id: objTo.parent,
              to: 'id' + objTo.parent,
              toIndex: findTo,
              parent: true
            });
          }
          dependencies.push({
            ...e,
            to: objTo.id,
            toIndex: find,
            parent: false
          });
        })
        if (obj.parent) {
          byId['id' + obj.parent].dependencies.push(...dependencies)
        }
        byId[key].dependencies = dependencies
      })
      let startNew = moment(moment.min(arrStartDate.filter(e => e.isValid())))
      let endNew = moment(moment.max(arrEndDate.filter(e => e.isValid())))
      if (!startNew.isValid()) startNew = moment()
      if (!endNew.isValid()) endNew = moment()
      startNew.add(-7, 'd')
      endNew.add(7, 'd')
      batch(() => {
        dispatch(actions.formChangeAsObj({
          byId, allIds,
          start: startNew,
          end: endNew,
          count: endNew.diff(startNew, 'days'),
          idRow: allIds.length + 1
        }, { name: formName }))
        dispatch(loadGantTree(res.tree))
        dispatch(loadGantArrow(dependencies))
        dispatch(actions.formChangeAsObj({ autocompliteParent }, { name: formDialog }))
      })

    }).catch(err => console.log(err))
  }, [dispatch, idProject, isLoad])
  const idRow = useSelector(state => state.form[formName]?.values.idRow)
  const handleAdd = () => {
    const newIdRow = idRow + 1;
    dispatch(actions.formObjAdd({ ...oneRow, id: 'id' + newIdRow }, { field: 'byId', id: 'id' + newIdRow, name: formName }))
    dispatch(actions.formArrayAdd('id' + newIdRow, { field: 'allIds', name: formName, end: true }))
    dispatch(actions.formChange(newIdRow, { field: 'idRow', name: formName }))
    dispatch(actions.formChangeAsObj({ obj: { ...oneRow, id: 'id' + newIdRow }, id: 'id' + newIdRow, open: true, newRow: true }, { name: formDialog }))
  }
  return <div className='App-paper'>
    <GantHeader
      zoom={zoom}
      setZoom={setZoom}
      setPercent={setPercent}
      history={history}
      fixTable={fixTable}
      setFixTable={setFixTable}
      formName={formName}
      handleAdd={handleAdd}
      setLoad={setLoad}
      idProject={idProject}
      formEstimateGroup={formEstimateGroup}
    />
    <GantDialog
      formName={formName}
      formDialog={formDialog}
      formWork={formWork}
      formMaterial={formMaterial}
      oneRow={oneRow}
      idProject={idProject}
      setLoad={setLoad}
    />
    <GrandView
      formDialog={formDialog}
      formView={formView}
      formName={formName}
      idProject={idProject}
      zoom={zoom}
      percent={percent}
      fixTable={fixTable}
      history={history}
    />
  </div>
}