import React, { useState, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, TextField, Button, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { loadProvider, addFile } from '../../../../../api/list'
import { nds } from '../../../../../enum/nds.enum';
import * as actions from '../../../../../reducers/form';
import { loadFileAPI } from '../../../../../reducers/materialLoad';
import AutoCompliteCreateModal from '../../../../../components/AutocompliteWithLoadCreateModal'
import Autocomplite from '../../../../../components/Autocomplite';
import CompaniesAdd from '../../../../list/provider/ProviderAdd'
import DriverHelper from './DriverHelper'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 240,
  }
}));
function ShipmentAddHeader({ formName, }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  return <>
    <DraveHelperButton />
    <LoadButton formName={formName} />
    <Grid container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-end"
      spacing={1}
    >
      <Grid item>
        <AutoCompliteCreateModal
          classes={classes}
          field='idProvider'
          name='Поставщик'
          path={loadProvider.path}
          meta={{
            field: 'provider',
            value: 'id',
            labelFunc: (obj) => obj.name + ' ' + obj.inn || ''
          }}
          formName={formName}
          modal={({ handleClose }) => <CompaniesAdd modal={true} modalClose={handleClose} />}
        />
      </Grid>
      <Grid item>
        <DateUpd formName={formName} />
      </Grid>
      <Grid item xs={4}>
        <Autocomplite
          palaceHolder={'НДС по умолчанию'}
          action={(e) => {
            dispatch(actions.formChange(e.value, { field: 'ndsDefault', name: formName }))
          }}
          arr={nds}
        />
      </Grid>
    </Grid>
  </>
}

export default memo(ShipmentAddHeader)


function DraveHelperButton() {
  const [helper, setHelper] = useState(false)
  return <>
    <DriverHelper open={helper} handleClose={() => setHelper(false)} />
    <Button onClick={() => setHelper(true)} color='primary' component="span">Помощь</Button>
  </>
}

function LoadButton({ formName }) {
  const dispatch = useDispatch()
  const [loadingFile, setLoadingFile] = useState(false)
  const _handleImageChange = (e) => {
    e.preventDefault();
    if (typeof e.target.files[0] === 'undefined' || e.target.files[0].length === 0) return
    setLoadingFile(true)
    let fileName = e.target.files[0].name
    const fileType = e.target.files[0].type
    dispatch(loadFileAPI({ file: e.target.files[0], path: addFile.path }))
      .then(res => {
        if (res.success) {
          dispatch(actions.formObjAdd({ id: res.id, name: fileName, type: fileType }, {
            field: 'fById',
            id: 'id' + res.id,
            name: formName
          }))
          dispatch(actions.formArrayAdd('id' + res.id, { field: 'fAllIds', name: formName }))
        }
        setLoadingFile(false)
      })
      .catch()
  }
  return <Button disabled={loadingFile} component="label" color='primary'>
    {loadingFile && <CircularProgress size={25} />}
    <span>Прикрепить файл</span>
    <input
      type="file"
      style={{ display: "none" }}
      onChange={(e) => _handleImageChange(e)}
    />
  </Button>
}

function DateUpd({ formName }) {
  const dispatch = useDispatch()
  const date = useSelector(state => state.form[formName]?.values?.date)
  return <TextField
    type="date"
    InputLabelProps={{
      shrink: true,
    }}
    onChange={(e) => {
      dispatch(actions.formChange(e.target.value, { field: 'date', name: formName }))
    }}
    values={date}
    label='Дата поставки'
  />
}