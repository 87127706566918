import React from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { addShipmenMaterialById, getShipmentMaterialNotTable } from '../../../../api/shipment'
import * as actions from '../../../../reducers/form';
import AutocompliteWithLoad from '../../../../components/AutocompliteWithLoad'
const styles = theme => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 120,
    marginTop: 5,
    marginBottom: 3,
    width: '50%'
  },
})
/**
 * @deprecated
 */
class ShipmentMaterialAdd extends React.Component {
  constructor(params) {
    super(params)
    params.actions.formInitialize({
      idMaterial: '',
      balance: '',
      price: '',
      count: '',
      priceOld: '',
    })
    this.state = { formName:'ShipmentMaterialAdd' }
  }
  handleSave = (e) => {
    e.preventDefault();
    const { formName } = this.state
    const { form } = this.props
    const { values } = form[formName] || form.default
    const params = {
      count: values.count,
      price: values.price,
      idMaterial: values.idMaterial
    }
    this.props.actions.formSubmit({
      props: params,
      url: addShipmenMaterialById.path({ id: this.props.match.params.idShipment }),
      method: addShipmenMaterialById.type,
      history: this.props.history
    })
  };
  textEditText = (e) => {
    this.props.actions.formChange(e.target.value, { field: [e.target.id] })
  }
  changeMaterial = obj => {
    this.props.actions.formChange(obj.balance, { field: 'balance' })
    this.props.actions.formChange(obj.price, { field: 'priceOld' })
  }
  render() {
    const { classes, form } = this.props
    const { formName } = this.state
    const { values } = form[formName] || form.default
    return (
      <div className='App-paper'>
        <form autoComplete="off" onSubmit={this.handleSave}>
          <AutocompliteWithLoad
            classes={classes}
            field='idMaterial'
            name='Материал'
            path={getShipmentMaterialNotTable.path({ id: this.props.match.params.idShipment })}
            meta={{
              field: 'idMaterial',
              value: 'id',
              labelFunc: (obj) => obj.name,
              hidden: ['balance', 'price']
            }}
            formName={formName}
            change={this.changeMaterial}
          />
          <FormControl className={classes.formControl} margin='dense'>
            <TextFieldCustom
              label='Стоимость единицы'
              id="price"
              onChange={this.textEditText}
              adornment={values.priceOld || ''}
              value={values.price || ''}
            />
          </FormControl>
          <FormControl className={classes.formControl} margin='dense'>
            <TextFieldCustom
              label='Количество'
              id="count"
              onChange={this.textEditText}
              adornment={values.balance || ''}
              value={values.count || ''}
            />
          </FormControl>
          <Button color="primary" onClick={() => this.props.history.goBack()}>Назад </Button>
          <Button type="submit" color="primary">Сохранить</Button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    form: store.form,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    actions: {
      formDestroy: () => dispatch(actions.formDestroy({ name: 'ShipmentMaterialAdd' })),
      formInitialize: obj => dispatch(actions.formInitialize(obj, { name: 'ShipmentMaterialAdd' })),
      formSubmit: (obj) => dispatch(actions.formSubmitAPI(obj, 'ShipmentMaterialAdd')),
      formChange: (obj, meta) => dispatch(actions.formChange(obj, { ...meta, name: 'ShipmentMaterialAdd' })),
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ShipmentMaterialAdd));


const TextFieldCustom = ({ label, onChange, adornment, value, id }) => (
  <TextField
    margin="dense"
    id={id}
    label={label}
    type="text"
    fullWidth
    value={value}
    onChange={onChange}
    InputProps={{
      endAdornment:
        <InputAdornment position="end">
          {adornment ? `(${adornment})` : ''}
        </InputAdornment>,
    }}
    autoComplete={'off'} />
)
