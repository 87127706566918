import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchDispath } from '../../functions/fetch'
import { getAnalyticsWorker } from '../../api/analytics'
import { Grid, TextField, Button } from '@material-ui/core'
import * as actions2 from '../../reducers/formTable';
import PWRTable from './source/PWRTable';
import getColor from '../../functions/getColorAttend'

// const styles = theme => ({
//   main: {
//     margin: theme.spacing(1)
//   },
//   btn: {
//     margin: theme.spacing(1)
//   },
//   text: {
//     fontSize: 14,
//   },
// });
const formName = 'pwr'
export default function AnalitycProjectWorker({ match }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions2.initFormTable({ name: formName }))
    return () => {
      dispatch(actions2.clearFormTable(formName))
    }
  }, [dispatch])
  const [dateEnd, setDateEnd] = useState('')
  const [dateStart, setDateStart] = useState('')
  const [generate, setGenerate] = useState(false)
  const onClick = () => {
    if (dateStart !== '' && dateStart !== '') {
      dispatch(fetchDispath({
        progress: true,
        request: getAnalyticsWorker,
        param: {
          id: match.params.idProject
        },
        querty: {
          dateStart: dateStart,
          dateEnd: dateEnd
        }
      })).then(res => {
        if (res.success) {
          setGenerate(true)
          dispatch(actions2.loadFormTable({ get: res.get, count: res.get.length }, { name: formName, id: 'id' }))
        }
      }).catch(err => console.log(err))
    }
  }
  return (<div className='App-paper'>
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={8}
    >
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
          spacing={8}
        >
          <Grid item>
            <Button onClick={onClick}>Сформировать</Button>
          </Grid>
          <Grid item>
            <TextField
              id="date"
              name='date'
              type={"date"}
              label='Начало'
              value={dateStart}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setDateStart(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              id="date"
              name='date'
              type={"date"}
              label='Конец'
              value={dateEnd}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setDateEnd(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <PWRTable dateEnd={dateEnd} dateStart={dateStart} generate={generate} />
      </Grid>
      <Grid item>
        {[{ value: 'attent', name: 'Пришел' }, { value: 'late', name: 'Опоздал' }, { value: 'absent', name: 'Отсутствовал' }].map((el, v) => {
          return <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
            key={el.value}
            spacing={8}><Grid item >
              <div style={{ width: 20, height: 20, background: getColor(el.value) }} />
            </Grid>
            <Grid item >
              - {el.name}
            </Grid>
          </Grid>
        })}
      </Grid>
    </Grid>
  </div>)
}