import React from 'react';
import PageList from '../../../../components/PageListAPI'
import { loadRentEquipment } from '../../../../api/shipment'
export default function ArendaEquipment(props) {
  const column = [
    { id: 'user', name: 'Добавил', },
    { id: 'dateCreate', name: 'Дата оформления', type: 'date' },
    { id: 'provider', name: 'Поставщик', },
    { id: 'name', name: 'Оборудование', },
    { id: 'serialNumber', name: 'Серийный номер', },
    { id: 'time', name: 'Время аренды' },
    { id: 'dateStart', name: 'Начало аренды', type: 'date' },
    { id: 'dateEnd', name: 'Окончание аренды', type: 'date' },
    { id: 'idWriteOff', name: 'Списан' },
    { id: 'price', name: 'Стоимость', },
  ]
  return (
    <div className='App-paper'>
      <PageList
        name='shipmentEquipmentList'
        columns={column}
        createItem={true}
        deleteItem={false}
        idName='id'
        loadAPI={{
          querty: {
            idProject: props.match.params.id
          },
          request: loadRentEquipment,
          transform: (res => {
            return {
              count: res.count,
              get: res.get.map(obj => {
                let autoWriteOffString = obj.autoWriteOff ? 'автоматическое списание' : 'ручное списание'
                let time = obj.isHour ? obj.time + ' (час)' : obj.time + ' (сутки)'
                let idWriteOff = obj.writeOff ? 'списан' : 'на балансе'
                return { ...obj, autoWriteOffString, time, idWriteOff }
              })
            }
          }),
        }}
        click={false}
        filterProps={[
          {
            arr: [{ value: '1', name: 'Списан' }, { value: '0', name: 'На балансе' }],
            field: 'writeOff',
            name: 'Статус',
            type: 'select'
          },
        ]}
        {...props}
      />
    </div>
  )
}