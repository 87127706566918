import React, { useState, useEffect, useCallback } from 'react'
import {
  Grid, Button, TextField, Typography, Dialog, DialogTitle, DialogContent,
  DialogActions, Table, TableHead, TableBody, TableCell, TableRow, Hidden, Menu, MenuItem
} from '@material-ui/core'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'
import { fetchDispath } from '../../functions/fetch'
import {
  getProjectMachine,
  addProjectMachine,
  delProjectMachine,
  updProjectMachine,
  getProjectMachineObject,
} from '../../api/projects'
import Autocomplite from '../../components/Autocomplite'
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite, load as loadAuto } from '../../reducers/autocomplite'
import { getWorkerList } from '../../api/worker'
import WayBillCreateForDialog from './WayBillCreateForDialog'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 210,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 200,
  },
}));
const defaultPropsDialog = {
  date: '2000-01-01',
  id: 0,
  time: '00:00',
  hour: 0,
  driver: null,
  waybill: false,
}

const fromMachine = 'machine'
const fromWorker = 'worker'
export default function MachineCalendar({ history, match }) {
  const classes = useStyles();
  const [date, setDate] = useState(moment().format('YYYY-MM'))
  const dispatch = useDispatch()
  const idProject = match.params.idProject
  const [reload, setReload] = useState(false)
  const [objCalendar, setObjCalendar] = useState({})
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogProps, setDialogProps] = useState(defaultPropsDialog)
  useEffect(() => {
    dispatch(initialAutocomplite({ field: fromMachine }))
    dispatch(initialAutocomplite({ field: fromWorker }))
    return () => {
      dispatch(clearAutocomplite({ field: fromMachine }))
      dispatch(clearAutocomplite({ field: fromWorker }))
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      progress: true,
      request: getProjectMachineObject,
    }))
      .then(res => {
        dispatch(loadAuto(res.get.map(e => ({ value: e.idMachine, label: e.model, typeM:e.typeM })), { field: fromMachine }))
      })
    dispatch(loadAutocompliteAPI(getWorkerList.path, {
      field: fromWorker,
      value: 'id',
      hidden: ['position'],
      labelFunc: (obj) => obj.surname + ' ' + obj.name + ' ' + obj.patronymic
    }))
  }, [dispatch, idProject])

  const handleAddMiss = (add) => {
    setDate(moment(date, 'YYYY-MM').add(add, 'M').format('YYYY-MM'))
  }
  const loadDate = useCallback(() => {
    const s = moment(date, 'YYYY-MM').startOf('M')
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      querty: {
        start: s.format('YYYY-MM-DD'),
        end: s.endOf('M').format('YYYY-MM-DD')
      },
      progress: true,
      request: getProjectMachine,
    })).then(res => {
      let arrObj = []
      res.get.forEach(e => {
        const d = moment(e.date).get('D')
        if (!arrObj[d]) {
          arrObj[d] = {}
        }
        arrObj[d][e.time] = { ...e, fio: `${e.surname}.${e.name ? e.name[0] : ''}.${e.patronymic ? e.patronymic[0] : ''}` }
      })
      setObjCalendar(arrObj)
    })
  }, [dispatch, date, idProject])
  useEffect(() => {
    loadDate()
  }, [loadDate])
  useEffect(() => {
    if (!reload) return;
    setReload(false)
    loadDate()
  }, [loadDate, reload])
  const [values, setValues] = useState({ value: '', label: '', typeM:'' })
  const [week, setWeek] = useState(0)

  const arr = useSelector(state => state.autocomplite[fromMachine] || state.autocomplite.default)
  return <div>
    <DialogAdd
      open={openDialog}
      handleReload={() => { setReload(true); }}
      onClose={() => { setOpenDialog(false); setDialogProps(defaultPropsDialog) }}
      dialogProps={dialogProps}
      idProject={idProject}
      idMachine={values?.value}
      date={date}
      typeM={values?.typeM}
    />
    <div style={{ margin: 2 }}>
      <Typography variant='h6'>Календарь техники</Typography>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-end"
      >
        <Grid item>
          <Button color='primary' component="span" onClick={() => history.goBack()}>Назад</Button>
        </Grid>
        <Grid item lg={2} md={5} sm={9} xs={9}>
          <Autocomplite
            action={(e) => {
              console.log(e)
              setValues(e)
            }}
            palaceHolder={'Техника'}
            value={values}
            arr={arr.arr}
            selectProps={{
              isClearable: true,
            }}
          />
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          lg={4}
          md={6}
          xl={3}
        >
          <Grid item>
            <Button onClick={() => handleAddMiss(-1)}><b>{"<"}</b></Button>
          </Grid>
          <Grid item>
            <form className={classes.container} noValidate>
              <TextField
                id="date"
                type="month"
                fullWidth
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </form>
          </Grid>
          <Grid item>
            <Button onClick={() => handleAddMiss(1)}><b>{">"}</b></Button>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          lg={3}
        >
          <Grid item>неделя</Grid>
          <Grid item>
            <Button onClick={() => setWeek(e => e > 0 ? e - 1 : e)}><b>{'<'}</b></Button>
          </Grid>
          <Grid item>{week + 1}</Grid>
          <Grid item>
            <Button onClick={() => setWeek(e => e < 4 ? e + 1 : e)}><b>{'>'}</b></Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
    <TableWeek
      date={date}
      objCalendar={objCalendar}
      valuesMachine={values?.value}
      setDialogProps={e => { setDialogProps(e); setOpenDialog(true) }}
      week={week}
      handleReload={() => { setReload(true); }}
      idProject={idProject}
    />
  </div>
}

const weekText = ['пн, ', 'вт,  ', 'ср, ', 'чт, ', 'пт, ', 'сб, ', 'вс, ']
const useStylesTable = makeStyles((theme) => ({
  tableDrive: {
    position: 'absolute',
    zIndex: 3,
    top: 0,
    left: 0,
    right: 0,
  },
  tableTime: {
    position: 'relative',
    zIndex: 2,
  },
  tableBody: {

  },
  tableRow: {

  },
  tableCell: {

  },
  tableDiv: {

  }
}));
const initialStateCard = {
  mouseX: null,
  mouseY: null,
  obj: {},
};
const initialStateTable = {
  mouseX: null,
  mouseY: null,
  obj: {},
};
function TableWeek({ date, objCalendar, valuesMachine, week, setDialogProps, handleReload, idProject }) {
  const classes = useStylesTable();
  const [arr, setArr] = useState([])
  const [arrSlice, setArrSlice] = useState([])
  useEffect(() => {
    const dateNow = moment(date, 'YYYY-MM').startOf('M')
    const dateNext = moment(dateNow).add(1, 'M')
    const count = dateNext.diff(dateNow, 'd')
    let start = dateNow.days()
    let end = dateNow.endOf('M').days()
    if (start === 0) start = 7
    if (end === 0) end = 7
    start = start - 1
    end = 7 - end
    const arrD = Array.apply(null, Array(start)).map(e => '')
    Array.apply(null, Array(count)).forEach((e, i) => {
      arrD.push(i + 1)
    })
    Array.apply(null, Array(end)).forEach((e, i) => {
      arrD.push('')
    })
    setArr(arrD)
  }, [date])
  useEffect(() => {
    setArrSlice(arr.slice(7 * week, 7 * (week + 1)))
  }, [arr, week])
  const handleClick = (obj) => {
    setDialogProps({
      day: obj.day,
      id: obj.id || 0,
      time: obj.time,
      hour: obj.hour,
      driver: obj.id ? {
        value: obj.idWorker,
        label: obj.surname + ' ' + obj.name + ' ' + obj.patronymic,
      } : null,
      waybill: obj.waybill,
      duration: obj.duration,
    })
  }
  const [stateContextCard, setStateContextCard] = useState(initialStateCard);
  const [stateContextTable, setStateContextTable] = useState(initialStateTable);
  const [objCopy, setObjCopy] = useState({})
  const handleContextMenuCard = (e, obj) => {
    e.preventDefault();
    setStateContextTable(initialStateTable)
    setStateContextCard({
      mouseX: e.clientX - 2,
      mouseY: e.clientY - 4,
      obj: obj,
    });
  }
  const handleContextMenuTable = (e, obj) => {
    e.preventDefault();
    setStateContextCard(initialStateCard)
    setStateContextTable({
      mouseX: e.clientX - 2,
      mouseY: e.clientY - 4,
      obj: obj,
    });
  }
  return <div
    id='tableWeek'
    style={{ position: 'relative' }}
  >
    <TableGrid
      arrSlice={arrSlice}
      handleClick={handleClick}
      onContextMenu={(e, obj) => handleContextMenuTable(e, obj)}
    />
    <ContextMenuCard
      state={stateContextCard}
      close={() => setStateContextCard(initialStateCard)}
      handleReload={handleReload}
      idProject={idProject}
      setObjCopy={(obj) => setObjCopy(obj)}
    />
    <ContextMenuTable
      state={stateContextTable}
      close={() => setStateContextTable(initialStateCard)}
      handleReload={handleReload}
      idProject={idProject}
      objCopy={objCopy}
      date={date}
    />
    <div className={classes.tableDrive}>
      <Table size={'small'} padding='none'>
        <TableBody className={classes.tableBody}>
          <TableRow className={classes.tableRow}>
            <TableCell width={'9%'} style={{ border: 'none', minWidth: 55 }} ></TableCell>
            {arrSlice.map((key, i) => {
              const element = document.getElementById('a1') || { offsetHeight: 34 };
              const element2 = document.getElementById('TableCell00:00') || { offsetHeight: 34 };
              if (objCalendar[key]) {
                return <TableCell
                  key={key + '' + i}
                  style={{ border: 'none' }}
                  width={'13%'}
                >{
                    Object.keys(objCalendar[key]).filter(e => objCalendar[key][e].idMachine === valuesMachine).map(e => {
                      const obj = objCalendar[key][e]
                      const offset = Number(e.split(':')[0])
                      const height = element2.offsetHeight * obj.duration
                      const top = element2.offsetHeight * offset + element.offsetHeight
                      return <CellCalendar
                        key={key + '' + i + e}
                        id={key + '' + i + e}
                        obj={obj}
                        offset={offset}
                        height={height}
                        onClick={() => handleClick({ ...obj, day: key })}
                        top={top}
                        oneHeight={element2.offsetHeight}
                        maxMove={element2.offsetHeight * (24 - offset - obj.duration)}
                        minMove={-element2.offsetHeight * offset}
                        handleReload={handleReload}
                        idProject={idProject}
                        onContextMenu={(e, obj) => handleContextMenuCard(e, obj)}
                      />
                    })
                  }</TableCell>
              }
              return <TableCell key={key + '' + i} style={{ border: 'none' }} width={'13%'}></TableCell>
            })}
          </TableRow>
        </TableBody>
      </Table>
    </div>
  </div>
}
const useStylesTableGrid = makeStyles((theme) => ({
  main: {
    position: 'relative',
    zIndex: 2,
  },
  cellHeader: {
    border: '1px solid #a1a1a1',
    textAlign: 'center'
  },
  cellHeaderTime: {
    border: '1px solid #a1a1a1',
    textAlign: 'center',
    minWidth: 55
  },
  cellTime: {
    border: '1px solid #a1a1a1', 
    padding: 7,
    minWidth: 55
  }
}));
const arrTime = [...Array.from({ length: 10 }, (v, k) => `0${k}:00`), ...Array.from({ length: 14 }, (v, k) => `${10 + k}:00`),]
function TableGrid({ arrSlice, handleClick, onContextMenu }) {
  const classes = useStylesTableGrid();
  return <div className={classes.main}>
    <Table size={'small'} padding='none' >
      <TableHead>
        <TableRow>
          <TableCell id='a1' className={classes.cellHeaderTime}>время</TableCell>
          {arrSlice.map((e, i) => {
            return <TableCell key={e + '_' + i} width='13%' className={classes.cellHeader}>{weekText[i] + e}</TableCell>
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {arrTime.map(e => {
          return <TableRow key={e}>
            <TableCell className={classes.cellTime} id={'TableCell' + e}>{e}<br />{' '}</TableCell>
            {arrSlice.map((key, i) => {
              const keyHandleClick = key === '' ? undefined : () => handleClick({ time: e + ':00', hour: Number(e.split(':')[0]), day: key })
              const keyHandleContextMenu = key === '' ? undefined : (s) => onContextMenu(s, { time: e + ':00', hour: Number(e.split(':')[0]), day: key })
              return <TableCell
                key={key + '' + i + '' + e}
                style={{ border: '1px solid #a1a1a1', width: '13%', }}
                onClick={keyHandleClick}
                onContextMenu={keyHandleContextMenu}
              />
            })}
          </TableRow>
        })}
      </TableBody>
    </Table>
  </div>
}

function DialogAdd({ open, onClose, dialogProps, idProject, handleReload, idMachine, date, typeM }) {
  const { hour, time, id, day, driver, waybill, duration: durationOld } = dialogProps
  const dateAll = moment(date || '2020-10', 'YYYY-MM').startOf('M').add(day - 1, 'd')
  const [duration, setDuration] = useState(1)
  const dispatch = useDispatch()
  const [values, setValues] = useState({ value: '', label: '' })
  const arr = useSelector(state => state.autocomplite[fromWorker] || state.autocomplite.default)
  useEffect(() => {
    setValues(driver)
  }, [driver])
  useEffect(() => {
    setDuration(durationOld || 1)
  }, [durationOld])
  const handleAdd = () => {
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      body: {
        date: dateAll.format('YYYY-MM-DD'),
        time,
        duration,
        idMachine,
        driver: values.value
      },
      progress: true,
      request: addProjectMachine,
    })).then(e => {
      onClose()
      handleReload()
    })
  }
  const handleDelete = () => {
    dispatch(fetchDispath({
      param: {
        id: id
      },
      progress: true,
      request: { path: delProjectMachine(idProject).path, type: delProjectMachine(idProject).type },
    })).then(e => {
      onClose()
      handleReload()
    })
  }

  const waybillFunc = () => {
    if (id === 0) return null
    if (waybill) {
      return <Typography>Путевой лист оформлен</Typography>
    }
    else return <WayBillCreateForDialog
      idCalendar={id}
      idProject={idProject}
      date={dateAll.format('YYYY-MM-DD')}
      handleAdd={() => { onClose(); handleReload() }}
      idMachine={idMachine}
      typeM={typeM}
    />
  }
  const handleEdit = () => {
    dispatch(fetchDispath({
      param: {
        id: id,
        idProject: idProject,
      },
      body: {
        duration,
        driver: values.value
      },
      progress: true,
      request: updProjectMachine,
    })).then(e => {
      onClose()
      handleReload()
    })
  }
  return <Dialog
    open={open}
    maxWidth='sm'
    keepMounted
    fullWidth
    scroll="paper"
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    PaperProps={{ style: { minHeight: 500 } }}
  >
    <DialogTitle id="max-width-dialog-title">{time}</DialogTitle>
    <DialogContent>
      <TextField
        label='продолжительность(ч)'
        type={'number'}
        inputProps={{
          min: 1,
          max: 24 - hour,
          step: 1
        }}
        fullWidth
        value={duration}
        onChange={e => setDuration(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Autocomplite
        action={(e) => {
          setValues(e)
        }}
        palaceHolder={'Водитель'}
        value={values}
        arr={arr.arr}
        selectProps={{
          isClearable: true,
        }}
      />
      {id !== 0 && <Button onClick={handleEdit} color="primary">Изменить</Button>}
      {waybillFunc()}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleAdd} color="primary" disabled={id !== 0}>
        Добавить
      </Button>
      <Button onClick={handleDelete} color="primary" disabled={id === 0}>
        Удалить
      </Button>
    </DialogActions>
  </Dialog>
}

function CellCalendar({ id, obj, offset, height, top, onClick,
  oneHeight, maxMove, minMove, handleReload, idProject, onContextMenu,
}) {
  const [posY, setPosY] = useState(0)
  const [move, setMove] = useState(0)
  const [lastMoved, setLastMoved] = useState(false)
  const [moveStart, setMoveStart] = useState(false)
  const dispatch = useDispatch()
  const cellRef = React.createRef();

  const onMouseDown = (e) => {
    setMoveStart(true)
    setPosY(e.clientY)
    setLastMoved(true)
  }
  const onMouseMove = (e) => {
    if (!moveStart) return;
    if (!cellRef.current) return;
    const top = parseInt(cellRef.current.style.top, 10)
    const newMove = top + (e.clientY - posY)
    const move2 = move + e.clientY - posY
    if (move2 > maxMove || move2 < minMove) return
    cellRef.current.style.top = newMove + 'px'
    setMove(move + e.clientY - posY)

    // cellRef.current
    // const newMove = move + e.clientY - posY
    setPosY(e.clientY)
  }
  const onMouseUp = (e) => {
    if (!moveStart) return;
    const time = (offset + Math.round(move / oneHeight))
    dispatch(fetchDispath({
      param: {
        id: obj.id,
        idProject: idProject,
      },
      body: {
        time: time > 9 ? `${time}:00:00` : `0${time}:00:00`,
      },
      progress: true,
      request: updProjectMachine,
    })).then(e => {
      handleReload()
      setMoveStart(false)
      setMove(0)
    })
  }
  return <div
    ref={cellRef}
    style={{
      border: '1px solid #62c1f0',
      backgroundColor: '#98d9fa',
      borderRadius: '8px',
      height,
      top: top,
      position: 'absolute',
      width: '12.8%',
      padding: '3px',
      cursor: 'pointer',
      userSelect: 'none',
    }}
    onClick={() => { if (lastMoved) { setLastMoved(false); return; } onClick() }}
    onContextMenu={(e) => onContextMenu(e, obj)}
  >
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      style={{ height }}>
      <Grid item><div>{offset + '-' + (offset + obj.duration)}</div></Grid>
      <Grid item>
        <Hidden only={['xs', 'sm', 'md']}>
          <div>{obj.fio}</div>
        </Hidden>
      </Grid>
      <Grid item>
        <div
          style={{ cursor: 'grab', height }}
          onDragStart={() => {
            window.onselectstart = e => false
          }}
          onDragEnd={() => {
            window.onselectstart = null
          }}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
        >
          <DragIndicatorIcon size='small' />
        </div>
      </Grid>
    </Grid>
  </div>
}

function ContextMenuCard({ state, close, idProject, handleReload, setObjCopy }) {
  const dispatch = useDispatch()
  const handleCopy = () => {
    setObjCopy(state.obj)
    close()
  }
  const handleDelete = () => {
    if (!state.obj) return;
    dispatch(fetchDispath({
      param: {
        id: state.obj?.id
      },
      progress: true,
      request: { path: delProjectMachine(idProject).path, type: delProjectMachine(idProject).type },
    })).then(e => {
      close()
      handleReload()
    })
  }
  return <Menu
    keepMounted
    open={state.mouseY !== null}
    onClose={() => close()}
    anchorReference="anchorPosition"
    anchorPosition={
      state.mouseY !== null && state.mouseX !== null
        ? { top: state.mouseY, left: state.mouseX }
        : undefined
    }
  >
    <MenuItem onClick={handleCopy}>Копировать</MenuItem>
    <MenuItem onClick={handleDelete}>Удалить</MenuItem>
  </Menu>
}

function ContextMenuTable({ state, close, idProject, handleReload, objCopy, date }) {
  const dispatch = useDispatch()
  const handlePaste = () => {
    if (!objCopy?.id) return;
    const dateAll = moment(date || '2020-10', 'YYYY-MM').startOf('M').add(state.obj.day - 1, 'd')
    dispatch(fetchDispath({
      param: {
        id: idProject
      },
      body: {
        date: dateAll.format('YYYY-MM-DD'),
        time: state.obj.time,
        duration: objCopy.duration,
        idMachine: objCopy.idMachine,
        driver: objCopy.idWorker,
      },
      progress: true,
      request: addProjectMachine,
    })).then(e => {
      close()
      handleReload()
    })
  }
  return <Menu
    keepMounted
    open={state.mouseY !== null}
    onClose={() => close()}
    anchorReference="anchorPosition"
    anchorPosition={
      state.mouseY !== null && state.mouseX !== null
        ? { top: state.mouseY, left: state.mouseX }
        : undefined
    }
  >
    <MenuItem onClick={handlePaste} disabled={!objCopy?.id}>Вставить</MenuItem>
  </Menu>
}