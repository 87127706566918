import React, { useState, useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import {
  IconButton, Tooltip, TextField, Select, Typography,
  Table, TableBody, TableCell, TableHead, TableRow, Button,
  DialogContent, DialogTitle, DialogActions, Dialog
} from '@material-ui/core';
import AutoComplite from '../../../components/Autocomplite'
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../reducers/form';
import { loadAutocompliteAPI, initialAutocomplite, clearAutocomplite } from '../../../reducers/autocomplite'
import { getMaterialsList } from '../../../api/material'
import { addOrdersMy } from '../../../api/orders'
import { getEquipment } from '../../../api/list'
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { addFilterAutocomplite, dellFilterAutocomplite, addFilterManyAutocomplite } from '../../../reducers/autocomplite'
const row = {
  count: '',
  idMaterial: null,
  idEquipment: null,
  nameText: '',
  type: 'material',
  storageUnit: '',
  valueMaterial: null,
  valueEquipment: null
}
const arrMaterial = 'material'
const arrEquipment = 'equipment'
const formName = 'OrdersMyAdd'
const outfitM = 'OurfitOPByIdM'
const formMaterialSelected = 'materialSelected'
export default function OrdersMyAdd({ match, history }) {
  const [idRow, setRow] = useState(0)
  const [outfitLoaded, setOutfitLoaded] = useState(false)

  const dispatch = useDispatch()
  const form = useSelector(state => state.form[formName] || state.form.default)
  const selectedMaterial = useSelector(state => state.form[formMaterialSelected] || state.form.default)
  const [selectedLoad, setSelectedLoad] = useState(typeof selectedMaterial.values.arr === 'undefined')
  const { values } = form
  useEffect(() => {
    dispatch(initialAutocomplite({ field: arrMaterial }))
    dispatch(initialAutocomplite({ field: arrEquipment }))
    dispatch(actions.formInitialize({ allIds: [], byId: {} }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
      dispatch(actions.formDestroy({ name: formMaterialSelected }))
      dispatch(actions.formDestroy({ name: outfitM }))
      dispatch(clearAutocomplite({ field: arrMaterial }))
      dispatch(clearAutocomplite({ field: arrEquipment }))
    }
  }, [dispatch])
  useEffect(() => {
    const pathMaterials = getMaterialsList.path({ id: match.params.idProject })
    const metaMaterials = {
      field: arrMaterial,
      value: 'id',
      hidden: ['storageUnit', 'balance', 'count'],
      labelFunc: (obj) => obj.name
    }
    dispatch(loadAutocompliteAPI(pathMaterials, metaMaterials))
    const metaEquipment = {
      field: arrEquipment,
      value: 'id',
      hidden: ['storageUnit'],
      labelFunc: (obj) => obj.name
    }
    dispatch(loadAutocompliteAPI(getEquipment.path, metaEquipment))
  }, [dispatch, match.params.idProject])
  const material = useSelector(state => state.autocomplite[arrMaterial] || state.autocomplite.default)
  const formOutfit = useSelector(state => state.form[outfitM] || state.form.default)
  useEffect(() => {
    if (!material.load || outfitLoaded) return;
    const { values } = formOutfit
    if (!values.allIds || values.allIds.length === 0) {
      setOutfitLoaded(true);
      return;
    }
    let objAdd = {}
    let arrAdd = []
    let filterAdd = []
    let newId = idRow
    values.allIds.forEach((key, i) => {
      const obj = values.byId[key]
      if (obj.count < obj.storage) return;
      const valueMaterial = material.arr.find(e => e.value === obj.id.toString())
      newId += 1
      objAdd['id' + newId] = {
        ...row,
        count: (obj.count - obj.storage).toString(),
        idMaterial: obj.id,
        storageUnit: valueMaterial.storageUnit,
        valueMaterial: valueMaterial,
      }
      arrAdd.push('id' + newId)
      filterAdd.push({ value: valueMaterial.value, id: 'id' + newId })
    })
    dispatch(addFilterManyAutocomplite(filterAdd, { field: arrMaterial }))
    dispatch(actions.formChangeAsObj({
      allIds: arrAdd,
      byId: objAdd,
    }, { name: formName }))
    setRow(newId)
    setOutfitLoaded(true);
  }, [dispatch, material, formOutfit, outfitLoaded, idRow])
  // load selected materials
  useEffect(() => {
    if (selectedLoad) return;
    if (!material.load) return;
    const { arr } = selectedMaterial.values
    if (arr.length === 0) return;
    let newId = 0
    let objAdd = {}
    let arrAdd = []
    let filterAdd = []
    arr.forEach((id, i) => {
      const findMaterial = material.arr.find(e => id.toString() === e.value)
      if (!findMaterial) return;
      newId += 1
      objAdd['id' + newId] = {
        ...row,
        idMaterial: findMaterial.value,
        storageUnit: findMaterial.storageUnit,
        valueMaterial: findMaterial,
      }
      arrAdd.push('id' + newId)
      filterAdd.push({ value: findMaterial.value, id: 'id' + newId })
    })
    dispatch(addFilterManyAutocomplite(filterAdd, { field: arrMaterial }))
    dispatch(actions.formChangeAsObj({
      allIds: arrAdd,
      byId: objAdd,
    }, { name: formName }))
    setRow(newId)
    setSelectedLoad(true)
  }, [dispatch, material, selectedMaterial, selectedLoad])
  const addRow = () => {
    dispatch(actions.formObjAdd(row, {
      field: 'byId',
      id: 'id' + (idRow + 1),
      name: formName
    }))
    dispatch(actions.formArrayAdd('id' + (idRow + 1), {
      field: 'allIds', name: formName
    }))
    setRow(idRow + 1)
  }
  const saveServer = () => {
    let props = []
    values.allIds.forEach(key => {
      const obj = values.byId[key]
      if (obj.count > 0 && (obj.idMaterial !== 0 || obj.idEquipment !== 0 || obj.nameText !== ''))
        props.push({
          idMTP: obj.idMaterial,
          idEquipment: obj.idEquipment,
          name: obj.nameText,
          type: (obj.type === 'material') ? 'project' : obj.type,
          count: obj.count,
          storageUnit: obj.storageUnit
        })
    })
    dispatch(actions.formSubmitAPI({
      props: { arr: props },
      url: addOrdersMy.path({ id: match.params.idProject }),
      method: addOrdersMy.type,
      history: history
    }, formName))
  }
  return (
    <div className='App-paper'>
      <Typography variant='h6'>Заказать материалы</Typography>
      <DialogAddTable
        arrMaterial={arrMaterial}
        arrEquipment={arrEquipment}
        idRow={idRow}
        setRow={(e) => setRow(e)}
      />
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell padding='checkbox'>
              <IconButton onClick={addRow}  ><AddIcon /></IconButton>
            </TableCell>
            {['Тип', 'Наименование', 'Количество', 'Ед. изм.'].map((e, i) => <TableCell key={i}>{e}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {values.allIds && values.allIds.map(row => <RowOrderAdd row={row} obj={values.byId[row]} key={row} />)}
          <TableRow style={{ height: 57 * 5 }}>
            <TableCell colSpan={3} />
          </TableRow>
        </TableBody>
      </Table>
      <Button color='primary' component="span" onClick={() => history.goBack()}>Назад</Button>
      <Button color='primary' component="span" onClick={saveServer}>Добавить</Button>
    </div>
  )
}

function RowOrderAdd({ row, obj }) {
  const dispatch = useDispatch()
  const formObjChangeObj = (objForm) => dispatch(actions.formObjChangeObj(objForm, { field: 'byId', id: row, name: formName }))
  const changeMaterial = text => {
    if (obj.valueMaterial) dispatch(dellFilterAutocomplite(obj.idMaterial, { field: arrMaterial, id: row }))
    formObjChangeObj({
      storageUnit: text.storageUnit,
      idMaterial: text.value,
      valueMaterial: text,
    })
    dispatch(addFilterAutocomplite(text.value, { field: arrMaterial, id: row }))
  };
  const changeEquipment = text => {
    if (obj.valueEquipment) dispatch(dellFilterAutocomplite(obj.idEquipment, { field: arrEquipment, id: row }))
    formObjChangeObj({
      storageUnit: text.storageUnit,
      idEquipment: text.value,
      valueEquipment: text,
    })
    dispatch(addFilterAutocomplite(text.value, { field: arrEquipment, id: row }))
  };
  const textEditRow = (e, obj) => {
    formObjChangeObj({ [obj.name]: e.target.value })
  }
  const deleteRow = () => {
    if (obj.valueMaterial) dispatch(dellFilterAutocomplite(obj.idMaterial, { field: arrMaterial, id: row }))
    if (obj.valueEquipment) dispatch(dellFilterAutocomplite(obj.idEquipment, { field: arrEquipment, id: row }))
    dispatch(actions.formArrayDelete(row, { field: 'allIds', name: formName }))
    dispatch(actions.formObjDelete({ id: row, field: 'byId', name: formName }))
  }
  return (
    <TableRow key={row}>
      <TableCell padding='checkbox'>
        <Tooltip title="Delete">
          <IconButton aria-label="Delete" onClick={deleteRow}  ><DeleteIcon /></IconButton>
        </Tooltip>
      </TableCell>
      <TableCell padding='checkbox'>
        <SelectSwich id={row} obj={obj} arrMaterial={arrMaterial} arrEquipment={arrEquipment} />
      </TableCell>
      <TableCell padding='checkbox'>
        <div style={{ minWidth: 300 }}>
          {
            (() => {
              switch (obj.type) {
                case 'material':
                  return <AutocompliteMaterial formArr={arrMaterial} value={obj.valueMaterial} change={changeMaterial} />
                case 'equipment':
                  return <AutocompliteMaterial formArr={arrEquipment} value={obj.valueEquipment} change={changeEquipment} />
                default:
                  return <TextFieldRow
                    type="text"
                    row={row}
                    name='nameText'
                    action={textEditRow}
                  />
              }
            })()
          }
        </div>
      </TableCell>
      <TableCell>
        <TextFieldRow
          type="number"
          row={row}
          name='count'
          action={textEditRow}
          value={obj.count}
        />
      </TableCell>
      <TableCell>{(obj.type !== 'text') ? obj.storageUnit : <TextFieldRow
        type="text"
        row={row}
        name='storageUnit'
        action={textEditRow}
      />}</TableCell>
    </TableRow>
  )
}


const TextFieldRow = ({ row, name, type, value, action }) => <TextField
  fullWidth
  margin="dense"
  type={type}
  value={value}
  onChange={(e) => action(e, { id: row, name: name })}
/>

const AutocompliteMaterial = ({ value, change, formArr }) => {
  const [edit, changeEdit] = useState(true)
  const arr = useSelector(state => state.autocomplite[formArr] || state.autocomplite.default)
  const filterFunc = obj => !(typeof obj.select !== 'undefined' && obj.select !== row)
  if (!value && !edit) changeEdit(true)
  if (!edit) return <div onClick={() => changeEdit(true)}>{(value) ? value.label : ''}</div>
  return <AutoComplite
    arr={arr.arr.filter(filterFunc)}
    action={(e) => { change(e); changeEdit(false) }}
    value={value}
    palaceHolder=''
  />
}

function SelectSwich({ id, obj, arrMaterial, arrEquipment }) {
  const dispatch = useDispatch()
  const arr = [{ value: 'material', label: 'материалы' }, { value: 'equipment', label: 'оборудование' }, { value: 'text', label: 'другое' },]
  const handleChange = (e) => {
    if (obj.valueMaterial) dispatch(dellFilterAutocomplite(obj.idMaterial, { field: arrMaterial, id: id }))
    if (obj.valueEquipment) dispatch(dellFilterAutocomplite(obj.idEquipment, { field: arrEquipment, id: id }))
    dispatch(actions.formObjChangeObj({ ...row, type: e.target.value }, { field: 'byId', id: id, name: formName }))
  }
  return <React.Fragment>
    <Select
      native
      value={obj.type}
      onChange={handleChange}
      style={{ minWidth: 120 }}
    >
      {arr.map(el => {
        return <option key={el.value} value={el.value}>{el.label}</option>
      })}
    </Select>
  </React.Fragment>
}

const useStyles = makeStyles(theme => ({
  selected: {
    background: '#bbbbbb'
  },
}))

function DialogAddTable({ arrMaterial, arrEquipment, idRow, setRow }) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState([])
  const [typeMaterial, setTypeMaterial] = useState({ value: 'material', label: 'материал' })
  const dispatch = useDispatch()
  const material = useSelector(state => state.autocomplite[arrMaterial] || state.autocomplite.default)
  const equipment = useSelector(state => state.autocomplite[arrEquipment] || state.autocomplite.default)
  const column = {
    material: [{ id: 'label', name: 'Наименование' }, { id: 'balance', name: 'Требуется заказать' }, { id: 'count', name: 'Кол-во по смете' }],
    equipment: [{ id: 'label', name: 'Наименование' }]
  }
  const arr = {
    material,
    equipment
  }
  const addMaterial = () => {
    setOpen(false)
    selected.forEach((obj, i) => {
      let newRow = { ...row, type: typeMaterial.value, storageUnit: obj.storageUnit }
      if (typeMaterial.value === 'material') {
        newRow.idMaterial = obj.value
        newRow.valueMaterial = obj
      }
      else {
        newRow.idEquipment = obj.value
        newRow.valueEquipment = obj
      }
      const newIdRow = idRow + 1 + i
      dispatch(actions.formObjAdd(newRow, {
        field: 'byId',
        id: 'id' + newIdRow,
        name: formName
      }))
      dispatch(actions.formArrayAdd('id' + newIdRow, {
        field: 'allIds', name: formName
      }))
      dispatch(addFilterAutocomplite(obj.value, { field: typeMaterial.value === 'material' ? arrMaterial : arrEquipment, id: 'id' + newIdRow }))

    })
    setRow(idRow + selected.length)
    setSelected([])
  }
  const handleChangeType = text => { setSelected([]); setTypeMaterial(text); }
  const changeSelected = (obj, add) => {
    if (add) {
      setSelected([...selected, obj])
      return;
    }
    setSelected(selected.filter(e => e.value !== obj.value))
  }
  return <React.Fragment>
    <Button color='primary' onClick={() => setOpen(true)}>Добавить списком</Button>
    <Dialog
      open={open}
      maxWidth='md'
      keepMounted
      fullWidth
      scroll="paper"
      onClose={() => (setOpen(false))}
      aria-labelledby="alert-dialog-title"
      PaperProps={{ style: { height: 400 } }}
    >
      <DialogTitle id="alert-dialog-title">Добавить материал</DialogTitle>
      <DialogContent >
        <AutoComplite
          arr={[{ value: 'material', label: 'материал' }, { value: 'equipment', label: 'оборудование' }]}
          action={handleChangeType}
          value={typeMaterial}
          palaceHolder='Тип материала'
        />
        <Table>
          <TableHead>
            <TableRow>
              {column[typeMaterial.value].map(obj => <TableCell key={obj.id}>{obj.name}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {arr[typeMaterial.value].arr.filter(obj => !(typeof obj.select !== 'undefined')).map((e, i) => {
              const sleectedRow = selected.includes(e)
              return <TableRow
                key={i}
                className={classNames({ [classes.selected]: sleectedRow, })}
                onClick={() => changeSelected(e, !sleectedRow)}
              >
                {column[typeMaterial.value].map(obj => <TableCell key={obj.id}>{e[obj.id]}</TableCell>)}
              </TableRow>
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Отмена
        </Button>
        <Button onClick={addMaterial} color="primary" autoFocus>
          Добавить
        </Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
}