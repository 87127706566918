import template from "./template_crud";
import { all } from "./template_crud";

// Storage unit -------------------------------------
const storageUnit = '/list/storageunit';
export const getStorageUnit = template.getList(storageUnit);
export const addStorageUnit = template.addOne(storageUnit)
export const deleteStorageUnit = template.deleteById(storageUnit)
export const getStorageUnitById = template.getById(storageUnit)
export const updStorageUnit = template.updById(storageUnit)
// Work template -------------------------------------
const workTemplate = '/list/worktemplate';
export const getWorkTemplate = template.getList(workTemplate);
export const addWorkTemplate = template.addOne(workTemplate)
export const deleteWorkTemplate = template.deleteById(workTemplate)
export const getWorkTemplateById = template.getById(workTemplate)
export const updWorkTemplate = template.updById(workTemplate)
// MSN-------------------------------------------------------
const estimateName = '/list/estimatename'
export const getMSN = template.getList(estimateName)
export const addMSN = template.addOne(estimateName)
export const deleteMSN = template.deleteById(estimateName)
// Equipment--------------------------------------------------------------
const equipment = '/equipment'
export const addEquipment = template.addOne(equipment)
export const getEquipmentById = template.getById(equipment)
export const getEquipment = template.getList(equipment)
export const deleteEquipment = template.deleteById(equipment)
export const updEquipment = template.updById(equipment)
// Provder ----------------------------------------------------------------
const provider = '/provider'
export const loadProvider = template.getList(provider)
export const addProvider = template.addOne(provider)
export const updProvider = template.updById(provider)
export const deleteProvider = template.deleteById(provider)
export const loadProviderById = template.getById(provider)
export const loadProviderMaterialsById = {
  path: (obj) => `${provider}/${obj.id}/materials`,
  type: 'GET'
}
// position -------------------------------------------------------
const position = '/list/position'
export const getPosition = template.getList(position)
export const getPositionById = template.getById(position)
export const updPosition = template.updById(position)
export const addPosition = template.addOne(position)
export const deletePosition = template.deleteById(position)
// report template -----------------------------------
const template_p = '/list/template'
export const getTemplate = template.getList(template_p)
export const getTemplateById = template.getById(template_p)
export const getTemplateFile = {
  path: (obj) => `${template_p}/${obj.id}/file`,
  type: 'GET'
}
export const updateTemplateFile = template.updById(template_p)

export const typework = { ...all('/list/typework') }
export const typequipment = { ...all('/list/typequipment') }

const file = '/files'
export const getFile = template.getById(file)
export const delFile = template.deleteById(file)
export const addFile = template.addOne(file + '/add')

// Companies -------------------------------------
const companies = '/list/companies';
export const getCompanies = template.getList(companies);
export const addCompanies = template.addOne(companies)
export const deleteCompanies = template.deleteById(companies)
export const getCompaniesById = template.getById(companies)
export const updCompanies = template.updById(companies)

export const getCompanyAPI = template.getList('/dadataapi/organisation')

export const getCompaniesSRO = template.getById(companies, '/sro');
export const updCompaniesSRO = template.updById(companies, '/sro');

// File parse -----------------------------------------
const fileParse = '/file_parse'
export const getFileParse = template.getList(fileParse);
export const addFileParse = template.addOne(fileParse);
export const deleteFileParse = template.deleteById(fileParse)

// Hide facility 
const hideFacility = '/list/hide_facility'
export const getHideFacility = template.getList(hideFacility);
export const addHideFacility = template.addOne(hideFacility);
export const deleteHideFacility = template.deleteById(hideFacility)

// Storage unit -------------------------------------
const resources = '/list/resources';
export const getResources = template.getList(resources);
export const addResources = template.addOne(resources);
export const deleteResources = template.deleteById(resources);
export const getResourcesById = template.getById(resources);
export const getResourcesByIdParents = template.getById(resources, '/parents');
export const updResources = template.updById(resources);


// Work estimate -------------------------------------
const workEstimate = '/list/work-estimate'
export const loadWorkEstimate = template.getList(workEstimate)
export const addWorkEstimate = template.addOne(workEstimate)
export const updWorkEstimate = template.updById(workEstimate)
export const deleteWorkEstimate = template.deleteById(workEstimate)
export const loadWorkEstimateById = template.getById(workEstimate)


// Machine -------------------------------------
const machine = '/list/machine';
export const getMachine = template.getList(machine);
export const addMachine = template.addOne(machine)
export const deleteMachine = template.deleteById(machine)
export const getMachineById = template.getById(machine)
export const updMachine = template.updById(machine)


// MachineState -------------------------------------
export const getMachineState = (idMachine)=> ({
  path: (obj) => `${machine}/${idMachine}/state`,
  type: 'GET'
})
export const addMachineState = {
  path: (obj) => `${machine}/${obj.id}/state`,
  type: 'POST'
}
export const getMachineStateById = (idMachine)=> ({
  path: (obj) => `${machine}/${idMachine}/state/${obj.id}`,
  type: 'GET'
})
export const updMachineState = (idMachine)=> ({
  path: (obj) => `${machine}/${idMachine}/state/${obj.id}`,
  type: 'PUT'
})
export const deleteMachineState = (idMachine)=> ({
  path: (obj) => `${machine}/${idMachine}/state/${obj.id}`,
  type: 'DELETE'
})

// MachineChecklist -------------------------------------
export const getMachineChecklist = (idMachine)=> ({
  path: (obj) => `${machine}/${idMachine}/checklist`,
  type: 'GET'
})
export const addMachineChecklist = {
  path: (obj) => `${machine}/${obj.id}/checklist`,
  type: 'POST'
}
export const deleteMachineChecklist = (idMachine)=> ({
  path: (obj) => `${machine}/${idMachine}/checklist/${obj.id}`,
  type: 'DELETE'
})

// Checklist -------------------------------------
const checklist = '/list/checklist';
export const getChecklist = template.getList(checklist);
export const addChecklist = template.addOne(checklist)
export const getChecklistById = template.getById(checklist)
export const updChecklist = template.updById(checklist)
export const deleteChecklist = template.deleteById(checklist)


// Checklist Question -------------------------------------
export const addChecklistQuestion = {
  path: (obj) => `${checklist}/${obj.id}/question`,
  type: 'POST'
}
export const updChecklistQuestion = (idChecklist)=> ({
  path: (obj) => `${checklist}/${idChecklist}/question/${obj.id}`,
  type: 'PUT'
})
export const deleteChecklistQuestion = (idChecklist)=> ({
  path: (obj) => `${checklist}/${idChecklist}/question/${obj.id}`,
  type: 'DELETE'
})

// location -------------------------------------
const location = '/list/location';
export const addLocation = template.addOne(location);
export const deleteLocation = template.deleteById(location)


// regions
const region = '/list/regions'
export const getRegions = template.getList(region);
export const addRegions = template.addOne(region)
export const getRegionsById = template.getById(region)
export const updRegions = template.updById(region)
export const deleteRegions = template.deleteById(region)

// specializations
const specializations = '/list/specializations'
export const getSpecializations = template.getList(specializations);
export const addSpecializations = template.addOne(specializations)
export const getSpecializationsById = template.getById(specializations)
export const updSpecializations = template.updById(specializations)
export const deleteSpecializations = template.deleteById(specializations)