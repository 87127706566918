// @ts-check
import React, { useCallback, useState } from 'react';
import AutoCompliteWithLoad from '../components/AutocompliteWithLoad'
import { Grid, InputLabel, TextField, FormControl, InputAdornment } from "@material-ui/core";
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Clear'
import ClearIcon from '@material-ui/icons/Clear';
/**
 * 
 * @param {import ('./types/FilterArray').FilterArray} param0 
 */
export default function FilterArray({ change, name = 'filter', arr = [] }) {
  return (<Grid
    container
    direction="row"
    justifyContent="flex-start"
    alignItems="flex-end"
    spacing={1}
  >
    {arr.map(obj => {
      return <Grid item key={obj.field} xs={3}><TypeFiler obj={obj} change={change} name={name} /></Grid>
    })}
  </Grid>)
}
/**
 * @param {import ('./types/FilterArray').AutoComplite} param0 
 */
const AutoCompliteSimple = ({ obj, change, name }) => {
  return <AutoCompliteWithLoad
    classes={{}}
    field={obj.field}
    name={obj.name}
    path={obj.path}
    formName={name + '_' + obj.field}
    meta={{
      field: name + '_' + obj.field,
      value: 'id',
      labelFunc: (obj) => obj.name,
      ...obj.meta
    }}
    change={(e) => change(obj.field, e ? e.value : undefined)}
    selectProps={{
      isClearable: true
    }}
    form={false}
  />
}
/**
 * @param {import ('./types/FilterArray').AutoComplite} param0 
 */
const AutoCompliteMilti = ({ obj, change, name }) => {
  return <AutoCompliteWithLoad
    classes={{}}
    field={obj.field}
    name={obj.name}
    path={obj.path}
    formName={name + '_' + obj.field}
    meta={{
      field: name + '_' + obj.field,
      value: 'id',
      labelFunc: (obj) => obj.name,
      ...obj.meta
    }}
    change={(e) => {
      const list = e.map(el => el.value);
      change(obj.field, list.length > 0 ? list.join(',') : undefined)
    }}
    selectProps={{
      isClearable: true,
      isMulti: true
    }}
    form={false}
  />
}
/**
 * @param {import ('./types/FilterArray').SelectSwitch} param0 
 */
const SelectSwitch = ({ obj, change }) => {
  const [value, changeValue] = useState('')
  const handleChange = (e) => {
    changeValue(e.target.value)
    change(obj.field, e.target.value !== '' ? e.target.value : undefined)
  }
  const { arr = [], name = '', field = '' } = obj
  return <FormControl fullWidth>
    <InputLabel htmlFor={field + "select-name"}>{name}</InputLabel>
    <Select
      native
      value={value}
      onChange={handleChange}
      inputProps={{
        name: field,
        id: field + 'select-name',
      }}
    >
      <option value="" />
      {arr.map(el => {
        return <option key={el.value} value={el.value}>{el.name}</option>
      })}
    </Select>
  </FormControl>
}

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    const later = () => {
      timeout = null;
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
/**
 * @param {import ('./types/FilterArray').SelectSwitch} param0 
 */
const FindText = ({ change, obj }) => {
  const { field } = obj
  const [find, setFind] = useState('')
  const changeValue = useCallback(debounce((value) => change(field, value !== '' ? value : undefined), 250), [change, field])
  const textEdit = value => {
    setFind(value)
    changeValue(value)

  }
  return <InputBase
    onChange={(e) => textEdit(e.target.value)}
    placeholder={obj.name || 'Найти'}
    value={find}
    fullWidth
    endAdornment={<IconButton aria-label="Delete" onClick={() => textEdit('')}>
      <DeleteIcon />
    </IconButton>}
  />
}
/**
 * @param {import ('./types/FilterArray').SelectSwitch} param0 
 */
const FilterDate = ({ change, obj }) => {
  const { field, name } = obj
  const [filter, setFilter] = useState('')
  const textEdit = value => {
    setFilter(value)
    change(field, value !== '' ? value : undefined)
  }
  return <TextField
    id="date"
    name='date'
    type='date'
    label={name}
    value={filter || ''}
    InputProps={{
      endAdornment: <InputAdornment position="end">
        <IconButton size="small"
          onClick={() => textEdit('')}
        ><ClearIcon fontSize="small"/>
        </IconButton>
      </InputAdornment>
    }}
    InputLabelProps={{
      shrink: true,
    }}
    onChange={(e) => textEdit(e.target.value)}
  />
}
/**
 * @param {import ('./types/FilterArray').TypeFiler} param0 
 */
const TypeFiler = ({ obj, change, name }) => {
  switch (obj.type) {
    case 'autocomplite':
      return <AutoCompliteSimple obj={obj} change={change} name={name} />
    case 'autocomplite_multi':
      return <AutoCompliteMilti obj={obj} change={change} name={name} />
    case 'select':
      return <SelectSwitch obj={obj} change={change} />
    case 'find_text':
      return <FindText change={change} obj={obj} />
    case 'date':
      return <FilterDate change={change} obj={obj} />
    default:
      return null
  }
}