import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { formChange } from '../reducers/form'
import * as actions from '../reducers/autocomplite'
import AutoComplite from './Autocomplite'

export default function AutocompliteWithLoad({
  classes = {},
  field,
  name,
  path,
  meta,
  formName,
  change,
  selectProps = {},
  textFieldProps = {},
  form = true,
  value
}) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.initialAutocomplite({ field: meta.field, value: value }))
    return () => {
      dispatch(actions.clearAutocomplite({ field: meta.field }))
    }
  }, [dispatch, meta.field, value])
  useEffect(() => {
    dispatch(actions.loadAutocompliteAPI(path, meta))
  }, [dispatch, path])
  const handleChange = (e) => {
    const value = (e) ? e.value : undefined
    dispatch(actions.set(e, { field: meta.field }))
    if (form) dispatch(formChange(value, { field: field, name: formName }))
    if (change) change(e)
  }
  const auto = useSelector(state => state.autocomplite[meta.field] || state.autocomplite.default)
  // жуткий костыль. подумать как исправить потом obj.value may be arr, object, null
  const valueM = (!auto.value) ? [] : (auto.value.length >= 0) ? auto.value : [auto.value]
  return <div className={classes.formControl}>
    <AutoComplite
      arr={auto.arr}
      action={handleChange}
      palaceHolder={name}
      value={(selectProps.isMulti) ? valueM.filter(e => e.value !== '') : auto.value}
      //  {/* костыль подумать как исправить потом*/}
      selectProps={selectProps}
      textFieldProps={{
        label: name,
        InputLabelProps: {
          shrink: true,
        },
        ...textFieldProps
      }}
    />
  </div>
}