import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as actions2 from '../../../reducers/formTable';
import * as actions from '../../../reducers/form';
import { fetchDispath } from '../../../functions/fetch'
import { enqueueSnackbar } from '../../../reducers/notifier'

import { getOrdersLenta, changeStatusOrder } from '../../../api/orders'
import { Tabs, Tab, TableCell, TableHead, TableBody, Table, TableRow, Button, Checkbox, Typography,TableContainer } from '@material-ui/core'
import moment from 'moment'
import 'moment/locale/ru';
const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
  },
  butt: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1
  },
  row: {
    color: '#000000',
    '&$selected': {
      color: '#aa0000'
    }
  },
  selected: {},
}));

const formProject = 'OrdersProcP'
const formEquipment = 'OrdersProcE'
const formText = 'OrdersProcT'
const formOrders = 'selectOrders'
export default function OrdersProcessing ({ match, history }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [tab, setTab] = useState(0)
  const [selected, setSelected] = useState({ project: [], equipment: [], text: [] })
  const [reload, setReload] = useState(true)
  const formTable = useSelector(state => state.formTable)
  useEffect(() => {
    dispatch(actions2.initFormTable({ name: formProject }))
    dispatch(actions2.initFormTable({ name: formEquipment }))
    dispatch(actions2.initFormTable({ name: formText }))
    dispatch(actions.formInitialize({ arr: [] }, { name: formOrders }))
    return () => {
      dispatch(actions2.clearFormTable(formProject))
      dispatch(actions2.clearFormTable(formEquipment))
      dispatch(actions2.clearFormTable(formText))
    }
  }, [dispatch])
  useEffect(() => {
    if (!reload) return;
    dispatch(fetchDispath({
      querty: {
        status: 'not'
      },
      param: {
        id: match.params.idProject
      },
      request: getOrdersLenta,
      progress: true,
    })).then(res => {
      const arrP = [];
      const arrE = [];
      const arrT = [];
      res.get.forEach(obj => {
        switch (obj.type) {
          case 'project':
            arrP.push(obj);
            break;
          case 'equipment':
            arrE.push(obj);
            break;
          case 'text':
            arrT.push(obj);
            break;

          default:
            break;
        }
      });
      dispatch(actions2.loadFormTable({ get: arrP, count: arrP.length }, { name: formProject, id: 'id' }))
      dispatch(actions2.loadFormTable({ get: arrE, count: arrE.length }, { name: formEquipment, id: 'id' }))
      dispatch(actions2.loadFormTable({ get: arrT, count: arrT.length }, { name: formText, id: 'id' }))
    }).catch(err => console.log(err))
    setReload(false)
  }, [dispatch, match.params.idProject, reload])
  const addSelect = (id, field) => setSelected({ ...selected, [field]: [...selected[field], id] })
  const deleteSelect = (id, field) => setSelected({ ...selected, [field]: selected[field].filter(key => key !== id) })
  const setSelect = (arr, field) => setSelected({ ...selected, [field]: arr })
  const handleReject = (nameOrder, nameSelect) => status => {
    const arrOrder = formTable[nameOrder].byId;
    const arrSelect = selected[nameSelect]
    dispatch(actions.formSubmitAPI({
      props: { arr: arrSelect.map(id => arrOrder[id].id), status: status },
      url: changeStatusOrder.path({ id: match.params.idProject }),
      method: changeStatusOrder.type,
      endFunc: () => {
        setSelect([], nameSelect)
        setReload(true)
      }
    }, formOrders))
  }
  const clickRow = (name) => (row, add) => {
    if (add) {
      addSelect(row, name)
      return;
    }
    deleteSelect(row, name)
  }
  const selectAll = (name) => (arr) => {
    setSelect(arr, name)
  }
  const handleClickToShipment = (nameOrder, nameSelect) => () => {
    const arrOrder = formTable[nameOrder].byId;
    const arrSelect = selected[nameSelect]
    const arrObj = {}
    const arr = arrSelect.map(key => {
      const {idMaterial, count, id } =arrOrder[key]
      if (arrObj[idMaterial]) arrObj[idMaterial] += 1; else arrObj[idMaterial] = 1
      return { id: idMaterial, count: count, idOrder: id }
    })
    if (Object.values(arrObj).find(e=>e>1)) {
      dispatch(enqueueSnackbar({
        message: `Нельзя обработать 2 одинаковых материала`,
        options: { variant: 'error' }
      }));
      return;
    }
    dispatch(actions.formChange(arr, { field: 'arr', name: formOrders }))
    const path = `/projects/${match.params.idProject}/shipment/${(nameSelect === 'project') ? 'materials' : 'equipment'}/add`
    history.push(path);
  }
  const TableData = ({ tableName, type }) => <TableOrder
    arr={formTable[tableName] || formTable.default}
    type={type}
    classes={classes}
    clickRow={clickRow(type)}
    selectAll={selectAll(type)}
    arrSelect={selected[type] || []}
    handleReject={handleReject(tableName, type)}
    handleClickToShipment={handleClickToShipment(tableName, type)}
  />
  return <div className='App-paper'>
    <Typography variant='h6'>Обработка заказов</Typography>
    <Tabs value={tab} onChange={(e, v) => setTab(v)} indicatorColor='primary'>
      <Tab label="Сметные материалы" />
      <Tab label="Оборудование" />
      <Tab label="Другое" />
    </Tabs>
    {tab === 0 && <TableData tableName={formProject} type='project' />}
    {tab === 1 && <TableData tableName={formEquipment} type='equipment' />}
    {tab === 2 && <TableData tableName={formText} type='text' />}
    <Button color="primary" component="span" onClick={() => history.goBack()}>Назад</Button>
  </div>
}

function TableOrder({ arr, clickRow, arrSelect, classes, handleReject, handleClickToShipment, type, selectAll }) {
  const { byId = {}, allIds = [] } = arr;
  const hendleSelectAll = (e) => {
    if (e.target.checked) {
      selectAll(allIds)
      return;
    }
    selectAll([])
  }
  return <div>
    <Button disabled={arrSelect.length === 0} color="primary" component="span" onClick={() => handleReject('reject')}>Отклонить</Button>
    <Button disabled={arrSelect.length === 0} color="primary" component="span" onClick={() => handleReject('ordered')}>Уже Заказано</Button>
    {(type !== 'text') && <Button
      disabled={arrSelect.length === 0}
      color="primary"
      component="span"
      onClick={handleClickToShipment}
    >
      Обработать
  </Button>}
  <TableContainer style={{ height: '70vh' }}>
    <Table size='small' stickyHeader >
      <TableHead>
        <TableRow>
          <TableCell padding='checkbox'>
            <Checkbox
              onClick={hendleSelectAll}
              indeterminate={arrSelect.length > 0 && arrSelect.length < allIds.length}
              checked={allIds.length > 0 && allIds.length === arrSelect.length}
            />
          </TableCell>
          {['Дата заказа', 'Добавил', 'Наимнование', 'Количество', 'Ед.изм.'].map((v, i) => <TableCell key={i}>{v}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {allIds.map(id => {
          const obj = byId[id];
          const selected = arrSelect.indexOf(id) !== -1
          return <TableRow
            key={id}
            className={classes.row}
            onClick={(e) => clickRow(id, !selected)}
            selected={selected}
            role="checkbox"
          >
            <TableCell padding='checkbox'><Checkbox checked={selected} /></TableCell>
            <TableCell>{moment(obj.date).format('LLL')}</TableCell>
            <TableCell>{obj.user}</TableCell>
            <TableCell>{obj.name}</TableCell>
            <TableCell>{obj.count}</TableCell>
            <TableCell>{obj.storageUnit}</TableCell>
          </TableRow>
        })}
      </TableBody>
    </Table>
    </TableContainer>
  </div>
}