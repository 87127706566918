import React, { useState, useEffect } from 'react'

import { Typography, Switch, FormControlLabel, IconButton, Grid, Tooltip } from '@material-ui/core'
import AssignmentIcon from '@material-ui/icons/Assignment';
import { fetchDispath } from '../functions/fetch'
import { getShareLink, addShareLink, updShareEnable } from '../api/share'
import { useDispatch } from 'react-redux';

export default function EstimateCreateLink({ idProject, type }) {
  const [enable, setEnable] = useState(false)
  const [link, setLink] = useState('')
  const [id, setId] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDispath({
      querty: {
        idProject: idProject,
        id: idProject,
        type: type
      },
      progress: true,
      request: getShareLink,
    })).then(res => {
      if (res.get) {
        setEnable(res.get.enable)
        setLink(res.get.link)
        setId(res.get.id)

      }
    }).catch(err => console.log(err))
  }, [dispatch, idProject, type])

  const handleClick = () => {
    navigator.clipboard.writeText(`${window.location.origin}/share/${link}/${type}`)
  }
  const addLink = () => {
    dispatch(fetchDispath({
      body: {
        idProject: idProject,
        id: idProject,
        type: type
      },
      progress: true,
      request: addShareLink,
    })).then(res => {
      if (res.success) {
        setEnable(true)
        setLink(res.link)
        setId(res.id)
      }
    }).catch(err => console.log(err))
  }

  const changeEnable = () => {
    dispatch(fetchDispath({
      body: {
        enable: !enable
      },
      param: {
        id: id
      },
      progress: true,
      request: updShareEnable,
    })).then(res => {
      if (res.success) {
        setEnable(!enable)
      }
    }).catch(err => console.log(err))
  }

  const handleSwitch = () => {
    if (id) {
      changeEnable()
    }
    else {
      addLink()
    }
  }
  return (<div className='App-paper'>
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item>
        <FormControlLabel
          control={<Switch
            checked={enable}
            onChange={handleSwitch}
            color="primary"
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />}
          label={`${(enable) ? 'Закрыть' : 'Открыть'} доступ по ссылке`}
        />
      </Grid>
      {enable &&
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <Typography>{`${window.location.origin}/share/${link}/${type}`}</Typography>
          </Grid>
          <Grid item >
            <Tooltip title="Copy" placement="bottom">
              <IconButton color="primary" component="span" onClick={handleClick}>
                <AssignmentIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      }
    </Grid>
  </div>)
}