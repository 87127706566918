import React from 'react';
import PageList from '../../components/PageListAPI'
import { getMyRequestTender } from '../../api/tender.api'
import { TENDER_STATUS } from '../tenderOur/TenderOurList'
import { TENDER_STATUS_BID } from './TenderMyRequestOne'

const columns = [
  { id: 'name', name: 'Название' },
  { id: 'facilityName', name: 'Объект' },
  { id: 'statusRus', name: 'Статус тендера' },
  {
    id: 'requestStatusRus', name: 'Статус заявки',
    type: 'component', component: ({ obj }) => <StatusColored obj={obj} />
  },
  { id: 'typeTenderRus', name: 'Тип' },
  { id: 'dateConfirmStart', name: 'Начало подтверждения участия', type: 'date', formatDate: 'LLL' },
  { id: 'dateConfirmEnd', name: 'Окончание подтверждения участия', type: 'date', formatDate: 'LLL' },
  { id: 'dateOfferEnd', name: 'Окончания предложения', type: 'date', formatDate: 'LLL' },
  { id: 'dateSummingEnd', name: 'Подведение итогов', type: 'date', formatDate: 'LLL' },
]

export default function TenderOpenList(props) {
  return (
    <div className='App-paper'>
      <PageList
        title='Тендеры'
        name='tender-our-list'
        columns={columns}
        createItem={false}
        deleteItem={false}
        loadAPI={{
          request: getMyRequestTender,
          transform: (res) => {
            return {
              ...res,
              get: res.get.map(e => ({
                ...e,
                typeTenderRus: e.typeTender === 'open' ? 'открытый' : 'закрытый',
                statusRus: TENDER_STATUS.find(s => s.value === e.status).label,
                requestStatusObj: TENDER_STATUS_BID.find(s => s.value === e.requestStatus)
              }))
            }
          }
        }}
        filterProps={[
          {
            arr: TENDER_STATUS.map(e=>({value:e.value, name:e.label})),
            field: 'status',
            name: 'Статус тендера',
            type: 'select'
          },
          {
            arr: TENDER_STATUS_BID.filter(e=>e.value!=='none').map(e=>({value:e.value, name:e.label})),
            field: 'statusBid',
            name: 'Статус заявки',
            type: 'select'
          },
        ]}
        headerInit={{
          sortBy:'dateConfirmStart', 
          sortDirection:'desc',          
        }}
        {...props}
      />
    </div>
  )
};


function StatusColored({ obj }) {
  if (!obj.requestStatusObj) return null
  return <>
    <div style={{ color: obj.requestStatusObj.color }}>{obj.requestStatusObj.label}</div>
  </>
}